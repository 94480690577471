import React from "react";

function ArrowLink() {
  return (
    <svg
      width="17"
      height="12"
      viewBox="0 0 17 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.4797 0.238643L16.7571 5.42386C17.081 5.74205 17.081 6.25794 16.7571 6.57614L11.4797 11.7614C11.1558 12.0795 10.6308 12.0795 10.3069 11.7614C9.98307 11.4432 9.98307 10.9273 10.3069 10.6091L14.1687 6.81478H0.814779C0.364789 6.81478 0 6.44999 0 6C0 5.55001 0.364789 5.18522 0.814779 5.18522H14.1687L10.3069 1.39091C9.98307 1.07272 9.98307 0.556834 10.3069 0.238643C10.6308 -0.0795478 11.1558 -0.0795478 11.4797 0.238643Z"
        fill="#00111A"
      />
    </svg>
  );
}

export default ArrowLink;
