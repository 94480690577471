import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const initialState: any = {
  Simple: {
    detteMensuel: 0,
    salaireMensuel: 0,
    Acompte: 0,
  },
  Avance: {
    tauxInt: 4.2,
    Programme: "25 ans",
    Capacity: 45.0,
  },
  Budget: 0,
  MensualiteMax: 0,
  MensualiteChoisi: 0,
  capital_restant: [],
  interets: [],
  principal: [],
};

const slice = createSlice({
  name: "calculezBudgetV2",
  initialState,
  reducers: {
    setRevenuSimple(state, action) {
      return {
        ...state,
        Simple: action.payload,
      };
    },
    setRevenuAvance(state, action) {
      return {
        ...state,
        Avance: action.payload,
      };
    },
    setBudget(state, action) {
      return {
        ...state,
        Budget: action.payload,
      };
    },
    setMensualiteMax(state, action) {
      return {
        ...state,
        MensualiteMax: action.payload,
      };
    },
    setMensualiteChoisi(state, action) {
      return {
        ...state,
        MensualiteChoisi: action.payload,
      };
    },
    setInterets(state, action) {
      return {
        ...state,
        interets: action.payload,
      };
    },
    setPricipal(state, action) {
      return {
        ...state,
        principal: action.payload,
      };
    },
    setCapitalRestant(state, action) {
      return {
        ...state,
        capital_restant: action.payload,
      };
    },
  },
});
const calcuezBudgetv2 = (state: any) => state.calculezBudgetV2 || initialState;

const revenuSimpleSelector = createSelector(
  [calcuezBudgetv2],
  (substate) => substate.Simple
);

const revenuAvanceSelector = createSelector(
  [calcuezBudgetv2],
  (substate) => substate.Avance
);
const budgetSelector = createSelector(
  [calcuezBudgetv2],
  (substate) => substate.Budget
);

const MensualiteChoisiSelector = createSelector(
  [calcuezBudgetv2],
  (substate) => substate.MensualiteChoisi
);

const Principal = createSelector(
  [calcuezBudgetv2],
  (substate) => substate.principal
);

const InteretTable = createSelector(
  [calcuezBudgetv2],
  (substate) => substate.interets
);

const CapitalRestant = createSelector(
  [calcuezBudgetv2],
  (substate) => substate.capital_restant
);

const MensualiteMaxSelector = createSelector(
  [calcuezBudgetv2],
  (substate) => substate.MensualiteMax
);

export const selectors = {
  revenuSimpleSelector,
  revenuAvanceSelector,
  budgetSelector,
  MensualiteMaxSelector,
  MensualiteChoisiSelector,
  Principal,
  InteretTable,
  CapitalRestant,
};

export const actions = {
  ...slice.actions,
};

export default slice.reducer;
