const normalize = (url) => {
  return decodeURI(url)
    .toLowerCase()
    .normalize("NFD")
    .replace(",", "")
    .replace(/[\u0300-\u036f]/g, "")
    .trim()
    .replace(/  /g, "")
    .replace("location-appartement-maison ", "location-appartement-maison")
    .replace("annonces-achat ", "annonces-achat")
    .replace("location-appartement-maison-", "location-appartement-maison")
    .replace("annonces-achat-", "annonces-achat")
    .replace(/ /g, "-")
    .replace("aaaa©", "e")
    .replace("a©", "e")
    .replace("aaae", "e")
    .replace("aaa‚", "")
    .replace(/\/+$/, "");
};

const specificURLs = [
  "agenz.ma",
  "https://agenz.ma",
  "http://agenz.ma",
  "www.agenz.ma",
];

export const externalUrl = (url) => {
  if (!url) {
    return "";
  }
  if (
    url.includes("https://agenz.ma") ||
    url.includes("http://agenz.ma") ||
    url.includes("www.agenz.ma")
  ) {
    return false;
  }
  if (
    url?.startsWith("http://") ||
    url?.startsWith("https://") ||
    url?.startsWith("www.")
  ) {
    if (
      !specificURLs.includes(url) &&
      !specificURLs.some((specificURL) => url.startsWith(specificURL))
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export default function formatUrl(url, lang = "fr", preserveText = false) {
  if (!url) {
    return "";
  }

  if (externalUrl(url)) {
    return url;
  }

  try {
    url = url.replace("immo/", "immo-");
  } catch (e) {}

  try {
    if (url?.includes("mag.agenz")) {
      return "https://mag.agenz.ma";
    }
    url = url
      .replace("www.", "")
      .replace("http://agenz.ma", "")
      .replace("https://agenz.ma", "")
      .replace("http://agenz.ma", "")
      .replace("https://agenz.ma", "")
      .replace("agenz.ma", "")
      .replace("/fr/", "/")
      .replace("/ar/", "/")
      .replace("/en/", "/")
      .replace("//", "/");
    if (url.startsWith("ar")) {
      url = url.replace("ar/", "/");
    }
    if (url.startsWith("en")) {
      url = url.replace("en/", "/");
    }
    if (url.startsWith("fr")) {
      url = url.replace("fr/", "/");
    }

    if (
      url == "/" ||
      url == "https://agenz.ma/fr" ||
      url == "https://agenz.ma" ||
      url == "" ||
      url == "/fr"
    ) {
      if (lang == "ar") {
        return `https://${
          process.env.VERSION_WEBSITE == "next" ? "next." : ""
        }agenz.ma/ar`;
      } else if (lang == "en") {
        return `https://${
          process.env.VERSION_WEBSITE == "next" ? "next." : ""
        }agenz.ma/en`;
      } else {
        return `https://${
          process.env.VERSION_WEBSITE == "next" ? "next." : ""
        }agenz.ma`;
      }
    }
    if (
      (lang == "ar" && url == "/") ||
      url == "https://agenz.ma/ar" ||
      (lang == "ar" && url == "https://agenz.ma") ||
      (lang == "ar" && url == "") ||
      url == "/ar"
    ) {
      return `https:/${
        process.env.VERSION_WEBSITE == "next" ? "next." : ""
      }/agenz.ma/ar`;
    }
    if (
      (lang == "en" && url == "/") ||
      url == "https://agenz.ma/en" ||
      (lang == "en" && url == "https://agenz.ma") ||
      (lang == "en" && url == "") ||
      url == "/en"
    ) {
      return `https:/${
        process.env.VERSION_WEBSITE == "next" ? "next." : ""
      }/agenz.ma/en`;
    }
    if (url == "/estimation") {
      return `https://${
        process.env.VERSION_WEBSITE == "next" ? "next." : ""
      }agenz.ma/${lang}/estimation/map?version=vente`;
    }
    if (url[0] !== "/") {
      url = `/${url}`
        .replace("fr//", "fr/")
        .replace("ar//", "fr/")
        .replace("/default/", "/fr/");
    }
    if (!preserveText) {
      url = url.replace("guide-achat-immobilier-maroc", "guide");
      url = url.replace(
        "/tendances-et-conseils",
        "/actualites-tendances-conseils"
      );
    }
    if (url.includes("/fr/") || url.includes("/ar/") || url.includes("/en/")) {
      return normalize(
        url
          .replace("fr//", "fr/")
          .replace("ar//", "fr/")
          .replace("en//", "fr/")
          .replace("/default/", "/fr/")
          .replace("/fr/fr", "/fr")
          .replace("fr/fr/", "fr/")
          .replace("ar/ar/", "ar/")
          .replace("/ar/ar", "/ar")
          .replace("/en/en", "/en")
          .replace("en/en/", "en/")
      );
    } else {
      return normalize(
        `/${lang ? lang : "fr"}${url}`
          .replace("fr//", "fr/")
          .replace("ar//", "fr/")
          .replace("en//", "fr/")
          .replace("/fr/fr", "/fr")
          .replace("fr/fr/", "fr/")
          .replace("ar/ar/", "ar/")
          .replace("/ar/ar", "/ar")
          .replace("/en/en", "/en")
          .replace("en/en/", "en/")
          .replace("/default/", "/fr/")
      );
    }
  } catch (e) {
    return normalize(
      url
        ?.replace("fr//", "fr/")
        .replace("ar//", "fr/")
        .replace("en//", "en/")
        .replace("/fr/fr", "/fr")
        .replace("fr/fr/", "fr/")
        .replace("ar/ar/", "ar/")
        .replace("/ar/ar", "/ar")
        .replace("/en/en", "/en")
        .replace("en/en/", "en/")
        .replace("/default/", "/fr/")
    );
  }
}

export const canonicalUrl = (url, lang = "fr") => {
  if (url?.includes("mag.agenz")) {
    return "https://mag.agenz.ma";
  }

  try {
    url = url
      .replace("www.", "")
      .replace("http://agenz.ma", "")
      .replace("https://agenz.ma", "")
      .replace("//", "/")
      .replace("http://agenz.ma", "")
      .replace("https://agenz.ma", "")
      .replace("agenz.ma", "")
      .replace("/fr/", "/")
      .replace("/ar/", "/")
      .replace("/en/", "/")
      .replace("/fr/fr", "/fr")
      .replace("fr/fr/", "fr/")
      .replace("ar/ar/", "ar/")
      .replace("/ar/ar", "/ar")
      .replace("/en/en", "/en")
      .replace("en/en/", "en/")

      .replace("//", "/");
    if (url.startsWith("ar")) {
      url = url.replace("ar/", "/");
    }
    if (url.startsWith("en")) {
      url = url.replace("en/", "/");
    }
    if (url.startsWith("fr")) {
      url = url.replace("fr/", "/");
    }
    if (
      (lang == "ar" && url == "/") ||
      url == "https://agenz.ma/ar" ||
      (lang == "ar" && url == "https://agenz.ma") ||
      (lang == "ar" && url == "") ||
      url == "/ar"
    ) {
      return `https://${
        process.env.VERSION_WEBSITE == "next" ? "next." : ""
      }agenz.ma/ar`;
    }
    if (
      (lang == "en" && url == "/") ||
      url == "https://agenz.ma/en" ||
      (lang == "en" && url == "https://agenz.ma") ||
      (lang == "en" && url == "") ||
      url == "/en"
    ) {
      return `https://${
        process.env.VERSION_WEBSITE == "next" ? "next." : ""
      }agenz.ma/ar`;
    }
    if (
      url == "/" ||
      url == "https://agenz.ma/fr" ||
      url == "https://agenz.ma" ||
      url == "" ||
      url == "/fr"
    ) {
      return `https://${
        process.env.VERSION_WEBSITE == "next" ? "next." : ""
      }agenz.ma`;
    }

    if (url[0] !== "/") {
      url = `https://agenz.ma/${lang}/${url}`
        .replace("fr//", `${lang}/`)
        .replace("/fr/fr", "/fr")
        .replace("fr/fr/", "fr/")
        .replace("ar/ar/", "ar/")
        .replace("/ar/ar", "/ar")
        .replace("/en/en", "/en")
        .replace("en/en/", "en/")
        .replace("ar//", `${lang}/`)
        .replace("en//", `${lang}/`);
    }
    url = url.replace("guide-achat-immobilier-maroc", "guide");
    url = url.replace(
      "/tendances-et-conseils",
      "/actualites-tendances-conseils"
    );
    if (url.includes("/fr/") || url.includes("/ar/") || url.includes("/en/")) {
      return normalize(
        `https://${
          process.env.VERSION_WEBSITE == "next" ? "next." : ""
        }agenz.ma/${lang}/${url
          .replace("fr//", `${lang}/`)
          .replace("ar//", `${lang}/`)
          .replace("en//", `${lang}/`)
          .replace("/fr/fr", `/fr`)
          .replace("/fr/fr", "/fr")
          .replace("fr/fr/", "fr/")
          .replace("ar/ar/", "ar/")
          .replace("/ar/ar", "/ar")
          .replace("/en/en", "/en")
          .replace("en/en/", "en/")
          .replace("/ar/ar", `/ar`)
          .replace("/en/en", `/en`)}`
      );
    } else {
      return normalize(
        `https://${
          process.env.VERSION_WEBSITE == "next" ? "next." : ""
        }agenz.ma/${lang ? lang : "fr"}${url}`
          .replace("fr//", `${lang}/`)
          .replace("/fr/fr", "/fr")
          .replace("fr/fr/", "fr/")
          .replace("ar/ar/", "ar/")
          .replace("/ar/ar", "/ar")
          .replace("ar//", `${lang}/`)
          .replace("en/en/", "en/")
          .replace("/en/en", "/en")
          .replace("en//", `${lang}/`)
      );
    }
  } catch (e) {
    return normalize(
      `https://${
        process.env.VERSION_WEBSITE == "next" ? "next." : ""
      }agenz.ma/${lang}/${url}`
        .replace("fr//", `${lang}/`)
        .replace("/fr/fr", "/fr")
        .replace("fr/fr/", "fr/")
        .replace("ar/ar/", "ar/")
        .replace("/ar/ar", "/ar")
        .replace("ar//", `${lang}/`)
        .replace("en/en/", "en/")
        .replace("/en/en", "/en")
        .replace("en//", `${lang}/`)
    );
  }
};

export const nextRewriteRules = (url) => {
  if (url) {
    url = url.replace("/immo/", "/immo-").replace("/pagination/", "?page=");
    return url;
  } else {
    return "";
  }
};
