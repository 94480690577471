import { useState } from "react";
import styles from "./Annuaire.module.scss";
import PlacesAutocomplete from "utils/PlacesAutocomplete/PlacesAutocomplete";
import useTranslation from "next-translate/useTranslation";
import BienTypeSelect from "./BienTypeSelect";
import { selectors as AnnuairereducerSelectors } from "redux/slices/v2/Annuaire";
import { useSelector } from "react-redux";

import style from "./style.module.css";

import SpecialiteTypeSelect from "./SpecialiteTypeSelect";
import { actions as AnnuairereducerActions } from "redux/slices/v2/Annuaire";
import { useDispatch } from "react-redux";
const Recherche = (props) => {
  const [geocoding, setGeocoding] = useState({});
  const [types, setTypes] = useState([]);
  const { t, lang } = useTranslation("RechercheAnnuaireAgence");
  const searchPayload: any = useSelector(
    AnnuairereducerSelectors.searchPayloadSelector
  );
  const dispatch = useDispatch();

  const agences: { agences: any[]; totalItems: number } = useSelector(
    AnnuairereducerSelectors.agencesSelector
  );

  const postHandleSelect = (recherche) => {
    setGeocoding({
      lat: recherche.lat,
      lng: recherche.lng,
      speed: recherche.speed,
      address: recherche.adresse,
      coordinates: { lat: recherche.lat, lng: recherche.lng },
    });
    dispatch(
      AnnuairereducerActions.setSearchPayload({
        data: {
          geometry_label:
            recherche.niveau == "communes"
              ? "commune"
              : recherche.niveau == "pas"
              ? "commune"
              : recherche.niveau == "provinces"
              ? "province"
              : "region",
          geometry_value: recherche.commune
            ? recherche.commune
            : recherche.province
            ? recherche.province
            : recherche.region
            ? recherche.region
            : "",
          limit: 10,
          page: 0,
        },
      })
    );
  };
  const onTypesSelected = (types) => {
    dispatch(
      AnnuairereducerActions.setSearchPayload({
        data: {
          type: types,
          page: 0,
        },
      })
    );
  };
  const onSpecialitesSelected = (types) => {
    dispatch(
      AnnuairereducerActions.setSearchPayload({
        data: {
          specialite: types,
          page: 0,
        },
      })
    );
  };

  return (
    <div className={style.recherche__container}>
      <div className={style.recherche__inner}>
        <div className={style.recherche__title__container}>
          <h1 className={style.recherche__title}>
            {searchPayload.geometry_value
              ? agences.totalItems
                ? `${agences.totalItems} ${t(
                    "agences immobilières parmis les plus performantes à"
                  )}
                    ${t(searchPayload.geometry_value)} ${t("sont sur agenz")}`
                : t("Recherchez une agence immobilière adaptée à votre projet")
              : t("Recherchez une agence immobilière adaptée à votre projet")}
          </h1>
        </div>
        <div className={style.recherche__fields__container}>
          <div
            className={`${styles.recherche__fields__item} ${styles.full_width_recherche_field} ${styles.recherche_field__places}`}
          >
            <label className={styles.label_search}>{t("Rechercher")} </label>
            <PlacesAutocomplete
              enableDefaultSearchList={true}
              placeholder={t("Dans quelle ville ? Quartier ?")}
              postHandleSelect={postHandleSelect}
              page="landing-page"
              hide_icon={false}
              show_icon={false}
              showSearchIcon={true}
              lang={lang}
            />
          </div>
          <div className={styles.recherche__fields__item}>
            <label className={styles.label_search}>{t("Type")} </label>
            <BienTypeSelect
              transactionType={"Vente"}
              onTypesSelected={onTypesSelected}
            />
          </div>
          <div className={styles.recherche__fields__item}>
            <label className={styles.label_search}>{t("Specialite")} </label>
            <SpecialiteTypeSelect
              transactionType={"Vente"}
              onTypesSelected={onSpecialitesSelected}
            />
          </div>
          {/* <div
            className={`${styles.recherche__fields__item} ${styles.recherche__fields__button} ${styles.full_width_recherche_field}`}
          >
            <label className={styles.label_search}> </label>
            <a href="." target="__blank" className={styles.recherche__button}>
              Rechercher
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default Recherche;
