const CheckMark = ({ styles }: { styles?: string }) => {
  return (
    <div className={styles}>
      <svg
        width="7"
        height="6"
        viewBox="0 0 7 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.95429 5.76622L0.203838 3.76456C-0.0679683 3.45135 -0.0679683 2.94826 0.203838 2.63505C0.334852 2.48507 0.513067 2.40081 0.699036 2.40081C0.885005 2.40081 1.06322 2.48507 1.19423 2.63505L2.44976 4.07011L5.80604 0.234237C5.93691 0.0842578 6.11513 0 6.30096 0C6.4868 0 6.66501 0.0843344 6.79589 0.234237C7.06804 0.547293 7.06804 1.0507 6.79589 1.36375L2.9453 5.76622C2.81415 5.9159 2.63593 6 2.4501 6C2.26426 6 2.08605 5.9159 1.9549 5.76622H1.95429Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default CheckMark;
