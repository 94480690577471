import useTranslation from "next-translate/useTranslation";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { actions as EstimationActions } from "redux/slices/v2/EstimationReducer";
import styles from "./DefaultSearchList.module.css";
import {
  actions as ListingreducerActions,
  selectors as ListingReducerSelectors,
} from "redux/slices/v2/ListingReducer";

function index({ 
  handleAdress,
  annonces,
  setSearchBarFull,
  setDefaultSearchList,
}: {
  handleAdress: any;
  annonces?: boolean;
  setSearchBarFull?: any;
  setDefaultSearchList?: any;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation("commonAnnonces");

  const handleGeoLocat = () => {
    dispatch(EstimationActions.setGeolocInProgress(true));
    navigator.geolocation.getCurrentPosition(successLoc, errorLoc, {
      enableHighAccuracy: true,
    });
  };

  const successLoc = (pos) => {
    dispatch(EstimationActions.setGeolocInProgress(false));
    dispatch(
      EstimationActions.setGeocoding({
        lat: pos.coords.latitude,
        lng: pos.coords.longitude,
        pa_id: "",
        quartier: "",
        commune: "",
        province: "",
        region: "",
        marker: true,
        zoom: 15,
      })
    );

    // setCenter({lng: pos.coords.longitude, lat: pos.coords.latitude})
    // setZoom([15]);
  };

  const errorLoc = () => {
    dispatch(EstimationActions.setGeolocInProgress(false));
  };

  return (
    <div className={annonces ? styles.property1defaultAnnonces : styles.property1default}>
      {/* <div
        className={styles.localisationActuelle}
        onClick={(e) => {
          handleGeoLocat();
        }}
      >
        <span>
          {}
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.7147 13.8807C13.9872 14.6083 12.5196 16.0758 11.4141 17.1813C10.633 17.9624 9.36824 17.9623 8.58719 17.1813C7.50158 16.0957 6.06111 14.6552 5.28661 13.8807C2.68311 11.2772 2.68311 7.05612 5.28661 4.45262C7.8901 1.84913 12.1112 1.84913 14.7147 4.45262C17.3182 7.05612 17.3182 11.2772 14.7147 13.8807Z"
              stroke="#4609F0"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12.5007 9.16667C12.5007 10.5474 11.3814 11.6667 10.0007 11.6667C8.61994 11.6667 7.50065 10.5474 7.50065 9.16667C7.50065 7.78596 8.61994 6.66667 10.0007 6.66667C11.3814 6.66667 12.5007 7.78596 12.5007 9.16667Z"
              stroke="#4609F0"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <div className={styles.casablancaMaroc}>
          {t("localisation actuelle")}
        </div>
      </div> */}

      <div className={styles.recherchesPopulairesWrapper}>
        <div className={styles.recherchesPopulaires}>
          {t("Recherches populaires")}
        </div>
      </div>

      <div
        className={styles.suggestions}
        onClick={(e) => {
          e.preventDefault();
          handleAdress("Casablanca, Grand Casablanca-Settat", {
            _id: "Casablanca, Grand Casablanca-Settat",

            center: {
              type: "Point",
              coordinates: [33.566825235191544, -7.600349436148131],
            },
            niveau: "provinces",
            zoom: 11.01,
            speed: 3.5,
            source: "agenz",
            commune: null,
            province: "Casablanca",
            region: "Grand Casablanca-Settat",
            description: "Casablanca, Grand Casablanca-Settat",
            search_field: "casablanca عمالة  الدار البيضاء",
            priority: 1,
            box: [
              [38.906894826897684, -12.310742727526389],
              [27.875015055173368, -2.8899561447698545],
            ],
          });
          if (setSearchBarFull){
            setSearchBarFull(false);
            dispatch(ListingreducerActions.setDisplayMobileSearchBar(true));
          }
          setDefaultSearchList(false);
        }}
      >
        <div className={styles.casablancaMaroc}>{t("Casablanca, Maroc")}</div>
      </div>

      <div
        className={styles.suggestions}
        onClick={(e) => {
          e.preventDefault();
          handleAdress("Rabat, Rabat-Salé-Kénitra", {
            _id: "Rabat, Rabat-Salé-Kénitra",
            center: {
              type: "Point",
              coordinates: [33.967562195245826, -6.836724346521818],
            },
            niveau: "provinces",
            zoom: 11.01,
            speed: 3.5,
            source: "agenz",
            commune: null,
            province: "Rabat",
            region: "Rabat-Salé-Kénitra",
            description: "Rabat, Rabat-Salé-Kénitra",
            search_field: "rabat عمالة  الرباط",
            priority: 1,
            box: [
              [39.28102686785985, -11.5471176379001],
              [28.30030119184746, -2.126331055143538],
            ],
          });
          if (setSearchBarFull){
            setSearchBarFull(false);
            dispatch(ListingreducerActions.setDisplayMobileSearchBar(true))
          }
          setDefaultSearchList(false);
        }}
      >
        <div className={styles.casablancaMaroc}>{t("Rabat, Maroc")}</div>
      </div>

      <div
        className={styles.suggestions}
        onClick={(e) => {
          e.preventDefault();
          handleAdress("Tanger-Médina, Tanger-Assilah", {
            _id: "Tanger-Médina, Tanger-Assilah",
            center: {
              type: "Point",
              coordinates: [35.77219840163203, -5.874425399253664],
            },
            niveau: "communes",
            zoom: 11.4,
            speed: 3.5,
            source: "agenz",
            commune: "Tanger-Médina",
            province: "Tanger-Assilah",
            region: "Tanger-Tetouan-Al Hoceima",
            description: "Tanger-Médina, Tanger-Assilah",
            search_field:
              "tanger medina طنجة المدينة  المقاطعة  tanger assilah",
            priority: 2,
            box: [
              [38.84050794746518, -8.617602035225616],
              [32.58078347838619, -3.1312487632816897],
            ],
          });
          if (setSearchBarFull){
            setSearchBarFull(false);
            dispatch(ListingreducerActions.setDisplayMobileSearchBar(true))
          }
          setDefaultSearchList(false);
        }}
      >
        <div className={styles.casablancaMaroc}>{t("Tanger, Maroc")}</div>
      </div>

      <div
        className={styles.suggestions}
        onClick={(e) => {
          e.preventDefault();
          handleAdress("Marrakech, Marrakech-Safi", {
            _id: "Marrakech, Marrakech-Safi",
            center: {
              type: "Point",
              coordinates: [31.627884112477254, -7.9881716867574895],
            },
            niveau: "provinces",
            zoom: 11.01,
            speed: 3.5,
            source: "agenz",
            commune: null,
            province: "Marrakech",
            region: "Marrakech-Safi",
            description: "Marrakech, Marrakech-Safi",
            search_field: "marrakech عمالة  مراكش",
            priority: 1,
            box: [
              [37.09332057297912, -12.69856497813575],
              [25.821614165071416, -3.2777783953792152],
            ],
          });
          if (setSearchBarFull){
            setSearchBarFull(false);
            dispatch(ListingreducerActions.setDisplayMobileSearchBar(true))
          }
          setDefaultSearchList(false);
        }}
      >
        <div className={styles.casablancaMaroc}>{t("Marrakech, Maroc")}</div>
      </div>
    </div>
  );
}

export default index;
