import React from 'react'

function LinkedIn() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.0004 0C7.38529 0 0.400391 6.9849 0.400391 15.6C0.400391 24.2151 7.38529 31.2 16.0004 31.2C24.6155 31.2 31.6004 24.2151 31.6004 15.6C31.6004 6.9849 24.6155 0 16.0004 0ZM10.1452 7.0239C11.2398 7.0239 11.9691 7.7532 11.9691 8.7256C11.9691 9.698 11.2398 10.4273 10.0243 10.4273C8.92969 10.4286 8.20039 9.698 8.20039 8.7256C8.20039 7.7532 8.92969 7.0239 10.1452 7.0239ZM12.1004 22.1H8.20039V11.7H12.1004V22.1ZM25.1004 22.1H21.4292V16.4164C21.4292 14.8447 20.4503 14.482 20.0837 14.482C19.7171 14.482 18.4925 14.7238 18.4925 16.4164C18.4925 16.6582 18.4925 22.1 18.4925 22.1H14.7004V11.7H18.4938V13.1508C18.9826 12.3045 19.9615 11.7 21.7971 11.7C23.6327 11.7 25.1004 13.1508 25.1004 16.4164V22.1Z" fill="#757E83" />
        </svg>
    )
}

export default LinkedIn
