import {
  useEffect,
  useRef,
  useState,
  useCallback,
  useLayoutEffect,
} from "react";
// import PlacesAutocomplete from "react-places-autocomplete";
import useTranslation from "next-translate/useTranslation";
import debounce from "lodash.debounce";
import getRPCQfromCoords from "utils/getRPCQfromCoords";
import RechercheAdresse from "utils/RechercheAdresse/RechercheAdresse";
import styles from "utils/PlacesAutocomplete/PlacesAutocomplete.module.css";
import { selectors as ListingReducerSelectors } from "redux/slices/v2/ListingReducer";
import { useDispatch, useSelector } from "react-redux";
import { actions as ListingreducerActions } from "redux/slices/v2/ListingReducer";
import { setAgence } from "redux/slices/authReducer";
import { actions as EstimationActions } from "redux/slices/v2/EstimationReducer";
import PROXY_CONFIG, { PROXY_CONFIG_AUTOCOMPLETE } from "Config/ProxyConfig";
import searchIcon from "./searchIcon.svg";
import DotLoading from "components/v2/Listings/SearchBarComponent/components/PlacesAutoComplete/DotLoading";
import { decrypt, encrypt } from "utils/CRYPTO/secret";
import api_call_v2 from "utils/API_CALL/api_call_v2";
import DefaultSearchList from "components/v2/Listings/SearchBarComponent/components/DefaultSearchList";
import { capitaliserPremiereLettre } from "utils/Functions";
import { Diversity3 } from "@mui/icons-material";

declare global {
  interface Window {
    cartedesprix: any;
    cartedesannonces: any;
    google: any;
  }
}
declare var google: any;

const mySvg2 = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    className={styles.show_mobile}
  >
    <path
      d="M13.705 12.295L10.885 9.50001C11.5294 8.60272 11.9136 7.54493 11.9952 6.44323C12.0768 5.34152 11.8527 4.23868 11.3475 3.25623C10.8423 2.27378 10.0757 1.44988 9.13215 0.875327C8.18859 0.300773 7.10472 -0.00212657 6 1.12383e-05C5.915 1.12383e-05 5.835 1.12383e-05 5.75 1.12383e-05C5.665 1.12383e-05 5.585 1.12383e-05 5.5 1.12383e-05C4.04131 1.12383e-05 2.64237 0.579474 1.61092 1.61092C0.579466 2.64237 3.60888e-06 4.04132 3.60888e-06 5.50001C3.60888e-06 5.58501 3.60888e-06 5.66501 3.60888e-06 5.75001C3.60888e-06 5.83501 3.60888e-06 5.91501 3.60888e-06 6.00001C-0.00213419 7.10473 0.300765 8.1886 0.875319 9.13216C1.44987 10.0757 2.27377 10.8423 3.25622 11.3475C4.23867 11.8527 5.34152 12.0768 6.44322 11.9952C7.54493 11.9136 8.60271 11.5294 9.5 10.885L12.32 13.705C12.5113 13.8688 12.7574 13.9544 13.0091 13.9447C13.2607 13.935 13.4995 13.8307 13.6776 13.6526C13.8557 13.4745 13.96 13.2357 13.9697 12.9841C13.9794 12.7324 13.8938 12.4863 13.73 12.295H13.705ZM6 10C5.20888 10 4.43552 9.76542 3.77772 9.32589C3.11993 8.88636 2.60724 8.26165 2.30449 7.53075C2.00173 6.79984 1.92252 5.99557 2.07686 5.21965C2.2312 4.44373 2.61217 3.73099 3.17158 3.17158C3.73099 2.61217 4.44372 2.23121 5.21964 2.07687C5.99557 1.92253 6.79983 2.00174 7.53074 2.30449C8.26164 2.60724 8.88636 3.11993 9.32588 3.77773C9.76541 4.43553 10 5.20889 10 6.00001C10 7.06088 9.57858 8.07829 8.82843 8.82844C8.07829 9.57858 7.06087 10 6 10Z"
      fill="#460AF0"
    />
  </svg>
);

const mySvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="34"
    viewBox="0 0 24 24"
    fill="none"
    className={styles.show_desktop}
  >
    <ellipse
      opacity="0.3"
      cx="12.3824"
      cy="11.1572"
      rx="8.38235"
      ry="8.15722"
      fill="#460AF0"
    />
    <g filter="url(#filter0_d_2582_13595)">
      <ellipse
        cx="12.3833"
        cy="11.1572"
        rx="3.49265"
        ry="3.39884"
        fill="#460AF0"
      />
      <path
        d="M12.3833 15.556C14.8387 15.556 16.8759 13.6121 16.8759 11.1572C16.8759 8.70227 14.8387 6.75833 12.3833 6.75833C9.92789 6.75833 7.89062 8.70227 7.89062 11.1572C7.89062 13.6121 9.92789 15.556 12.3833 15.556Z"
        stroke="white"
        strokeWidth="2"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2582_13595"
        x="0.890625"
        y="0.758331"
        width="22.9844"
        height="22.7977"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2582_13595"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2582_13595"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default function PlacesAutoCompleteInput({
  allowMaps,
  enableMajorZoom,
  enableDefaultSearchList,
  postHandleSelect,
  page,
  placeholder,
  hide_icon,
  show_icon,
  defaultValue,
  showSearchIcon,
  infosProfessionel,
  handleAutoChange,
  setAddressEntreprise,
  setLat,
  setLng,
  errorAddress,
  setErrors,
  isVitrine,
  isPro,
  isEstimation,
  lang = "fr",
  lpHome,
}: {
  allowMaps?: boolean;
  enableMajorZoom?: boolean;
  enableDefaultSearchList?: boolean;
  postHandleSelect?: any;
  page: any;
  placeholder: any;
  defaultValue?: any;
  hide_icon: any;
  show_icon?: boolean;
  showSearchIcon?: boolean;
  infosProfessionel?: boolean;
  handleAutoChange?: (e) => void;
  setAddressEntreprise?: any;
  errorAddress?: boolean;
  isVitrine?: boolean;
  isPro?: boolean;
  isEstimation?: boolean;
  setErrors?: any;
  setLat?: any;
  setLng?: any;
  lang?: string;
  lpHome?: boolean;
}) {
  const { t } = useTranslation("prixImmobilierMaroc");
  const t2 = useTranslation("annonceAchat").t;

  const dispatch = useDispatch();

  const options = {
    componentRestrictions: { country: "MA" },
    fields: ["address_components", "geometry", "icon", "name"],
    strictBounds: false,
  };
  let autocomplete = null;
  const inputRef = useRef<HTMLInputElement>(null);
  const listingsLoading = useSelector(ListingReducerSelectors.loadingSelactor);
  const [rechercheType, setRechercheType] = useState<any>("AGENZ");
  const [suggestionsAgenz, setSuggestionsAgenz] = useState<any>([]);
  const [loadingAgenz, setLoadingAgenz] = useState<any>(false);
  // const currentLocationAddress = useSelector(
  //   ListingReducerSelectors.currentLocationAddressSelactor
  // );
  const [fromSelection, setFromSelection] = useState<any>(true);
  const [address, setAddress] = useState<any>(defaultValue ? defaultValue : "");
  const [
    currentLocationSuggestionVisible,
    setCurrentLocationSuggestionVisible,
  ] = useState<boolean>(false);

  const onAgenzSuggestionSelected = async (suggestion) => {
    setFromSelection(true);
    setSuggestionsAgenz([]);

    setCurrentLocationSuggestionVisible(false);

    setAddress(suggestion.description);
    if (suggestion.source == "agenz") {
      postHandleSelect({
        ...suggestion,
        zoom: enableMajorZoom
          ? suggestion.zoom
          : suggestion.zoom > 14.5
          ? 14
          : suggestion.zoom,
        adresse: suggestion.description,
        lat: Math.max(
          suggestion.center.coordinates[1],
          suggestion.center.coordinates[0]
        ),
        lng: Math.min(
          suggestion.center.coordinates[1],
          suggestion.center.coordinates[0]
        ),
      });
    } else {
      const mapStatefromAddress = {
        ...suggestion,
        lat: suggestion.center
          ? Math.max(
              suggestion.center.coordinates[0],
              suggestion.center.coordinates[1]
            )
          : null,
        lng: suggestion.center
          ? Math.min(
              suggestion.center.coordinates[0],
              suggestion.center.coordinates[1]
            )
          : null,
        adresse: suggestion.description,
      };

      // if (suggestion.source == "google_maps") {
      //   try {
      //     await api_call_v2(
      //       undefined,
      //       "post",
      //       "autocomplete/update",
      //       {
      //         data: {
      //           ...mapStatefromAddress,
      //           description: mapStatefromAddress.adresse,
      //           geometry: null,
      //           center: {
      //             type: "Point",
      //             coordinates: [
      //               mapStatefromAddress.lng,
      //               mapStatefromAddress.lat,
      //             ],
      //           },
      //           priority: mapStatefromAddress.niveau == "pas" ? 4 : 3,
      //           source: "recherche_google_maps",
      //           search_field: `${address.toLowerCase()} ${suggestion.description
      //             .toLowerCase()
      //             .normalize("NFD")
      //             .replace(/&/g, " ")
      //             .replace(/-/g, " ")
      //             .replace(/_/g, " ")
      //             .replace(/,/g, " ")
      //             .replace(".", " ")
      //             .replace(/;/g, " ")
      //             .replace(/:/g, " ")
      //             .replace(/'/g, " ")
      //             .replace(/"/g, " ")
      //             .replace(/[\u0300-\u036f]/g, "")}`,
      //           place_id: suggestion.place_id,
      //           reference: suggestion.reference,
      //           structured_formatting: suggestion.structured_formatting,
      //           terms: suggestion.terms,
      //           types: suggestion.types,
      //         },
      //         priority: mapStatefromAddress.niveau == "pas" ? 4 : 3,
      //         source: "recherche_google_maps",
      //         search_field: `${address.toLowerCase()} ${suggestion.description
      //           .toLowerCase()
      //           .normalize("NFD")
      //           .replace(/&/g, " ")
      //           .replace(/-/g, " ")
      //           .replace(/_/g, " ")
      //           .replace(/,/g, " ")
      //           .replace(".", " ")
      //           .replace(/;/g, " ")
      //           .replace(/:/g, " ")
      //           .replace(/'/g, " ")
      //           .replace(/"/g, " ")
      //           .replace(/[\u0300-\u036f]/g, "")}`,
      //         place_id: suggestion.place_id,
      //         reference: suggestion.reference,
      //         structured_formatting: suggestion.structured_formatting,
      //         terms: suggestion.terms,
      //         types: suggestion.types,
      //       },
      //       "autocomplete"
      //     );
      //   } catch (e) {}
      // }
      postHandleSelect(mapStatefromAddress);
    }
  };
  const [displayLoader, setDisplayLoader] = useState(false);
  const [borderColor, setBorderColor] = useState(false);

  const handleChangeResult = async (value) => {
    setSuggestionsAgenz([]);
    if (value) {
      setLoadingAgenz(true);

      api_call_v2(
        undefined,
        "get",
        "autocomplete/places/box",
        {
          params: {
            search: value,
            allowMaps: allowMaps ? allowMaps : false,
          },
        },
        "autocomplete"
      )
        .then((res) => {
          let results = decrypt(res.data).results.map((item) => {
            if (lang == "ar") {
              item.description_ar
                ? (item.description = item.description_ar
                    .split(", ")
                    .map((word) => {
                      return t2(word);
                    })
                    .join(", "))
                : (item.description = item.description
                    .split(", ")
                    .map((word) => {
                      return t2(capitaliserPremiereLettre(word.toLowerCase()));
                    })
                    .join(", "));
            } else if (lang == "en"){
              item.Description_en
                ? (item.description = item.Description_en
                  .split(", ")
                  .map((word) => {
                    return t2(word);
                  })
                  .join(", "))
                : (item.description = item.description
                  .split(", ")
                  .map((word) => {
                    return t2(capitaliserPremiereLettre(word.toLowerCase()));
                  })
                  .join(", "));
            }
            return item;
          });

          setSuggestionsAgenz(
            results.map((item) => {
              return { ...item, source: allowMaps ? item.source : "agenz" };
            })
          );

          setDisplayLoader(false);
          setLoadingAgenz(false);
        })
        .catch((err) => {});
    } else {
      setLoadingAgenz(false);
    }
  };
  const debouncedResults = useCallback(
    debounce((value) => {
      handleChangeResult(value);
    }, 400),
    []
  );

  // useEffect(() => {
  //   return () => {
  //     debouncedResults.cancel();
  //   };
  // });

  const [spinnerLoading, setSpinnerLoading] = useState<any>(false);

  const firstUpdateSpinner = useRef(true);
  const firstUpdateCLAdresse = useRef(true);
  const firstUpdateAdresse = useRef(true);

  useEffect(() => {
    if (firstUpdateSpinner.current) {
      firstUpdateSpinner.current = false;
      return;
    } else {
      setSpinnerLoading(listingsLoading);
    }
  }, [listingsLoading]);

  // useEffect(() => {
  //   if (firstUpdateCLAdresse.current) {
  //     firstUpdateCLAdresse.current = false;
  //     return;
  //   } else {
  //     setAddress(currentLocationAddress);
  //   }
  // }, [currentLocationAddress]);

  useEffect(() => {
    if (firstUpdateAdresse.current) {
      firstUpdateAdresse.current = false;

      return;
    }
    if (!fromSelection) {
      debouncedResults(address);
    }
  }, [address]);

  // useEffect(() => {
  //   if (!fromSelection) {
  //     debouncedResults(address);
  //   }
  // }, [address]);
  const [styleDiv, setStyleDiv] = useState(
    `${styles.input_new_search_container}`
  );
  const [loadingLocalisation, setLoadingLocalisation] = useState(false);

  const handleGeoLocat = () => {
    setLoadingLocalisation(true);
    navigator.geolocation.getCurrentPosition(successLoc, errorLoc, {
      enableHighAccuracy: true,
    });
  };

  const successLoc = (pos) => {
    setLoadingLocalisation(false);
    dispatch(
      EstimationActions.setGeocoding({
        lat: pos.coords.latitude,
        lng: pos.coords.longitude,
        pa_id: "",
        quartier: "",
        commune: "",
        province: "",
        region: "",
        marker: true,
        zoom: 15,
      })
    );
  };

  const errorLoc = () => {
    setLoadingLocalisation(false);
  };
  const [defaultSearchList, setDefaultSearchList] = useState(false);

  const handleAdress = (value, data) => {
    onAgenzSuggestionSelected(data);
  };

  const dropProperty = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropProperty.current &&
        !dropProperty.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setDefaultSearchList(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (address) setDefaultSearchList(false);
  }, [address]);
  return (
    <div
      id="search-bar-input-auto"
      className={`${styles.search__bar__input___lp_container} ${
        styles.search__bar__input___lp_container_visible
      } ${styles.search__bar__input___lp_container__in_listings} ${
        infosProfessionel ? styles.pa11inputForVitrine : ""
      }`}
    >
      <div
        // autoComplete="off"
        // autoCorrect="off"
        // autoSave="off"
        role="search"
        style={{ height: "100%" }}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div
          className={`${styles.pa115_search_bar} ${
            infosProfessionel ? styles.pa11inputForVitrine : ""
          } ${isPro ? styles.isProContainer : ""}`}
        >
          <div
            className={`${styles.pa115_search_bar__button} ${
              infosProfessionel ? styles.pa11inputForVitrine : ""
            }
            ${isPro ? styles.isProContainer : ""}
            `}
          >
            {/* <!-- fake fields are a workaround for chrome autofill getting the wrong fields --> */}
            {!show_icon ? (
              showSearchIcon ? (
                <div
                  className={styles.input_Lp_search_container}
                  style={{
                    border: !borderColor && address ? "1px solid #D1D1D5" : "",
                  }}
                >
                  <input
                    autoComplete="off"
                    aria-autocomplete="list"
                    ref={inputRef}
                    // defaultValue={defaultValue ? defaultValue : ""}
                    value={address}
                    className={`${styles.input_Lp_search} ${
                      infosProfessionel ? styles.inputForVitrine : ""
                    }`}
                    type="search"
                    onFocus={(e) => {
                      e.preventDefault();
                      setBorderColor(true);
                      if (enableDefaultSearchList) {
                        if (e.target.value === "") setDefaultSearchList(true);
                      }
                    }}
                    onKeyDown={(e) => {
                      setDisplayLoader(true);
                      setFromSelection(false);
                      setSuggestionsAgenz([]);
                    }}
                    onChange={(e) => {
                      if (e.target.value !== "") setDefaultSearchList(false);
                      else if (enableDefaultSearchList) {
                        setDefaultSearchList(true);
                      }
                      if (!e.target.value) {
                        setDisplayLoader(false);
                      }
                      setAddress(e.target.value);
                    }}
                    onBlur={() => {
                      setDisplayLoader(false);
                      setBorderColor(false);
                    }}
                    onBlurCapture={() => {
                      setDisplayLoader(false);
                    }}
                    placeholder={
                      placeholder
                        ? placeholder
                        : t("Entrez une ville, un quartier , une adresse")
                    }
                    // style={{ backgroundColor: "white" }}
                  />
                  {defaultSearchList ? (
                    <div
                      className={styles.pa115_search__results__inner__default}
                    >
                      <div
                        className={styles.pa115_search__results__item}
                        ref={dropProperty}
                      >
                        <DefaultSearchList
                          handleAdress={handleAdress}
                          setDefaultSearchList={setDefaultSearchList}
                        />
                      </div>
                    </div>
                  ) : null}
                  {address ? (
                    <span
                      className={styles.fa__close}
                      onClick={() => {
                        setAddress("");
                      }}
                    >
                      <i className={"fa fa-times"} />
                    </span>
                  ) : (
                    <>
                      {isEstimation ? (
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.5 17.5L12.5 12.5M14.1667 8.33333C14.1667 11.555 11.555 14.1667 8.33333 14.1667C5.11167 14.1667 2.5 11.555 2.5 8.33333C2.5 5.11167 5.11167 2.5 8.33333 2.5C11.555 2.5 14.1667 5.11167 14.1667 8.33333Z"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      ) : (
                        <>
                          {lpHome ? (
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M17.5 18L12.5 13M14.1667 8.83333C14.1667 12.055 11.555 14.6667 8.33333 14.6667C5.11167 14.6667 2.5 12.055 2.5 8.83333C2.5 5.61167 5.11167 3 8.33333 3C11.555 3 14.1667 5.61167 14.1667 8.83333Z"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          ) : (
                            <img
                              data-tracking="click"
                              data-value="search-icon"
                              src={searchIcon.src}
                              alt="search icon"
                              width={22}
                              height={22}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <>
                  <input
                    autoComplete="off"
                    aria-autocomplete="list"
                    ref={inputRef}
                    defaultValue={defaultValue ? defaultValue : ""}
                    value={address}
                    className={`${styles.pa115_input__form__search} ${
                      styles.search__bar__input__lp_40
                    } ${styles.search_bar_in_lisings} ${
                      infosProfessionel ? styles.inputForVitrine : ""
                    } ${errorAddress ? styles.inputBorderError : ""}
                    ${isPro ? styles.inputIsPro : ""}
                    ${isEstimation ? styles.inputEstimation : ""}
                    `}
                    type="search"
                    onFocus={(e) => {
                      e.preventDefault();
                    }}
                    onKeyDown={(e) => {
                      setDisplayLoader(true);
                      setFromSelection(false);
                      setSuggestionsAgenz([]);
                    }}
                    onChange={(e) => {
                      if (!e.target.value) {
                        setDisplayLoader(false);
                      }
                      setAddress(e.target.value);
                      if (setAddressEntreprise) {
                        setAddressEntreprise(e.target.value);
                      }
                      if (!e.target.value || e === undefined) {
                        if (setLat) {
                          setLat(0);
                          setLng(0);
                        }
                      }
                    }}
                    onBlur={() => {
                      setDisplayLoader(false);
                    }}
                    onBlurCapture={() => {
                      setDisplayLoader(false);
                    }}
                    placeholder={
                      placeholder
                        ? placeholder
                        : t("Entrez une ville, un quartier , une adresse")
                    }
                    style={{
                      backgroundColor: "white",
                    }}
                  />
                  {address && (
                    <span
                      onClick={() => {
                        setAddress("");
                        if (setAddressEntreprise) {
                          setAddressEntreprise("");
                        }
                      }}
                      className={styles.empty}
                    >
                      <i className={"fas fa-times"} />
                    </span>
                  )}
                </>
              )
            ) : (
              <div className={styleDiv} id="div_new">
                <input
                  autoComplete="off"
                  aria-autocomplete="list"
                  ref={inputRef}
                  value={address}
                  className={styles.input_new_search}
                  tabIndex={0}
                  onFocus={(e) => {
                    e.preventDefault();
                    setStyleDiv(`${styles.input_new_search_container2}`);
                  }}
                  onBlur={() => {
                    setStyleDiv(`${styles.input_new_search_container}`);
                  }}
                  onKeyDown={(e) => {
                    setDisplayLoader(true);
                    setFromSelection(false);
                    setSuggestionsAgenz([]);
                  }}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  placeholder={
                    placeholder
                      ? placeholder
                      : t("Entrez une ville, un quartier,  une adresse")
                  }
                  style={{ backgroundColor: "white" }}
                />

                {address ? (
                  <span
                    className={styles.fa__close}
                    onClick={() => {
                      setAddress("");
                    }}
                  >
                    <i className={"fa fa-times"} />
                  </span>
                ) : (
                  <>
                    {mySvg2}
                    {mySvg}
                  </>
                )}
              </div>
            )}
          </div>
          <div
            className={styles.pa115_search__results}
            style={{
              // minWidth: '400',
              top: "62px",
            }}
          >
            {(loadingAgenz || displayLoader) && (
              // {true && (
              <div className={styles.pa115_search__results__item}>
                <div className={styles.pa115_search__results__item__inner}>
                  <div className={styles.search__results__suggestion}>
                    <DotLoading />
                  </div>
                </div>
              </div>
            )}
            <div className={styles.pa115_search__results__inner}>
              {currentLocationSuggestionVisible && (
                <div
                  onMouseDown={() => {
                    setSpinnerLoading(true);
                    // onGeolocatePress();
                  }}
                  className={styles.pa115_search__results__item}
                >
                  <p className={styles.search__results__suggestion}>
                    <>
                      <i
                        aria-hidden
                        className="fa-solid fa-location-dot"
                        style={{
                          marginLeft: "0px",
                          color: "rgba(0, 0, 0, 0.4)",
                        }}
                      ></i>
                      {t("Localisation actuelle")}{" "}
                    </>
                  </p>
                </div>
              )}

              {suggestionsAgenz.map((suggestion) => {
                const style = {
                  backgroundColor: suggestion.active ? "#ddd" : "transparent",
                };
                return (
                  <div
                    className={styles.pa115_search__results__item}
                    onClick={() => {
                      onAgenzSuggestionSelected(suggestion);
                    }}
                  >
                    <div className={styles.pa115_search__results__item__inner}>
                      <p className={styles.search__results__suggestion}>
                        {/* <span className={styles.search__results__suggestion__part}> */}
                        {suggestion.description}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
