// import axios, { AxiosResponse } from "axios";
import PROXY_CONFIG, {
  PROXY_CONFIG_AUTOCOMPLETE,
  PROXY_CONFIG_V3,
} from "../../Config/ProxyConfig";
import { PROXY_CONFIG_PROJETNEUF } from "../../Config/ProxyConfig";
import { PROXY_CONFIG_ESTIMATION } from "../../Config/ProxyConfig";
import { PROXY_CONFIG_UPLOAD } from "../../Config/ProxyConfig";

import nookies, { parseCookies, setCookie } from "nookies";
import { encrypt, decrypt } from "utils/CRYPTO/secret";

import {
  setAuthenticatedPro,
  setAuthenticatedPromoteur,
  setAuthenticated,
  updateUser,
  signInR,
  setUID,
  setEmail,
} from "redux/slices/authReducer";
import { head } from "lodash";
import { LogarithmicScale } from "chart.js";
const set_user_data = (user, store) => {
  return new Promise((resolve, reject) => {
    if (store) {
      store.dispatch(updateUser({ data: user }));
      store.dispatch(signInR({ data: user }));
      store.dispatch(setUID({ data: user._id }));
      store.dispatch(setEmail({ data: user.email }));
      if (user.isPro) {
        store.dispatch(setAuthenticated({}));
        store.dispatch(setAuthenticatedPro({}));

        if (user.isPromoteur) {
          store.dispatch(setAuthenticatedPromoteur({}));
        }
      }
      resolve("done");
    } else {
      reject("no store provided");
    }
  });
};
const handle_res_headers = (res, ctx) => {
  if (res.headers.get("token")) {
    nookies.set(undefined, "AccessToken", res.headers.get("token"), {
      maxAge: 30 * 24 * 60 * 60,
      path: "/",
    });
    if (ctx) {
      nookies.set(ctx, "AccessToken", res.headers.get("token"), {
        maxAge: 30 * 24 * 60 * 60,
        path: "/",
      });
    } else {
      setCookie(undefined, "AccessToken", res.headers.get("token"), {
        maxAge: 30 * 24 * 60 * 60,
        path: "/",
      });
    }
  }
  if (res.headers.get("refresh")) {
    nookies.set(undefined, "RefreshToken", res.headers.get("refresh"), {
      maxAge: 30 * 24 * 60 * 60,
      path: "/",
    });
    if (ctx) {
      nookies.set(ctx, "RefreshToken", res.headers.get("refresh"), {
        maxAge: 30 * 24 * 60 * 60,
        path: "/",
      });
    } else {
      setCookie(undefined, "RefreshToken", res.headers.get("refresh"), {
        maxAge: 30 * 24 * 60 * 60,
        path: "/",
      });
    }
  }
};
const api_call_v2: (
  ctx?: any,
  type?: "post" | "get",
  endpoint?: string,
  body?: any,
  baseUrl?: string,
  requireHeader?: boolean,
  config?: object | any,
  store?: any,
  specificUrl?: string
) => Promise<any> = (
  ctx = undefined,
  type = "post",
  endpoint = "/",
  body = {},
  baseUrl = "cloud_functions",
  requireHeader = false,
  config = undefined,
  store = null,
  specificUrl = undefined
) => {
  let url = "";
  // if(specificUrl){
  switch (baseUrl) {
    case "cloud_functions":
      url = PROXY_CONFIG;
      break;
    case "v3":
      url = PROXY_CONFIG_V3;
      break;
    case "autocomplete":
      url = PROXY_CONFIG_AUTOCOMPLETE;
      break;
    case "uploads":
      url = PROXY_CONFIG_UPLOAD;
      break;
    case "projet_neuf":
      url = PROXY_CONFIG_PROJETNEUF;
      break;
    case "estimation":
      url = PROXY_CONFIG_ESTIMATION;
      break;
    default:
      url = PROXY_CONFIG;
  }
  // }else {
  //   url=specificUrl;
  // }
  return new Promise((resolve, reject) => {
    let cookies;
    if (ctx) {
      cookies = nookies.get(ctx);
    } else {
      cookies = parseCookies();
    }
    const token = cookies.AccessToken;
    const refreshToken = cookies.RefreshToken;

    let user = null;
    let recommanded_listings = [];
    // if (token) {
    //   axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    // }
    // if (refreshToken) {
    //   axios.defaults.headers.common["refresh"] = refreshToken;
    // }
    const headers = new Headers();
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
      headers.append("authorization", `Bearer ${token}`);
      headers.append("refresh", refreshToken);
    } else if (refreshToken) {
      headers.append("refresh", refreshToken);
    }
    try {
      if (endpoint == "uploadListingImage") {
        for (var pair of body.entries()) {
        }
        fetch(`${url}/${endpoint}`, {
          method: "POST",
          body: body,
        })
          .then((res) => {
            // if (res.headers.user) {
            //   set_user_data(decrypt(res.headers.user), store);
            // }
            handle_res_headers(res, ctx);
            if (res.ok) {
              res.json().then((result) => {
                resolve({ data: result });
              });
            } else {
              res
                .json()
                .then((err) => {
                  reject(err);
                })
                .catch((err) => {
                  reject(err);
                });
            }
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        headers.append("Content-Type", "application/json");
        // ENDPOINT LOGIN NOT READY YET
        if (requireHeader && !token && !refreshToken) {
          reject({ err: true, message: "session expired", expired: true });
        } else {
          if (endpoint == "login" || endpoint == "signup") {
            fetch(`${url}/${endpoint}`, {
              method: "POST",
              headers: headers,
              body: JSON.stringify(body),
            })
              .then((res: any) => {
                if (!res.ok) {
                  res.json().then((err) => {
                    reject(err);
                  });
                } else {
                  res.json().then((result) => {
                    nookies.set(undefined, "AccessToken", result.token, {
                      maxAge: 30 * 24 * 60 * 60,
                      path: "/",
                    });
                    nookies.set(
                      undefined,
                      "RefreshToken",
                      result.refreshToken,
                      {
                        maxAge: 30 * 24 * 60 * 60,
                        path: "/",
                      }
                    );
                    if (ctx) {
                      nookies.set(ctx, "AccessToken", result.token, {
                        maxAge: 30 * 24 * 60 * 60,
                        path: "/",
                      });
                      nookies.set(ctx, "RefreshToken", result.refreshToken, {
                        maxAge: 30 * 24 * 60 * 60,
                        path: "/",
                      });
                    } else {
                      setCookie(undefined, "AccessToken", result.token, {
                        maxAge: 30 * 24 * 60 * 60,
                        path: "/",
                      });
                      setCookie(
                        undefined,
                        "RefreshToken",
                        result.refreshToken,
                        {
                          maxAge: 30 * 24 * 60 * 60,
                          path: "/",
                        }
                      );
                    }
                    resolve({ data: result });
                  });
                }
              })
              .catch((err) => {
                if (err) {
                  try {
                    err
                      .json()
                      .then((error) => {
                        reject(error);
                      })
                      .catch((error) => {
                        reject(err);
                      });
                  } catch (e) {
                    reject(err);
                  }
                } else {
                  reject(err);
                }
              });
          } else if (
            endpoint == "auth/getUser" ||
            endpoint == "/getUser" ||
            type == "get"
          ) {
            let query = null;
            if (body?.params) {
              const filteredParams: any = Object.fromEntries(
                Object.entries(body?.params).filter(
                  ([key, value]) => value !== null && value !== undefined
                )
              );
              query = new URLSearchParams(filteredParams).toString();
            }

            fetch(`${url}/${endpoint}${query ? `?${query}` : ""}`, {
              method: "GET",
              headers: headers,
            })
              .then((res: any) => {
                if (!res.ok) {
                  res
                    .json()
                    .then((err) => {
                      reject(err);
                    })
                    .catch((err) => {
                      reject({ err: "something wrong happened" });
                    });
                } else {
                  try {
                    res
                      .json()
                      .then((result) => {
                        handle_res_headers(res, ctx);

                        resolve({ data: result });
                      })
                      .catch((err) => {});
                  } catch (e) {
                    reject(e);
                  }
                }
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            if (config) {
              fetch(`${url}/${endpoint}`, {
                method: "POST",
                headers: headers,
                ...config,
                body: body,
                // : JSON.stringify(body),
              })
                .then((res) => {
                  if (!res.ok) {
                    res.json().then((err) => {
                      reject(err);
                    });
                  } else {
                    res.json().then((result) => {
                      if (res.headers.get("refresh")) {
                        nookies.set(
                          undefined,
                          "RefreshToken",
                          res.headers.get("refresh"),
                          {
                            maxAge: 30 * 24 * 60 * 60,
                            path: "/",
                          }
                        );
                        if (ctx) {
                          nookies.set(
                            ctx,
                            "RefreshToken",
                            res.headers.get("refresh"),
                            {
                              maxAge: 30 * 24 * 60 * 60,
                              path: "/",
                            }
                          );
                        } else {
                          setCookie(
                            undefined,
                            "RefreshToken",
                            res.headers.get("refresh"),
                            {
                              maxAge: 30 * 24 * 60 * 60,
                              path: "/",
                            }
                          );
                        }
                      }

                      resolve({ data: result });
                    });
                  }
                })
                .catch((err) => {
                  err
                    .json()
                    .then((error) => {
                      reject(error);
                    })
                    .catch((error) => {
                      reject(err);
                    });
                });
            } else {
              fetch(`${url}/${endpoint}`, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(body),
              })
                .then((res) => {
                  handle_res_headers(res, ctx);
                  if (!res.ok) {
                    res.json().then((err) => {
                      reject(err);
                    });
                  } else {
                    try {
                      res.json().then((result) => {
                        resolve({ data: result });
                      });
                    } catch (e) {
                      resolve({});
                    }
                  }
                })
                .catch((err) => {
                  try {
                    err
                      .json()
                      .then((error) => {
                        reject(error);
                      })
                      .catch((error) => {
                        reject(err);
                      });
                  } catch (e) {
                    reject({});
                  }
                });
            }
          }
        }
      }
    } catch (e) {
      reject({ error: "Something wrong happened" });
    }
  });
};

export default api_call_v2;
