import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "annonce",
  initialState: {
    dialogAnnonce: false,
  },
  reducers: {
    setDialogOpen: (state, action) => {
      return { ...state, dialogAnnonce: action.payload.data };
    },
  },
});

export const { setDialogOpen } = slice.actions;
export default slice.reducer;
