import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const initialState = {
  activeStep: 1,

  etatdAchat: "",
  quandAcheter: "",
  ville: "",

  typeUsageDuBien: "",
  typeDuBien: "",

  budgetDeLaMaison: "",
  Apport: "",
  Apportpourcentage: "",

  nom: "",
  prenom: "",
  phone: "",
  email: "",
  interditBanquaire: false,
};

const slice = createSlice({
  name: "selectDemandUnCredit",
  initialState,
  reducers: {
    resetReducerSimulateurCreditsv2(state, action) {
      return {
        activeStep: 1,
        etatdAchat: "",
        quandAcheter: "",
        ville: "",
        typeUsageDuBien: "",
        typeDuBien: "",
        budgetDeLaMaison: "",
        Apport: "",
        Apportpourcentage: "",
        nom: "",
        prenom: "",
        phone: "",
        email: "",
        interditBanquaire: false,
      };
    },
    setActivesTep(state, action) {
      return {
        ...state,
        activeStep: action.payload,
      };
    },
    setFirstFormAnswers(state, action) {
      return {
        ...state,
        activeStep: 2,
        etatdAchat: action.payload.etatdAchat,
        quandAcheter: action.payload.quandAcheter,
        ville: action.payload.ville,
      };
    },
    setSecondFormAnswers(state, action) {
      return {
        ...state,
        activeStep: 3,
        typeUsageDuBien: action.payload.typeUsageDuBien,
        typeDuBien: action.payload.typeDuBien,
      };
    },
    setThirdFormAnswers(state, action) {
      return {
        ...state,
        activeStep: action.payload.activeStep ? action.payload.activeStep : 4,
        budgetDeLaMaison: action.payload.budgetDeLaMaison,
        Apport: action.payload.Apport,
        Apportpourcentage: action.payload.Apportpourcentage,
      };
    },
    setFourthFormAnswers(state, action) {
      return {
        ...state,
        activeStep: 5,
        nom: action.payload.nom,
        prenom: action.payload.prenom,
        phone: action.payload.phone,
        email: action.payload.email,
        interditBanquaire: action.payload.interditBanquaire,
        uid: action.payload.uid,
      };
    },
  },
});

const selectDemandUnCredit = (state: any) =>
  state.selectDemandUnCredit || initialState;

const activeStepSelector = createSelector(
  [selectDemandUnCredit],
  (substate) => substate.activeStep
);

const etatdAchat = createSelector(
  [selectDemandUnCredit],
  (substate) => substate.etatdAchat
);

export const selectors = {
  selectDemandUnCredit,
  activeStepSelector,
  etatdAchat,
};

export const actions = {
  ...slice.actions,
};
export default slice.reducer;
