import formatUrl from "utils/FormatUrl";
import styles from "./Pagination.module.css";
import { useEffect, useState } from "react";
import useTranslation from "next-translate/useTranslation";
const Pagination = ({
  activePage,
  numberPages,
  pagesToDisplay,
  onPageChange,
  noScriptLink,
  rel,
}: {
  activePage?: number;
  numberPages?: number;
  pagesToDisplay?: number;
  onPageChange?: (page: number) => void;
  noScriptLink?: string;
  rel?: string;
}) => {
  const { lang, t } = useTranslation("annonces");
  const [active, setActive] = useState(activePage);

  useEffect(() => {
    setActive(activePage);
  }, [activePage]);


  return (
    <div className={styles.pagination__container}>
      <div className={styles.pagination__inner}>
        {active == 0 ? (
          <div className={`${styles.pagination__chevron_container}`}>
            <i
              className={`"fa-solid fa-chevron-left" ${styles.chevronMobile}`}
              style={{ color: "rgba(0, 0, 0, 0.2)" }}
            ></i>
          </div>
        ) : (
          <div
            className={styles.pagination__chevron_container}
            onClick={() => {
              setActive(active - 1);
              onPageChange(active - 1);
            }}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </div>
        )}
        {numberPages > 1 ? (
          <>
            <ul className={styles.desktop}>
              {numberPages > pagesToDisplay && active + 1 == numberPages ? (
                <>
                  <li
                    key={numberPages}
                    className={`${styles.pagination__number__container} ${
                      numberPages == active
                        ? styles.pagination_number__container_active
                        : ""
                    }`}
                    onClick={() => {
                      setActive(0);
                      onPageChange(0);
                    }}
                  >
                    {noScriptLink ? (
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        href={
                          formatUrl(`${noScriptLink}`, lang).split("?page=0")[0]
                        }
                        className={`${styles.pagination_number} ${
                          numberPages == active
                            ? styles.pagination_number_active
                            : ""
                        } ${styles.number}`}
                        rel={rel ? rel : "index,follow"}
                      >
                        1
                      </a>
                    ) : (
                      <div
                        className={`${styles.pagination_number} ${
                          numberPages == active
                            ? styles.pagination_number_active
                            : ""
                        }`}
                        onClick={() => {
                          setActive(0);
                          onPageChange(0);
                        }}
                      >
                        <span className={`${styles.number}`}>{1}</span>
                      </div>
                    )}
                  </li>
                  {/* <li
                key={"..."}
                className={`${styles.pagination__number__container} ${styles.DesktopV}`}
              >
                <div className={`${styles.pagination_number}`}>
                  <span className={`${styles.number} `}>...</span>
                </div>
              </li> */}
                  <li
                    key={"sur"}
                    className={`${styles.pagination__number__container} ${styles.MobileV}`}
                  >
                    <div className={`${styles.pagination_number}`}>
                      <span className={`${styles.number} `}>{"sur"}</span>
                    </div>
                  </li>
                </>
              ) : null}
              {numberPages > pagesToDisplay
                ? active > pagesToDisplay
                  ? Array.from(Array(pagesToDisplay).keys()).map(
                      (item, key) => {
                        item = active - pagesToDisplay + item + 1;
                        return (
                          <li
                            key={key}
                            className={`${
                              styles.pagination__number__container
                            } ${
                              item == active
                                ? styles.pagination_number__container_active
                                : ""
                            }`}
                            onClick={() => {
                              setActive(item);
                              onPageChange(item);
                            }}
                          >
                            {noScriptLink ? (
                              <a
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                                href={
                                  formatUrl(
                                    `${noScriptLink}?page=${item}`,
                                    lang
                                  ).split("?page=0")[0]
                                }
                                className={`${styles.pagination_number} ${
                                  item == active
                                    ? styles.pagination_number_active
                                    : ""
                                } ${styles.number}`}
                                rel={rel ? rel : "index,follow"}
                              >
                                {item + 1}
                              </a>
                            ) : (
                              <div
                                className={`${styles.pagination_number} ${
                                  item == active
                                    ? styles.pagination_number_active
                                    : ""
                                }`}
                                onClick={() => {
                                  setActive(item);
                                  onPageChange(item);
                                }}
                              >
                                <span className={`${styles.number} `}>
                                  {item + 1}
                                </span>
                              </div>
                            )}
                          </li>
                        );
                      }
                    )
                  : Array.from(
                      Array(Math.min(numberPages - active, 5)).keys()
                    ).map((item, key) => {
                      item = item + active;
                      return (
                        <li
                          key={key}
                          className={`${styles.pagination__number__container} ${
                            item == active
                              ? styles.pagination_number__container_active
                              : ""
                          } ${styles.DesktopV}`}
                          onClick={() => {
                            setActive(item);
                            onPageChange(item);
                          }}
                        >
                          {noScriptLink ? (
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                              href={
                                formatUrl(
                                  `${noScriptLink}?page=${item}`,
                                  lang
                                ).split("?page=0")[0]
                              }
                              className={`${styles.pagination_number} ${
                                item == active
                                  ? styles.pagination_number_active
                                  : ""
                              } ${styles.number}`}
                              rel={rel ? rel : "index,follow"}
                            >
                              {item + 1}
                            </a>
                          ) : (
                            <div
                              className={`${styles.pagination_number} ${
                                item == active
                                  ? styles.pagination_number_active
                                  : ""
                              }`}
                              onClick={() => {
                                setActive(item);
                                onPageChange(item);
                              }}
                            >
                              <span className={`${styles.number}`}>
                                {item + 1}
                              </span>
                            </div>
                          )}
                        </li>
                      );
                    })
                : Array.from(
                    Array(Math.min(numberPages, pagesToDisplay - 1)).keys()
                  ).map((item, key) => {
                    return (
                      <li
                        key={key}
                        className={`${styles.pagination__number__container} ${
                          item == active
                            ? styles.pagination_number__container_active
                            : ""
                        }`}
                        onClick={() => {
                          setActive(item);
                          onPageChange(item);
                        }}
                      >
                        {noScriptLink ? (
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                            href={
                              formatUrl(
                                `${noScriptLink}?page=${item}`,
                                lang
                              ).split("?page=0")[0]
                            }
                            className={`${styles.pagination_number} ${
                              item == active
                                ? styles.pagination_number_active
                                : ""
                            } ${styles.number}`}
                            rel={rel ? rel : "index,follow"}
                          >
                            {item + 1}
                          </a>
                        ) : (
                          <div
                            className={`${styles.pagination_number} ${
                              item == active
                                ? styles.pagination_number_active
                                : ""
                            }`}
                            onClick={() => {
                              setActive(item);
                              onPageChange(item);
                            }}
                          >
                            <span className={`${styles.number} `}>
                              {item + 1}
                            </span>
                          </div>
                        )}
                      </li>
                    );
                  })}

              {numberPages > pagesToDisplay && active + 1 < numberPages ? (
                <>
                  {/* <li
                key={"..."}
                className={`${styles.pagination__number__container} ${styles.DesktopV}`}
              >
                <div className={`${styles.pagination_number}`}>
                  <span className={`${styles.number} `}>...</span>
                </div>
              </li> */}
                  <li
                    key={"sur"}
                    className={`${styles.pagination__number__container} ${styles.MobileV}`}
                  >
                    <div className={`${styles.pagination_number}`}>
                      <span className={`${styles.number} `}>{"sur"}</span>
                    </div>
                  </li>
                  <li
                    key={numberPages}
                    className={`${styles.MobileV} ${
                      styles.pagination__number__container
                    } ${
                      numberPages == active
                        ? styles.pagination_number__container_active
                        : ""
                    }`}
                    onClick={() => {
                      setActive(numberPages - 1);
                      onPageChange(numberPages - 1);
                    }}
                  >
                    {noScriptLink ? (
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        href={
                          formatUrl(
                            `${noScriptLink}?page=${numberPages}`,
                            lang
                          ).split("?page=0")[0]
                        }
                        className={`${styles.pagination_number} ${
                          numberPages == active
                            ? styles.pagination_number_active
                            : ""
                        } ${styles.number}`}
                        rel={rel ? rel : "index,follow"}
                      >
                        {numberPages}
                      </a>
                    ) : (
                      <div
                        className={`${styles.pagination_number} ${
                          numberPages == active
                            ? styles.pagination_number_active
                            : ""
                        }`}
                        onClick={() => {
                          setActive(numberPages - 1);
                          onPageChange(numberPages - 1);
                        }}
                      >
                        <span className={`${styles.number} `}>
                          {numberPages}
                        </span>
                      </div>
                    )}
                  </li>
                </>
              ) : null}
            </ul>
            <div className={styles.mobile}>
              <div className={styles.mobile_display__container}>
                <span className={styles.mobile_text}>
                  {t("Page")} {active + 1} sur {numberPages}{" "}
                </span>
              </div>
            </div>
          </>
        ) : null}

        {numberPages > 1 ? (
          active == numberPages - 1 ? (
            <div className={`${styles.pagination__chevron_container}`}>
              <i
                className={`"fa-solid fa-chevron-right" ${styles.chevronMobile}`}
                style={{ color: "rgba(0, 0, 0, 0.2)" }}
              ></i>
            </div>
          ) : (
            <div
              className={styles.pagination__chevron_container}
              onClick={() => {
                setActive(active + 1);
                onPageChange(active + 1);
              }}
            >
              <i className="fa-solid fa-chevron-right"></i>
            </div>
          )
        ):(null)
        }
      </div>
    </div>
  );
};

export default Pagination;
