import api_call_v2 from "utils/API_CALL/api_call_v2";
import nookies from "nookies";
// import { getAnalytics, setUserId } from "firebase/analytics";

import {
  setAuthenticatedPro,
  setAuthenticatedPromoteur,
  setAuthenticated,
  updateUser,
  signInR,
  setUID,
  setEmail,
  setUserSpaceRoute,
} from "redux/slices/authReducer";
import { actions as ProSpaceActions } from "redux/slices/v2/ProSpace";
import { setAgence, setAgenceCopy } from "redux/slices/authReducer";

const setContactsInfos = (contacts, store) => {
  store.dispatch(ProSpaceActions.setContactsLoading({ data: false }));
  store.dispatch(ProSpaceActions.setContactsRefreshing({ data: false }));
  store.dispatch(
    ProSpaceActions.setContactsNumber({ data: contacts.totalItems })
  );
  store.dispatch(ProSpaceActions.setContacts({ data: contacts.results }));

  if (contacts.results.length === 0) {
    store.dispatch(
      ProSpaceActions.setContactsError({
        contactsError: true,
      })
    );
  }
  store.dispatch(
    ProSpaceActions.setContactsNotification({ data: contacts.notifications })
  );
};

const setMessagesInfos = (messages, store) => {
  store.dispatch(
    ProSpaceActions.setTotalInboxMessages({
      data: messages.totalItems,
    })
  );
  store.dispatch(
    ProSpaceActions.setInboxMessages({
      data: messages.results,
    })
  );
  store.dispatch(ProSpaceActions.setInboxRefreshing({ data: false }));
  store.dispatch(
    ProSpaceActions.setInboxNotification({ data: messages.notifications })
  );
};

const setReviewsInfos = (reviews, store) => {
  store.dispatch(
    ProSpaceActions.setAvisData({
      data: reviews.results?.length ? reviews.restuls : [],
    })
  );
  store.dispatch(ProSpaceActions.setTotalAvis({ data: reviews.totalItems }));
  store.dispatch(
    ProSpaceActions.setGlobalAvis({
      data: {
        noteGlobal: reviews.noteGlobal,
        stars_1_per: reviews.stars_1_per,
        stars_2_per: reviews.stars_2_per,
        stars_3_per: reviews.stars_3_per,
        stars_4_per: reviews.stars_4_per,
        stars_5_per: reviews.stars_5_per,
      },
    })
  );
  store.dispatch(ProSpaceActions.setAvisFirstLoading({ data: false }));
  store.dispatch(ProSpaceActions.setAvisLoading({ data: false }));
};

const setSimilairAnnonces = (ctx, store, annonces) => {
  const success = annonces.results.map((item, id) => {
    if (item._id === ctx.params.id) {
      store.dispatch(ProSpaceActions.setSimilairAnnonce({ data: item }));
      return true;
    } else return false;
  });
  let i = 0;
  while (i < success.length) {
    if (success[i] === true) return true;
    i++;
  }
  return false;
};

export const checkUserPro = (ctx, store, route) => {
  return new Promise(async (resolve, reject) => {
    store.dispatch(
      ProSpaceActions.setCurrentRout({
        currentRoute: route,
      })
    );
    const cookies = nookies.get(ctx);
    const accessToken = cookies.AccessToken;

    if (accessToken) {
      api_call_v2(ctx, "get", "pro/data", {}, "cloud_functions", true)
        .then(async (res) => {
          let {
            agence,
            vitrineViews,
            contactPhoneMail,
            annonces,
            vendu,
            reviews,
            contacts,
            messages,
            suspended,
            ...user
          } = res.data.userProData;

          let ventes: any = vendu;

          const userSimilair = {
            user: {},
            similair: false,
          };
          userSimilair.user = user;
          store.dispatch(setAuthenticated({}));
          store.dispatch(updateUser({ data: user }));
          store.dispatch(signInR({ data: user }));
          store.dispatch(setUID({ data: user._id }));
          store.dispatch(setEmail({ data: user.email }));
          store.dispatch(setAuthenticatedPro({}));
          store.dispatch(setAgence({ data: agence }));
          store.dispatch(setAgenceCopy({ data: agence }));
          store.dispatch(
            ProSpaceActions.setUserMessagesVisibility({
              data: contactPhoneMail,
            })
          );
          store.dispatch(
            ProSpaceActions.setcontactPhoneMailLoader({ data: false })
          );
          store.dispatch(
            ProSpaceActions.setUserVitrineView({
              data: vitrineViews,
            })
          );
          store.dispatch(
            ProSpaceActions.setAnnonces({ data: annonces.results })
          );
          if (
            route === "PRO_SPACE_ANNONCE_PUBLIER_SIMILAIR" ||
            route === "PRO_SPACE_ANNONCE_PUBLIER_SIMILAIR_VENDU"
          )
            userSimilair.similair = setSimilairAnnonces(ctx, store, annonces);
          store.dispatch(ProSpaceActions.setVentes({ data: ventes.results }));
          store.dispatch(
            ProSpaceActions.setTotalAnnoncesNumbers({
              data: {
                totalAnnonces: annonces.totalItems,
                totalRejectedAnnonces: annonces.totalRejectedItems,
                totalValidAnnonces: annonces.totalValidItems,
                totalStandByAnnonces: annonces.totalStandByItems,
              },
            })
          );
          store.dispatch(
            ProSpaceActions.setTotalVentesNumbers({
              data: {
                totalAnnonces: ventes.totalItems,
                totalRejectedAnnonces: ventes.totalRejectedItems,
                totalValidAnnonces: ventes.totalValidItems,
                totalStandByAnnonces: annonces.totalStandByItems,
              },
            })
          );

          setContactsInfos(contacts, store);
          setMessagesInfos(messages, store);
          setReviewsInfos(reviews, store);
          if (
            route === "PRO_SPACE_ANNONCE_PUBLIER_SIMILAIR" ||
            route === "PRO_SPACE_ANNONCE_PUBLIER_SIMILAIR_VENDU"
          )
            resolve(userSimilair);
          else resolve(user);
        })
        .catch((err) => {
          store.dispatch(ProSpaceActions.setInboxRefreshing({ data: false }));
          store.dispatch(ProSpaceActions.setInboxLoading({ data: false }));
          store.dispatch(
            ProSpaceActions.setVitrineLoadersLoaders({ data: false })
          );
          resolve(null);
        });
    } else {
      resolve(null);
    }
  });
};

export const checkUser = (ctx, store, route = null) => {
  return new Promise(async (resolve, reject) => {
    const cookies = nookies.get(ctx);
    const accessToken = cookies.AccessToken;
    if (route) {
      store.dispatch(setUserSpaceRoute({ data: route }));
    }

    if (accessToken) {
      api_call_v2(ctx, "get", "auth/getUser", {}, "cloud_functions", true)
        .then(async (res) => {
          let user: any = res.data;
          store.dispatch(updateUser({ data: user }));
          store.dispatch(signInR({ data: user }));
          store.dispatch(setUID({ data: user._id }));
          store.dispatch(setEmail({ data: user.email }));
          if (user.isPro) {
            store.dispatch(setAuthenticated({}));
            store.dispatch(setAuthenticatedPro({}));
            await api_call_v2(
              ctx,
              "get",
              "getAgenceUser",
              {
                user_id: user._id,
                isPromoteur: user.isPromoteur ? user.isPromoteur : false,
              },
              "cloud_functions",
              true
            )
              .then(async (result) => {
                try {
                  store.dispatch(setAgence({ data: result.data }));
                  store.dispatch(setAgenceCopy({ data: result.data }));
                } catch (e) {}
              })
              .catch((err) => {});

            if (user.isPromoteur) {
              store.dispatch(setAuthenticatedPromoteur({}));
              resolve(user);
            } else {
              resolve(user);
            }
          } else {
            resolve(user);
          }
        })
        .catch((err) => {
          resolve(null);
        });
    } else {
      resolve(null);
    }
  });
};

export const checkUserClientSide = (ctx, dispatch) => {
  return new Promise(async (resolve, reject) => {
    const cookies = nookies.get(undefined);
    const accessToken = cookies.AccessToken;
    if (accessToken) {
      api_call_v2(ctx, "get", "auth/getUser", {}, "cloud_functions", true)
        .then(async (res) => {
          let user: any = res.data;
          dispatch(updateUser({ data: user }));
          dispatch(signInR({ data: user }));
          dispatch(setUID({ data: user._id }));
          dispatch(setEmail({ data: user.email }));
          // const analytics = getAnalytics();
          // setUserId(analytics, user_data._id);
          if (user.isPro) {
            dispatch(setAuthenticated({}));
            dispatch(setAuthenticatedPro({}));
            await api_call_v2(
              ctx,
              "get",
              "getAgenceUser",
              {
                user_id: user._id,
                isPromoteur: user.isPromoteur ? user.isPromoteur : false,
              },
              "cloud_functions",
              true
            ).then(async (result) => {
              dispatch(setAgence({ data: result.data }));
              dispatch(setAgenceCopy({ data: result.data }));
            });

            if (user.isPromoteur) {
              dispatch(setAuthenticatedPromoteur({}));
              resolve(user);
            } else {
              resolve(user);
            }
          } else {
            resolve(user);
          }
        })
        .catch((err) => {
          resolve(null);
        });
    } else {
      resolve(null);
    }
  });
};
