import React from "react";

function index() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2511 19.399C15.4715 19.399 19.7011 15.1694 19.7011 9.94902C19.7011 4.72869 15.4715 0.499023 10.2511 0.499023C5.03081 0.499023 0.801147 4.72869 0.801147 9.94902C0.801147 15.1694 5.03081 19.399 10.2511 19.399ZM16.4511 14.3653C15.5211 13.1683 14.0847 12.3879 12.4459 12.3879C12.0577 12.3879 11.4552 12.7533 10.2511 12.7533C9.051 12.7533 8.44462 12.3879 8.05639 12.3879C6.42154 12.3879 4.98513 13.1691 4.05116 14.3653C3.12686 13.0783 2.62994 11.5336 2.63051 9.94902C2.63051 5.74614 6.04826 2.32839 10.2511 2.32839C14.454 2.32839 17.8718 5.74614 17.8718 9.94902C17.8718 11.5949 17.3426 13.1195 16.4511 14.3653ZM10.2511 11.4728C11.2081 11.453 12.1193 11.0589 12.789 10.375C13.4588 9.6912 13.834 8.77211 13.834 7.8149C13.834 6.85769 13.4588 5.9386 12.789 5.25477C12.1193 4.57093 11.2081 4.17682 10.2511 4.15696C9.29414 4.17682 8.38304 4.57093 7.71325 5.25477C7.04345 5.9386 6.66833 6.85769 6.66833 7.8149C6.66833 8.77211 7.04345 9.6912 7.71325 10.375C8.38304 11.0589 9.29414 11.453 10.2511 11.4728ZM10.2511 9.64426C9.76597 9.64416 9.30071 9.45132 8.95771 9.10817C8.61471 8.76503 8.42207 8.29968 8.42218 7.8145C8.42228 7.32933 8.61512 6.86406 8.95827 6.52107C9.30141 6.17807 9.76676 5.98543 10.2519 5.98554C10.4922 5.98559 10.73 6.03296 10.952 6.12494C11.1739 6.21692 11.3755 6.35171 11.5454 6.52162C11.7152 6.69153 11.8499 6.89323 11.9418 7.1152C12.0337 7.33716 12.081 7.57506 12.0809 7.81529C12.0809 8.05553 12.0335 8.2934 11.9415 8.51533C11.8495 8.73726 11.7147 8.9389 11.5448 9.10873C11.3749 9.27857 11.1732 9.41327 10.9512 9.50516C10.7293 9.59704 10.4914 9.64431 10.2511 9.64426ZM10.2511 17.5697C8.43082 17.5703 6.67109 16.9156 5.29383 15.7253C5.58131 15.2788 5.97282 14.9087 6.43478 14.6467C6.89674 14.3847 7.41535 14.2387 7.94613 14.2212C8.73836 14.4645 9.49278 14.5866 10.2511 14.5866C11.0095 14.5866 11.7639 14.4685 12.5562 14.2204C13.0869 14.2387 13.6052 14.3852 14.0671 14.6472C14.5289 14.9093 14.9205 15.2792 15.2085 15.7253C13.8312 16.9156 12.0715 17.5703 10.2511 17.5697Z"
        fill="#051011"
      />
    </svg>
  );
}

export default index;
