import React from 'react'

function Instagram() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.8002 0C7.19859 0 0.200195 6.9984 0.200195 15.6C0.200195 24.2016 7.19859 31.2 15.8002 31.2C24.4018 31.2 31.4002 24.2016 31.4002 15.6C31.4002 6.9984 24.4018 0 15.8002 0ZM11.7994 4.8H19.7986C23.5486 4.8 26.6002 7.85042 26.6002 11.5992V19.5984C26.6002 23.3484 23.5498 26.4 19.801 26.4H11.8018C8.05176 26.4 5.0002 23.3496 5.0002 19.6008V11.6016C5.0002 7.85156 8.05061 4.8 11.7994 4.8ZM11.7994 7.2C9.37421 7.2 7.4002 9.17516 7.4002 11.6016V19.6008C7.4002 22.026 9.37536 24 11.8018 24H19.801C22.2262 24 24.2002 22.0248 24.2002 19.5984V11.5992C24.2002 9.17402 22.225 7.2 19.7986 7.2H11.7994ZM21.4018 9.19922C21.8434 9.19922 22.201 9.55684 22.201 9.99844C22.201 10.44 21.8434 10.8 21.4018 10.8C20.9602 10.8 20.6002 10.44 20.6002 9.99844C20.6002 9.55684 20.9602 9.19922 21.4018 9.19922ZM15.8002 9.6C19.1086 9.6 21.8002 12.2916 21.8002 15.6C21.8002 18.9084 19.1086 21.6 15.8002 21.6C12.4918 21.6 9.80019 18.9084 9.80019 15.6C9.80019 12.2916 12.4918 9.6 15.8002 9.6ZM15.8002 12C14.8454 12 13.9297 12.3793 13.2546 13.0544C12.5795 13.7295 12.2002 14.6452 12.2002 15.6C12.2002 16.5548 12.5795 17.4705 13.2546 18.1456C13.9297 18.8207 14.8454 19.2 15.8002 19.2C16.755 19.2 17.6706 18.8207 18.3458 18.1456C19.0209 17.4705 19.4002 16.5548 19.4002 15.6C19.4002 14.6452 19.0209 13.7295 18.3458 13.0544C17.6706 12.3793 16.755 12 15.8002 12Z" fill="#757E83" />
        </svg>
    )
}

export default Instagram
