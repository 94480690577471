import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const initialState = {
  version: "estimation",
  date: new Date().toISOString(),
  activeStep: 1,
  type: "Appartement",
  geoLocInProgress: false,
  geocoding: {
    lat: 33.556203798815005,
    lng: -7.65478641566938,
    pa_id: "",
    quartier: "",
    commune: "",
    province: "",
    region: "",
    zoom: 2,
    marker: false,
    adresse: "",
  },
  informations: {
    surfaceTerrain: 0,
    surfaceConstruite: 0,
    nombreDechambres: 1,
    nombreSdb: 1,
    surfaceInterHabitable: 0,
    EtageDeBien: 1,
    ageDeBien: "",
    etatDeBien: "Correct",
    qualityDeMaterial: "Normal, milieu de gamme",
    luminoseDeBien: "Ensoleillé",
  },
  caracteristiques: {
    murMitoyen: {
      checked: false,
      typeDeVilla: null,
    },
    jardin: false,
    garage: false,
    chambreDeService: false,
    piscine: false,
    ChauffeEauSolaire: false,
    Residence: false,
    visAvis: false,
    Ascenseur: false,
    Balcon: false,
    Terrasse: false,
    BoxEnSousSol: false,
    SurfaceBalcon: 0,
    SurfaceTerrasse: 0,
    SurfaceBoxEnSousSol: 0,
    PlaceDeParking: false,
    NombrePlacesDeParking: 1,
    ChambreDeService: false,
    ResidenceSecurisee: false,
  },
  bienEtVous: {
    particulier: true,
    professionnel: false,
    estimeRaison: "",
    vendrevotreBien: "",
    phone: "",
    professionnelActivite: "",
    recherche: "",
    interessant: "",
    emprunter: false,
    proprietaire: true,
    contacterParProfs: false,
  },
  estimationShareId: null,
  estimationId: null,
  errorId: null,
  shareId: null,
  updateId: null,
  estimation_group_id: null,
  RESULT_VIEW_TYPE: "ESTIMATION",
  results: {
    estimation: {
      vente: null,
      location: null,
    },
    incertitude_prix: null,
    indice_de_confiance: null,
  },
  user: {},
  incompleteSignup: false,
  agences: [],
  ventes: [],
  annonces: [],
  graphe: {
    data: [],
    categories: [],
  },
};

const slice = createSlice({
  name: "EstimationV2",
  initialState,
  reducers: {
    setInCompleteSignup(state, action) {
      return {
        ...state,
        incompleteSignup: action.payload,
      };
    },
    setGraphes(state, action) {
      return {
        ...state,
        graphe: action.payload,
      };
    },
    setErrorId(state, action) {
      return {
        ...state,
        errorId: action.payload,
      };
    },
    setEstimationId(state, action) {
      return {
        ...state,
        estimationId: action.payload,
      };
    },
    setUpdateId: (state, action) => {
      state = { ...state, updateId: action.payload };
      return state;
    },
    setEstimationGroupId: (state, action) => {
      state = { ...state, estimation_group_id: action.payload };
      return state;
    },
    setShareId(state, action) {
      return {
        ...state,
        shareId: action.payload,
      };
    },
    setResults(state, action) {
      return {
        ...state,
        results: action.payload.results,
        estimationId: action.payload.estimationId,
        shareId: action.payload.shareId
          ? action.payload.shareId
          : state.shareId,
      };
    },
    setAgences(state, action) {
      return {
        ...state,
        agences: action.payload,
      };
    },
    setVentes(state, action) {
      return {
        ...state,
        ventes: action.payload,
      };
    },
    setAnnonces(state, action) {
      return {
        ...state,
        annonces: action.payload,
      };
    },
    setInitialState(state, action) {
      return {
        ...state,
        date: action.payload.date ? action.payload.date : state.date,
        type: action.payload.type,
        geocoding: { ...state.geocoding, ...action.payload.geocoding },
        informations: { ...state.informations, ...action.payload.informations },
        caracteristiques: {
          ...state.caracteristiques,
          ...action.payload.caracteristiques,
        },
        bienEtVous: { ...state.bienEtVous, ...action.payload.bienEtVous },
        results: { ...state.results, ...action.payload.results },
        user: { ...state.user, ...action.payload.user },
        estimationShareId: action.payload.estimationShareId
          ? action.payload.estimationShareId
          : null,
        estimationId: action.payload.estimationId
          ? action.payload.estimationId
          : null,
        shareId: action.payload.shareId ? action.payload.shareId : null,
      };
    },
    setResultViewType(state, action) {
      return {
        ...state,
        RESULT_VIEW_TYPE: action.payload,
      };
    },
    setGeolocInProgress(state, action) {
      return {
        ...state,
        geoLocInProgress: action.payload,
      };
    },
    setActiveStep(state, action) {
      return {
        ...state,
        activeStep: action.payload,
      };
    },
    setSelectedType(state, action) {
      return {
        ...state,
        type: action.payload,
      };
    },
    setGeocoding(state, action) {
      return {
        ...state,
        version: action.payload.version
          ? action.payload.version
          : state.version,
        geocoding: {
          lat: action.payload.lat ? action.payload.lat : state.geocoding.lat,
          lng: action.payload.lng ? action.payload.lng : state.geocoding.lng,
          pa_id: action.payload.pa_id
            ? action.payload.pa_id
            : state.geocoding.pa_id,
          quartier: action.payload.quartier
            ? action.payload.quartier
            : state.geocoding.quartier,
          commune: action.payload.commune
            ? action.payload.commune
            : state.geocoding.commune,
          province: action.payload.province
            ? action.payload.province
            : state.geocoding.province,
          region: action.payload.region
            ? action.payload.region
            : state.geocoding.region,
          zoom: action.payload.zoom
            ? action.payload.zoom
            : state.geocoding.zoom,
          marker: action.payload.marker
            ? action.payload.marker
            : state.geocoding.marker,
          adresse: action.payload.adresse
            ? action.payload.adresse
            : state.geocoding.adresse !== ""
            ? ""
            : state.geocoding.adresse,
        },
      };
    },
    setInformations(state, action) {
      return {
        ...state,
        informations: action.payload,
      };
    },
    setCaracteristiques(state, action) {
      return {
        ...state,
        caracteristiques: { ...state.caracteristiques, ...action.payload },
      };
    },
    setBienEtVous(state, action) {
      return {
        ...state,
        bienEtVous: action.payload,
      };
    },

    setInitialHydration(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

const selectEstimationV2Domain = (state: any) =>
  state.EstimationV2 || initialState;

const activeStepSelector = createSelector(
  [selectEstimationV2Domain],
  (substate) => substate.activeStep
);
const grapheSelector = createSelector(
  [selectEstimationV2Domain],
  (substate) => substate.graphe
);

const resultSelector = createSelector(
  [selectEstimationV2Domain],
  (substate) => substate.results
);

const reducerSelector = createSelector(
  [selectEstimationV2Domain],
  (substate) => substate
);
const estimationIdSelector = createSelector(
  [selectEstimationV2Domain],
  (substate) => substate.estimationId
);
const shareIdSelector = createSelector(
  [selectEstimationV2Domain],
  (substate) => substate.shareId
);
const updateIdSelector = createSelector(
  [selectEstimationV2Domain],
  (substate) => substate.updateId
);
const selectedTypeSelector = createSelector(
  [selectEstimationV2Domain],
  (substate) => substate.type
);

const informationsDeBien = createSelector(
  [selectEstimationV2Domain],
  (substate) => substate.informations
);

const caracteristiqueDeBien = createSelector(
  [selectEstimationV2Domain],
  (substate) => substate.caracteristiques
);

const estimationGeocodingSelector = createSelector(
  [selectEstimationV2Domain],
  (substate) => substate.geocoding
);

const versionSelector = createSelector(
  [selectEstimationV2Domain],
  (substate) => substate.version
);

const bienEtVousSelector = createSelector(
  [selectEstimationV2Domain],
  (substate) => substate.bienEtVous
);
const geoLocInProgressSelector = createSelector(
  [selectEstimationV2Domain],
  (substate) => substate.geoLocInProgress
);

const agencesSelector = createSelector(
  [selectEstimationV2Domain],
  (substate) => substate.agences
);

const ventesSelector = createSelector(
  [selectEstimationV2Domain],
  (substate) => substate.ventes
);

const annoncesSelector = createSelector(
  [selectEstimationV2Domain],
  (substate) => substate.annonces
);
const incompleteSignupSelector = createSelector(
  [selectEstimationV2Domain],
  (substate) => substate.incompleteSignup
);

export const selectors = {
  versionSelector,
  incompleteSignupSelector,
  estimationGeocodingSelector,
  activeStepSelector,
  selectedTypeSelector,
  informationsDeBien,
  caracteristiqueDeBien,
  bienEtVousSelector,
  geoLocInProgressSelector,
  resultSelector,
  reducerSelector,
  estimationIdSelector,
  shareIdSelector,
  updateIdSelector,
  agencesSelector,
  annoncesSelector,
  ventesSelector,
  grapheSelector,
};

export const actions = {
  ...slice.actions,
};

export default slice.reducer;
