import React, { useEffect, useRef, useState } from "react";
import formatUrl from "utils/FormatUrl";
import style from "./style.module.css";

const modallinks = [
  {
    title: "Accéder à mon espace pro",
    linkTo: "/espace-pro",
    color: "rgb(70, 10, 240)",
  },
  {
    title: "Annonces aimées",
    linkTo: "/user/saved",
    color: "#000",
  },
  {
    title: "Mes recherches sauvegardées",
    linkTo: "/user/recherches",
    color: "#000",
  },
  {
    title: "Mes estimations",
    linkTo: "/user/estimates",
    color: "#000",
  },
  {
    title: "Publier une annonce",
    // linkTo: "/user/annonces/publier",
    color: "#000",
    dropDown: [{
      subtitle: "Publier une annonce",
      subtitlelink:"/user/annonces/publier"
    }, {
      subtitle: "Annonces publiées",
      subtitlelink:"/user/annonces"
    }],
  },
  {
    title: "Inbox",
    linkTo: "/user/inbox",
    color: "#000",
  },
  {
    title: "Paramètres",
    linkTo: "user/settings",
    color: "#000",
  },
];

export default function index({ onLogout, setShowDropDown, t, user, lang }) {
  const [showAnnonce, setshowAnnonce] = useState(false)
  return (
    <div className={style.modalContainer}>
      <ul className={style.linksListContainer}>
        {modallinks.map((item, key) => {
          if (item.title === "Accéder à mon espace pro" && !user?.isPro)
            return null;
          else
            return (
              <>
                {!item.dropDown ? (
                  <li key={key}>
                    <a
                      href={`${formatUrl(item.linkTo, lang)}`}
                      style={{ color: item.color }}
                    >
                      {t(item.title)}
                    </a>
                  </li>
                ) : (
                  <>
                    <li
                      key={key}
                    >
                      <span
                      onClick={(e)=>{
                        e.preventDefault();
                        setshowAnnonce(!showAnnonce)
                      }}
                        style={{ color: item.color }}
                      >
                        {t(item.title)}
                        <span className={showAnnonce ? style.svg_down : style.svg_up}>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="Icon/Outline/chevron-right">
                                <path
                                  id="Icon"
                                  d="M19 9L12 16L5 9"
                                  stroke="#000"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                            </svg>
                        </span>
                      </span>
                    {showAnnonce ? (
                      <div className={style.dropDown}>
                        {item.dropDown.map((dr, idx) => {
                          return (
                            <>
                                <a href={`${formatUrl(dr.subtitlelink, lang)}`}>
                                  {t(dr.subtitle)}
                                </a>
                            </>
                          )
                        })}
                      </div>
                    ):(null)}
                    </li>

                  </>
                )}
              </>
            );
        })}
        <li
          className={style.logoutButton}
          onClick={() => {
            onLogout();
            setShowDropDown(false);
          }}
          style={{flexDirection: "row"}}
        >

          <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.68294 12.667L5.01628 9.00033M5.01628 9.00033L8.68294 5.33366M5.01628 9.00033L17.8496 9.00032M13.2663 12.667V13.5837C13.2663 15.1024 12.0351 16.3337 10.5163 16.3337H4.09961C2.58083 16.3337 1.34961 15.1024 1.34961 13.5837V4.41699C1.34961 2.89821 2.58083 1.66699 4.09961 1.66699H10.5163C12.0351 1.66699 13.2663 2.89821 13.2663 4.41699V5.33366" stroke="#FF376E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          {t("Déconnexion")}
        </li>
      </ul>
    </div>
  );
}
