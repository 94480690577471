import styles from "./Banner.module.css";
import useTranslation from "next-translate/useTranslation";
import formatUrl from "utils/FormatUrl";
import bg from "./prixImmobg.png";

const Banner = ({
  title,
  buttonText,
  onButtonClick,
  buttonHref,
}: {
  title: string;
  buttonText: string;
  onButtonClick?: any;
  buttonHref?: string;
}) => {
  const { t, lang } = useTranslation("common");
  return (
    <div
      className={styles.banner_inner_container}
      style={{
        backgroundImage: `url(${bg.src})`,
      }}
    >
      <p className={styles.banner__title}>{t(`${title}`)}</p>
      <div className={styles.button}>
        {buttonHref ? (
          <a
            rel={
              buttonHref.includes("list") ||
              buttonHref.includes("map") ||
              buttonHref.includes("?") ||
              buttonHref.includes("conditions") ||
              buttonHref.includes("contact")
                ? "nofollow noopener noreferrer noindex"
                : "follow"
            }
            href={formatUrl(buttonHref, lang)}
            target="__blank"
            className={styles.btnFillblue}
          >
            {t(`${buttonText}`)}
          </a>
        ) : (
          <button className={styles.btnFillblue}>{t(`${buttonText}`)}</button>
        )}
      </div>
    </div>
  );
};
export default Banner;
