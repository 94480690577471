import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import nookies from "nookies";

import { createSelector } from "reselect";

export const initialState = {
  userSpaceRoute: "USER_SPACE_ESTIMATION",
  user: null,
  loadedSite: false,
  authenticated: false,
  authenticatedPro: false,
  authenticatedPromoteur: false,
  uid: "",
  agence: {
    nameEntreprie: null,
    lat: 33.57818919660718,
    lng: -7.634373469681108,
  },
  agenceCopy: {
    nameEntreprie: null,
    lat: 33.57818919660718,
    lng: -7.634373469681108,
  },
  pointLocationAgenceExist: false,
  email: "",
  emailInscription: "",
  firstNameInscription: "",
  lastNameInscription: "",
  phoneInscription: "",
  FBIdToken: "",
  RT: "",
};

export const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserSpaceRoute: (state, action) => {
      return { ...state, userSpaceRoute: action.payload.data };
    },
    mainLoader: (state, action) => {
      return { ...state, loadedSite: action.payload.data };
    },

    updateUser: (state, action) => {
      return { ...state, user: action.payload.data };
    },

    signInR: (state, action) => {
      return { ...state, authenticated: true };
    },

    signInUp: (state, action) => {
      return { ...state, authenticated: true };
    },

    setAuthenticated: (state, action) => {
      return {
        ...state,
        authenticated: action.payload == false ? false : true,
      };
    },

    setAuthenticatedPro: (state, action) => {
      return {
        ...state,
        authenticatedPro: action.payload == false ? false : true,
      };
    },

    setAuthenticatedPromoteur: (state, action) => {
      return {
        ...state,
        authenticatedPromoteur: action.payload == false ? false : true,
      };
    },

    setUID: (state, action) => {
      return { ...state, uid: action.payload.data };
    },

    setEmail: (state, action) => {
      return { ...state, email: action.payload.data };
    },

    setUser: (state, action) => {
      return {
        ...state,
        user: action.payload
          ? action.payload.data
            ? action.payload.data
            : action.payload
          : null,
      };
    },

    setAgence: (state, action) => {
      return { ...state, agence: { ...state.agence, ...action.payload.data } };
    },

    setAgenceCopy: (state, action) => {
      return {
        ...state,
        agenceCopy: { ...state.agenceCopy, ...action.payload.data },
      };
    },

    signOutR: (state, action) => {
      // document.location.href = "/";
      nookies.destroy(undefined, "AccessToken");
      nookies.destroy(undefined, "RefreshToken");
      localStorage.removeItem("FBIdToken");
      localStorage.removeItem("RT");
      // delete axios.defaults.headers.common["authorization"];
      // delete axios.defaults.headers.common["Authorization"];
      return {
        ...state,
        authenticated: false,
        authenticatedPro: false,
        authenticatedPromoteur: false,
      };
    },

    phoneNumberError: (state, action) => {
      return { ...state, phoneError: true };
    },

    updateAgenceLocationPointexist: (state, action) => {
      return { ...state, pointLocationAgenceExist: action.payload.data };
    },

    setMarkerAgenceLocation: (state, action) => {
      return {
        ...state,
        agence: {
          ...state.agence,
          lat: action.payload.data.latitude,
          lng: action.payload.data.longitude,
        } /*zoom: 13*/,
      };
    },
  },
});

const selectAuthDomain = (state) => state.auth || initialState;

const userSpaceRouteSelector = createSelector(
  [selectAuthDomain],
  (substate) => substate.userSpaceRoute
);

const FBIdTokenSelector = createSelector(
  [selectAuthDomain],
  (substate) => substate.FBIdToken
);
const userSelector = createSelector(
  [selectAuthDomain],
  (substate) => substate.user
);

const RTSelector = createSelector(
  [selectAuthDomain],
  (substate) => substate.RT
);

const AgenceSelector = createSelector(
  [selectAuthDomain],
  (substate) => substate.agence
);
const AgenceCopySelector = createSelector(
  [selectAuthDomain],
  (substate) => substate.agenceCopy
);

export const selectors = {
  selectAuthDomain,
  FBIdTokenSelector,
  RTSelector,
  userSelector,
  AgenceSelector,
  AgenceCopySelector,
  userSpaceRouteSelector,
};

export const {
  mainLoader,
  updateUser,
  signInR,
  signInUp,
  signInErr,
  signUp,
  setAuthenticated,
  setAuthenticatedPro,
  setAuthenticatedPromoteur,
  setUID,
  setEmail,
  setUser,
  setAgence,
  setAgenceCopy,
  signOutR,
  signUpErr,
  requestSended,
  requestErr,
  passReset,
  phoneNumberError,
  updateAgenceLocationPointexist,
  setMarkerAgenceLocation,
  setUserSpaceRoute,
} = slice.actions;

export default slice.reducer;
