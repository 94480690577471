import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const initialState = {
    page:[],
  
};

const slice = createSlice({
  name: "UrlTrack",
  initialState,
  reducers: {
    setPage(state, action){
        return{
            ...state,
            page: action.payload,
        }
    },
   
  },
});

const UrlTrack = (state: any) =>
  state.UrlTrack || initialState;

const pageUrlsSelector = createSelector(
  [UrlTrack],
  (substate) => substate.page
);

export const selectors = {
  UrlTrack,
  pageUrlsSelector,
};

export const actions = {
  ...slice.actions,
};
export default slice.reducer;
