import UrlToprovince from "assets/citiesUrlsMappings/url-provinces.json";
import UrlToProvinceToRegion from "assets/citiesUrlsMappings/province-to-regions.json";
import UrlToregion from "assets/citiesUrlsMappings/url-region.json";
import RegionToProvince from "assets/citiesUrlsMappings/regions-to-provinces.json";

import Provinces from "assets/provinces_region_centre.json";
import HeadSeo from "components/HeadSeo/HeadSeo";
import PROXY_CONFIG from "Config/ProxyConfig";
import AnnuaireContainer from "containers/v2/AnnuaireContainer";
import siteMetadata from "data/siteMetadata";
import useTranslation from "next-translate/useTranslation";
import { useEffect, useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actions as AnnuairereducerActions,
  selectors as AnnuairereducerSelectors,
} from "redux/slices/v2/Annuaire";
import api_call_v2 from "utils/API_CALL/api_call_v2";
import { checkUser } from "utils/BackendHelper";
import { decrypt } from "utils/CRYPTO/secret";
import formatUrl from "utils/FormatUrl";
import { norm } from "components/v2/Listings/ListingsRouterUrlHelper";
export const DEFAULT_SEARCH_LIMIT = 12;
const buildUrl = (searchPayload, lang = "fr") => {
  let region = "";
  let province = "";
  if (searchPayload.geometry_label == "region") {
    region = searchPayload.geometry_value;
  }
  if (searchPayload.geometry_label == "province") {
    province = UrlToProvinceToRegion[searchPayload.geometry_value]
      ? UrlToProvinceToRegion[searchPayload.geometry_value].province
      : searchPayload.geometry_value;
    region =
      Provinces.filter((item) => {
        return item.province == province;
      })[0]?.region || "";
  }
  let url = `/agence-immobiliere-maroc${
    province ? `/immo-${norm(province)}` : ""
  }${
    searchPayload.page ||
    searchPayload.type?.length ||
    searchPayload.specialite?.length ||
    searchPayload.address
      ? `/recherche?page=${searchPayload.page}`
      : ""
  }`;
  return formatUrl(url, lang);
};
export default function AnnuairePagesHepler(props) {
  function addProductJsonLd(props) {
    return {
      __html: `
      {
        "@context": "https://schema.org/", 
        "@type": "BreadcrumbList", 
        "itemListElement": [
          {
          "@type": "ListItem", 
          "position": 1, 
          "name": "Annuaire des agences immobilières au maroc",
          "item": "https://agenz.ma/agence-immobiliere-maroc"  
        }${
          props.searchPayload.gvalue
            ? `,
        {
          "@type": "ListItem", 
          "position": 2, 
          "name": "${props.searchPayload.gvalue}",
          "item": "https://agenz.ma/agence-immobiliere-maroc/recherche"  
        }`
            : ""
        }
        
      ]
      }  
  `,
    };
  }
  const dispatch = useDispatch();
  const firstUpdateSP = useRef(true);
  const searchPayload: any = useSelector(
    AnnuairereducerSelectors.searchPayloadSelector
  );
  const { lang } = useTranslation("");
  useEffect(() => {
    let url = buildUrl(searchPayload, lang);
  }, [searchPayload]);
  useEffect(() => {
    if (firstUpdateSP.current) {
      firstUpdateSP.current = false;
      return;
    } else {
      dispatch(
        AnnuairereducerActions.setLoading({
          data: true,
        })
      );
      let url = buildUrl(searchPayload, lang);

      window.history.pushState({}, null, url);

      api_call_v2(undefined, "post", "annuaire/recherche", {
        sort_by: searchPayload.sort_by,
        geometry_label:
          searchPayload.geometry_label == "province"
            ? "region"
            : searchPayload.geometry_label,
        geometry_value:
          searchPayload.geometry_label == "province"
            ? UrlToProvinceToRegion[norm(searchPayload.geometry_value)]?.region
            : searchPayload.geometry_value,
        type: searchPayload.type,
        limit: searchPayload.limit,
        page: searchPayload.page,
      })
        .then((results) => {
          let data = decrypt(results.data);
          dispatch(
            AnnuairereducerActions.setAgences({
              agences: data.results,
              totalItems: data.totalItems,
            })
          );
          dispatch(
            AnnuairereducerActions.setLoading({
              data: false,
            })
          );
        })
        .catch((err) => {
          dispatch(
            AnnuairereducerActions.setAgences({
              agences: [],
              totalItems: 0,
            })
          );
          dispatch(
            AnnuairereducerActions.setLoading({
              data: false,
            })
          );
        });
    }
  }, [searchPayload]);

  return (
    <>
      <HeadSeo
        title={
          props.agences.totalItems
            ? `${`${props.agences.totalItems} ${props.t(
                "agences immobilières"
              )} `}${
                props.searchPayload.geometry_value
                  ? `${props.t("à")} ${props.t(
                      props.searchPayload.gvalue_todisplay
                        ? props.searchPayload.gvalue_todisplay
                        : props.searchPayload.geometry_value
                    )}`
                  : props.t("au Maroc")
              } ${props.t("sont sur agenz")}`
            : props.t(
                "Recherchez une agence immobilière adaptée à votre projet"
              )
        }
        description={`${props.t(
          "Recherchez une agence immobilière adaptée à votre projet"
        )} ${
          props.searchPayload.geometry_value
            ? `${props.t("à")} ${props.t(
                props.searchPayload.gvalue_todisplay
                  ? props.searchPayload.gvalue_todisplay
                  : props.searchPayload.geometry_value
              )} `
            : ""
        }${props.t("parmis plus de 500 agences sur agenz")}`}
        ogImageUrl={siteMetadata.siteLogoSquare}
        ogTwitterImage={siteMetadata.siteLogoSquare}
        ogType={"website"}
        ogTitle={
          props.agences.totalItems
            ? `${`${props.agences.totalItems} ${props.t(
                "agences immobilières"
              )} `}${
                props.searchPayload.geometry_value
                  ? `${props.t("à")} ${props.t(
                      props.searchPayload.gvalue_todisplay
                        ? props.searchPayload.gvalue_todisplay
                        : props.searchPayload.geometry_value
                    )}`
                  : props.t("au Maroc")
              } ${props.t("sont sur agenz")}`
            : props.t(
                "Recherchez une agence immobilière adaptée à votre projet"
              )
        }
        ogDescription={`${props.t(
          "Recherchez une agence immobilière adaptée à votre projet"
        )} ${
          props.searchPayload.geometry_value
            ? `${props.t("à")} ${props.t(
                props.searchPayload.gvalue_todisplay
                  ? props.searchPayload.gvalue_todisplay
                  : props.searchPayload.geometry_value
              )} `
            : ""
        }${props.t("parmis plus de 500 agences sur agenz")}`}
        canonicalUrl={`https://agenz.ma${buildUrl(
          {
            ...props.searchPayload,
            geometry_label: props.province
              ? "province"
              : props.searchPayload.geometry_label,
            geometry_value: props.province
              ? props.province
              : props.searchPayload.geometry_value,
          },
          lang
        )}`}
        structuredData={null}
      >
        <></>
      </HeadSeo>
      <AnnuaireContainer t={props.t} lang={lang} />
    </>
  );
}

export const getServerSidePropsHelper = async (
  store: any,
  query: any,
  ctx?: any
) => {
  let user: any = checkUser(ctx, store, null);
  let glabel = "";
  let gvalue = "";
  let glabel_todisplay = "province";
  let gvalue_todisplay = "";
  if (query.province) {
    glabel = "region";
    gvalue_todisplay = UrlToProvinceToRegion[query.province]?.province;
    gvalue = UrlToProvinceToRegion[query.province]?.region;
  } else if (query.region) {
    glabel_todisplay = "region";
    glabel = "region";
    gvalue_todisplay = UrlToregion[query.region]?.name;
    gvalue = UrlToregion[query.region]?.name;
  } else {
    if (query.glabel) {
      glabel_todisplay = query.glabel;
      glabel = query.glabel;
    }
    if (query.gvalue) {
      gvalue_todisplay = query.gvalue;
      gvalue = query.gvalue;
    }
  }
  let adresse = query.adresse || "";
  let page = parseInt(query.page?.toString()) || 0;

  let sort_by = query.sort_by || "annonces";
  let searchPayload = {
    sort_by: sort_by,
    geometry_label: glabel,
    geometry_value: gvalue,
    gvalue_todisplay: gvalue_todisplay,
    type: [],
    limit: DEFAULT_SEARCH_LIMIT,
    page: page,
  };
  let agences = {
    totalItems: 0,
  };

  try {
    let results = await api_call_v2(undefined, "post", "annuaire/recherche", {
      sort_by: sort_by,
      geometry_label: glabel,
      geometry_value: gvalue,
      type: [],
      limit: DEFAULT_SEARCH_LIMIT,
      page: page,
    });
    let data = decrypt(results.data);

    store.dispatch(
      AnnuairereducerActions.setAgencesAndPayload({
        agences: data.results,
        totalItems: data.totalItems,
        sort_by: sort_by,
        geometry_label: glabel_todisplay,
        geometry_value: gvalue_todisplay,
        type: [],
        limit: DEFAULT_SEARCH_LIMIT,
        page: page,
      })
    );
    agences.totalItems = data.totalItems;
  } catch (e) {
    store.dispatch(
      AnnuairereducerActions.setAgencesAndPayload({
        agences: [],
        totalItems: 0,
        sort_by: sort_by,
        geometry_label: glabel,
        geometry_value: gvalue,
        gvalue_todisplay: gvalue_todisplay,
        type: [],
        limit: DEFAULT_SEARCH_LIMIT,
        page: page,
      })
    );
  }
  return {
    searchPayload: searchPayload,
    agences: agences,
  };
};
