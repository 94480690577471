import { useEffect, Suspense, useState } from "react";
import styles from "./Annuaire.module.scss";
import style from "./style.module.css";

import { selectors as AnnuairereducerSelectors } from "redux/slices/v2/Annuaire";
import { useSelector } from "react-redux";
import Pagination from "./Pagination";
import dynamic from "next/dynamic";
import { actions as AnnuairereducerActions } from "redux/slices/v2/Annuaire";
import { useDispatch } from "react-redux";
import useTranslation from "next-translate/useTranslation";
import formatUrl from "utils/FormatUrl";
import { useRouter } from "next/router";
import Carousel from "components/v3/common/cardSlider/Carousel";

const AgenceSqueleton = dynamic(() => import("./AgenceSqueleton"), {
  suspense: true,
  ssr: false,
});

const AgenceCard = dynamic(() => import("./AgenceCard"), {
  suspense: true,
  ssr: true,
});

const AgenceCardV2 = dynamic(() => import("./AgenceCardV2"), {
  suspense: true,
  ssr: true,
});

const Empty = dynamic(() => import("./Empty"), {
  suspense: true,
  ssr: false,
});
const AgencesSection = (props) => {
  const { t, lang } = useTranslation("agencesSection");
  let loading: any = useSelector(AnnuairereducerSelectors.loadingSelector);
  // let loading = true;
  let totalPages: any = useSelector(
    AnnuairereducerSelectors.totalPagesSelector
  );
  let searchPayload: any = useSelector(
    AnnuairereducerSelectors.searchPayloadSelector
  );
  const agencesSelected: { agences: []; totalItems: number } = useSelector(
    AnnuairereducerSelectors.agencesSelector
  );
  const agences = props.agences ? { agences: props.agences } : agencesSelected;

  const dispatch = useDispatch();

  const onPageChange = (page) => {
    dispatch(
      AnnuairereducerActions.setSearchPayload({
        data: {
          page: page,
        },
      })
    );
    if (window) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const { asPath } = useRouter();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 990) {
        setIsMobile(true);
      } else setIsMobile(false);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (window.innerWidth <= 990) {
      setIsMobile(true);
    } else setIsMobile(false);
  }, []);
  return (
    <>
      {agences && agences.agences ? (
        agences.agences.length ? (
          <section className={styles.section} style={{ position: "relative" }}>
            {asPath.includes("/estimation")
              ? null
              : asPath.includes("/prix-immobilier-maroc")
              ? null
              : // agences.agences.length && (
                //   <Carousel
                //     isLp={true}
                //     isMobile={isMobile}
                //     t={t}
                //     similarLen={
                //       agences.agences.length > 0 ? agences.agences.length : 0
                //     }
                //     title={t("Agences recommandées")}
                //   >
                //     {agences.agences.map((item, id) => {
                //       return (
                //         <div key={id}>
                //           <AgenceCardV2 agent={item} />
                //         </div>
                //       );
                //     })}
                //   </Carousel>
                // )
                agences.agences.length && (
                  <div className={style.containerCards}>
                    {agences.agences.map((item, key) => {
                      return (
                        <Suspense fallback={<></>}>
                          {" "}
                          <AgenceCardV2 agent={item} />
                        </Suspense>
                      );
                    })}
                  </div>
                )}
            {props.displayPagination ? (
              <div className={styles.pagination__container}>
                {/* <h1>Pagination</h1> */}
                <Pagination
                  activePage={searchPayload.page}
                  numberPages={totalPages}
                  onPageChange={onPageChange}
                  pagesToDisplay={12}
                />
              </div>
            ) : props.alternateLink ? (
              <div className={styles.alternate_link__container}>
                <a
                  className={styles.alternate_link}
                  rel="dofollow"
                  href={formatUrl(props.alternateLink, lang)}
                >
                  {props.alternateLinkLabel}
                </a>
              </div>
            ) : null}
          </section>
        ) : props.displayEmptyPage ? (
          <Suspense fallback={<></>}>
            <Empty />
          </Suspense>
        ) : null
      ) : props.displayEmptyPage ? (
        <Suspense fallback={<></>}>
          <Empty />
        </Suspense>
      ) : null}
    </>
  );
};
export default AgencesSection;
