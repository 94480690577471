import { useState } from "react";
import useTranslation from "next-translate/useTranslation";
import classes from "./Annuaire.module.scss";
import CheckMark from "components/v2/Listings/SearchBarComponent/components/BienTypeSelect/CheckMark";
import Dropdown from "./DropDown";

type PossibleTypes = "Vente" | "Location" | "Select_All";

const allTypes = ["Vente", "Location", "Select_All"];
const BienTypesOptionsAchatLocation: {
  value: any;
}[] = [{ value: "Vente" }, { value: "Location" }];
const BienTypesProjetsNeuf: { value: any }[] = [
  { value: "Vente" },
  { value: "Location" },
];

type TypesList = ("Vente" | "Location")[];

export default function SpecialiteTypeSelect({
  transactionType,
  onTypesSelected,
  onTypesSelectionChange = () => {},
  variant = "SELECT",
}: {
  transactionType: "Vente" | "Location" | "Projets Neufs" | "";
  onTypesSelected: (types: any) => void;
  onTypesSelectionChange?: (types: any) => void;
  variant?: "SELECT" | "VIEW";
}) {
  const { t } = useTranslation("agencesSection");

  const BienTypesOptions = {
    Vente: BienTypesOptionsAchatLocation,
    Location: BienTypesOptionsAchatLocation,
    "Projets Neufs": BienTypesProjetsNeuf,
  }[transactionType];

  const [displayInputTypes, setDisplayInputTypes] = useState<boolean>(false);
  // const [allTypesSelected, setAllTypesSelected] = useState<boolean>(false);
  const [type, setType] = useState<PossibleTypes[] | string[]>([]);
  const [dropdownToggle, setDropdownToggle] = useState(false);

  const [initialisedFromRedux, setinitialisedFromRedux] =
    useState<boolean>(false);

  const onTypeSelectChange = (e, value) => {
    if (e) {
      if (type.concat(value).length == BienTypesOptions.length) {
        setType(type.concat(value).concat("Select_All"));
        onTypesSelectionChange(type.concat(value).concat("Select_All"));
      } else {
        setType(type.concat(value));
        onTypesSelectionChange(type.concat(value));
      }
    } else {
      setType(
        type.filter((item) => {
          return item != value && item != "Select_All";
        })
      );
      onTypesSelectionChange(
        type.filter((item) => {
          return item != value && item != "Select_All";
        })
      );
    }
  };
  const handleChangeSelectAll = (e) => {
    if (e) {
      setType(allTypes);
      onTypesSelectionChange(allTypes);
    } else {
      setType([]);
      onTypesSelectionChange([]);
    }
  };

  const contentComponent = (
    <div className={classes.containerDesktop}>
      <div className={classes.headDrop}>
        <p>{t("Tout")}</p>
      </div>
      <div className={classes.selectsContainer}>
        <div className={classes.selectAllBtnWrapper}>
          <label className={classes.selectAllBtn}>
            <span
              className={`${classes.checkbox} ${
                type.includes("Select_All")
                  ? classes.checkboxSlctAllActive
                  : classes.checkboxSlctAllInactive
              } `}
            >
              <CheckMark />
            </span>
            <input
              className={`${classes.checkboxInput} ${classes.hideCheckbox}`}
              type="checkbox"
              checked={type.includes("Select_All")}
              onChange={(e) => {
                handleChangeSelectAll(e.target.checked);
              }}
            />
            {type.includes("Select_All")
              ? t("Tout désélectionner")
              : t("Tout sélectionner")}
          </label>
        </div>

        {BienTypesOptions.map((bienType) => (
          <div className={classes.listItemWrapper} key={bienType.value}>
            <label className={classes.listItem}>
              <span
                className={`${classes.checkbox} ${
                  type.includes(bienType.value)
                    ? classes.checkboxSlctActive
                    : classes.checkboxSlctInactive
                }`}
              >
                <CheckMark />
              </span>
              <input
                className={`${classes.checkboxInput} ${classes.hideCheckbox}`}
                type="checkbox"
                checked={type.includes(bienType.value)}
                onChange={(e) => {
                  onTypeSelectChange(e.target.checked, bienType.value);
                }}
              />
              {t(bienType.value)}
            </label>
          </div>
        ))}
      </div>
      <div className={classes.validBtnWrapper}>
        <button
          className={classes.validBtn}
          onClick={() => {
            onTypesSelected(
              type.filter((t) => t !== "Select_All") as TypesList
            );
            setDisplayInputTypes(false);
            setDropdownToggle(false);
          }}
        >
          {t("Valider")}
        </button>
      </div>
    </div>
  );

  const valueComponent = (
    <div className={classes.value__container}>
      <span className={classes.label}>
        {type.length && type.length != allTypes.length
          ? type
              .filter((item) => {
                return item != "Select_All";
              })
              .join(", ")
          : t("Tout")}
      </span>
      <div className={classes.chevron__container}>
        <i className="fa-solid fa-chevron-down"></i>
      </div>
    </div>
  );

  return variant === "VIEW" ? (
    <div className={classes.container}>
      <span
        style={{
          paddingLeft: "20px",
          fontWeight: 600,
        }}
      >
        {t("Type")}
      </span>
      {/* <Grid
        container
        spacing={0}
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 12,
        }}
      > */}
      <div style={{ padding: "12px 20px 0 20px" }}>
        {/* <Grid
          item
          xs={12}
          sx={{
            color: "rgb(13, 69, 153)",
            borderBottom: "solid 1px gray",
            marginBottom: "12px",
            fontWeight: 600,
          }}
        > */}
        <div className={classes.selectAllBtnWrapper}>
          <label className={classes.selectAllBtn}>
            <input
              className={`${classes.checkboxInput} ${classes.hideCheckbox}`}
              type="checkbox"
              checked={type.includes("Select_All")}
              onChange={(e) => {
                handleChangeSelectAll(e.target.checked);
              }}
            />
            <span
              className={`${classes.checkbox} ${
                type.includes("Select_All")
                  ? classes.checkboxSlctAllActive
                  : classes.checkboxSlctAllInactive
              } `}
            >
              <CheckMark />
            </span>
            {type.includes("Select_All")
              ? t("Tout désélectionner")
              : t("Tout sélectionner")}
          </label>
          {/* </Grid> */}
        </div>

        {BienTypesOptions.map((bienType) => (
          <div className={classes.listItemWrapper} key={bienType.value}>
            <label className={classes.listItem}>
              <input
                className={`${classes.checkboxInput} ${classes.hideCheckbox}`}
                type="checkbox"
                checked={type.includes(bienType.value)}
                onChange={(e) => {
                  onTypeSelectChange(e.target.checked, bienType.value);
                }}
              />
              <span
                className={`${classes.checkbox} ${
                  type.includes(bienType.value)
                    ? classes.checkboxSlctActive
                    : classes.checkboxSlctInactive
                }`}
              >
                <CheckMark />
              </span>
              {t(bienType.value)}
            </label>
          </div>
        ))}
      </div>
      {/* </Grid> */}
    </div>
  ) : (
    <div className={classes.dropdown__container}>
      <Dropdown
        liststyling={classes.liststyling}
        className={`${classes.buttonWrapper} ${
          type.length ? classes.btnWrapperActive : ""
        }`}
        setDropdownToggle={setDropdownToggle}
        dropdownToggle={dropdownToggle}
        toggleBtnStyle={classes.toggleBtnStyle}
        onToggleActive={classes.toggleBtnActive}
        valueComponent={valueComponent}
        contentComponent={contentComponent}
      />
    </div>
  );
}
