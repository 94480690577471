import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const initialState: any = {
  PrixDeBien: 0,
  Acompte: {
    prix: 0,
    percent: 0,
  },
  Taux: 4.2,
  Programme: "25 ans",
  Mensualite: 0,
  Taxes: 0,
  PANDI: 0,
  Assurances: 0,
  Capital_restant: [],
  interets_cumules: [],
  principal_rembourse: [],
};

const slice = createSlice({
  name: "calculezMensualiteV2",
  initialState,
  reducers: {
    setPrixDeBien(state, action) {
      return {
        ...state,
        PrixDeBien: action.payload,
      };
    },
    setAcompte(state, action) {
      return {
        ...state,
        Acompte: action.payload,
      };
    },
    setPrograme(state, action) {
      return {
        ...state,
        Programme: action.payload,
      };
    },
    setTaux(state, action) {
      return {
        ...state,
        Taux: action.payload,
      };
    },
    setPANDI(state, action) {
      return {
        ...state,
        PANDI: action.payload,
      };
    },
    setTaxes(state, action) {
      return {
        ...state,
        Taxes: action.payload,
      };
    },
    setAssurances(state, action) {
      return {
        ...state,
        Assurances: action.payload,
      };
    },
    setMensualite(state, action) {
      return {
        ...state,
        Mensualite: action.payload,
      };
    },
    setCapital_restant(state, action) {
      return {
        ...state,
        Capital_restant: action.payload,
      };
    },
    setinterets_cumules(state, action) {
      return {
        ...state,
        interets_cumules: action.payload,
      };
    },
    setprincipal_rembourse(state, action) {
      return {
        ...state,
        principal_rembourse: action.payload,
      };
    },
  },
});
const calculezMensualiteV2 = (state: any) =>
  state.calculezMensualiteV2 || initialState;

const mensualPrixDeBien = createSelector(
  [calculezMensualiteV2],
  (substate) => substate.PrixDeBien
);
const mensualAcompte = createSelector(
  [calculezMensualiteV2],
  (substate) => substate.Acompte
);
const mensualProgramme = createSelector(
  [calculezMensualiteV2],
  (substate) => substate.Programme
);

const mensualTaux = createSelector(
  [calculezMensualiteV2],
  (substate) => substate.Taux
);

const MensualiteSelector = createSelector(
  [calculezMensualiteV2],
  (substate) => substate.Mensualite
);

const TaxesSelector = createSelector(
  [calculezMensualiteV2],
  (substate) => substate.Taxes
);

const AssurancesSelector = createSelector(
  [calculezMensualiteV2],
  (substate) => substate.Assurances
);

const PANDISelector = createSelector(
  [calculezMensualiteV2],
  (substate) => substate.PANDI
);

const Capital_restantSelector = createSelector(
  [calculezMensualiteV2],
  (substate) => substate.Capital_restant
);
const interets_cumulesSelector = createSelector(
  [calculezMensualiteV2],
  (substate) => substate.interets_cumules
);
const principal_rembourseSelector = createSelector(
  [calculezMensualiteV2],
  (substate) => substate.principal_rembourse
);

export const selectors = {
  mensualPrixDeBien,
  mensualAcompte,
  mensualProgramme,
  mensualTaux,
  TaxesSelector,
  AssurancesSelector,
  PANDISelector,
  MensualiteSelector,
  Capital_restantSelector,
  interets_cumulesSelector,
  principal_rembourseSelector,
};

export const actions = {
  ...slice.actions,
};

export default slice.reducer;
