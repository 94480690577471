import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const initialState = {
  defaultSearchList: false,
};

const slice = createSlice({
  name: "DefaultSearchListTrack",
  initialState,
  reducers: {
    setDefaultSearchList(state, action) {
      return {
        ...state,
        defaultSearchList: action.payload,
      };
    },
  },
});

const DefaultSearchListTrack = (state: any) =>
  state.DefaultSearchListTrack || initialState;

const defaultSearchListSelector = createSelector(
  [DefaultSearchListTrack],
  (substate) => substate.defaultSearchList
);

export const selectors = {
  DefaultSearchListTrack,
  defaultSearchListSelector,
};

export const actions = {
  ...slice.actions,
};
export default slice.reducer;
