import { createSlice } from "@reduxjs/toolkit";

import { createSelector } from "reselect";
import { RootState } from "../../../../types/RootState";
import { ListingsState } from "./types";

const initialState: ListingsState = {
  quartier_links: [],
  exclusivite: false,
  virtual_tour: false,
  loading: false,
  refreshing: false,
  totalListingsCount: 0,
  error: false,
  listings: [],
  favListings: [],
  adresse: "",
  displayMobileSearchBar: true,
  title_complement: "",
  initialSearchPayloadSSR: {
    exclusivite: false,
    virtual_tour: false,
    address: "",
    no_trigger_search: false,
    prixmax: null,
    prixmin: null,
    type: [],
    caracteristiques: [],
    typologie: [],
    surfacemin: null,
    surfacemax: null,
    quartier: [],
    meublee: false,
    bounds: {
      _sw: {
        lng: -20,
        lat: 22,
      },
      _ne: {
        lng: 0,
        lat: 37,
      },
    },
    zoom: 4.8,
    sort: "date_desc",
    region: "",
    province: "",
    commune: "",
    transaction_type: "Vente",
    controlBy: "API",
    lat: 33.6343781446569,
    lng: -7.661961042799499,
    shortName: "",
    etage: 0,
    sallesDeBain: 0,
    anciennete: "",
    etat: "",
    page: 0,
    pageSize: 8,
  },
  searchPayload: {
    exclusivite: false,
    virtual_tour: false,
    address: "",
    no_trigger_search: false,
    prixmax: null,
    prixmin: null,
    type: [],
    caracteristiques: [],
    typologie: [],
    surfacemin: null,
    surfacemax: null,
    quartier: null,
    meublee: false,
    bounds: {
      _sw: {
        lng: -20,
        lat: 22,
      },
      _ne: {
        lng: 0,
        lat: 37,
      },
    },
    zoom: 4.8,
    sort: "date_desc",
    region: "",
    province: "",
    commune: "",
    transaction_type: "Vente",
    vendu: false,
    controlBy: "API",
    lat: 33.6343781446569,
    lng: -7.661961042799499,
    shortName: "",
    etage: 0,
    sallesDeBain: 0,
    anciennete: "",
    etat: "",
    page: 0,
    pageSize: 8,
  },
  currentListing: null,
  mapVsList: "LIST",
  mapState: {
    preventZoomEndAction: false,
    center: {
      lat: 0,
      lng: 0,
    },
    zoom: 12,
    speed: 2,
  },
  mapListing: {
    listing: null,
    coordinates: { latitude: 0, longitude: 0 },
  },
  showPopup: 0,
  activeListingId: "",
  geolocateRequestsCounter: 0,
  currentLocationAddress: "",
};

const slice = createSlice({
  name: "listingsV2",
  initialState,
  reducers: {
    setloading: (state, action) => {
      return {
        ...state,
        loading: action.payload.loading,
      };
    },

    setRefreshing: (state, action) => {
      return {
        ...state,
        refreshing: action.payload.refreshing,
      };
    },

    searchListings: (state, action) => {
      return {
        ...state,
        refreshing: action.payload.refreshing ? true : state.refreshing,
        loading: action.payload.refreshing ? state.loading : true,
      };
    },

    searchListingSuccess: (state, action) => {
      let listingsWithoutImagesErrors = action.payload.listings.map(
        (listing) => {
          let listingImages = listing.images;
          // .map((image) => {
          //   return typeof image === "string" ? image : image.url;
          // })
          // .filter(
          //   (image) =>
          //     image !== null &&
          //     image !== undefined &&
          //     typeof image === "string"
          // );

          // if (listingImages.length === 0) {
          if (!listingImages.length) {
            listingImages = [
              "https://storage.googleapis.com/headers-agenz/Logo%20300_%20300%20px.jpg",
            ];
          }

          return {
            ...listing,
            images: listingImages,
          };
        }
      );

      return {
        ...state,
        totalListingsCount: action.payload.totalListingsCount,
        // listings: action.payload.refreshing
        //   ? state.listings.concat(listingsWithoutImagesErrors)
        //   : listingsWithoutImagesErrors,
        //IF WE DO CONCAT WE ADD TO EXISTING CARDS
        listings: listingsWithoutImagesErrors,
        refreshing: false,
        loading: false,
      };
    },

    setFavListing: (state, action) => {
      return {
        ...state,
        favListings: action.payload.favListings,
      };
    },

    searchListingError: (state, action) => {
      return {
        ...state,
        error: action.payload.error,
        refreshing: false,
        loading: false,
      };
    },

    setCurrentLocationAddress: (state, action) => {
      return {
        ...state,
        adresse: action.payload.address,
      };
    },

    setSearchAdress: (state, action) => {
      return {
        ...state,
        adresse: action.payload,
      };
    },
    setDisplayMobileSearchBar: (state, action) => {
      return {
        ...state,
        displayMobileSearchBar: action.payload,
      };
    },
    setSearchPayload: (state, action) => {
      return {
        ...state,
        title_complement: action.payload.title_complement
          ? action.payload.title_complement
          : "",
        allFilters: action.payload.searchPayload.no_trigger_search
          ? state.allFilters
          : calculateAllFilters(
            {
              ...state.searchPayload,
              ...action.payload.searchPayload,
            },
            action.payload.controlBy === "MAP"
          ),
        searchPayload: {
          ...state.searchPayload,
          ...action.payload.searchPayload,
          page: action.payload.searchPayload.page
            ? action.payload.searchPayload.page
            : 0,
          no_trigger_search: action.payload.searchPayload.no_trigger_search
            ? action.payload.searchPayload.no_trigger_search
            : false,
        },
      };
    },

    setInitialHydration: (state, action) => {
      return {
        ...state,
        ...action.payload,
        allFilters: calculateAllFilters(
          {
            ...state.searchPayload,
            ...action.payload.searchPayload,
          },
          action.payload.controlBy === "MAP"
        ),
      };
    },
    setQuartierLinks: (state, action) => {
      return {
        ...state,
        quartier_links: action.payload,
      };
    },
    setMAPVSLIST: (state, action) => {
      return {
        ...state,
        mapVsList: action.payload,
      };
    },
    increasePage: (state, action) => {
      return {
        ...state,
        searchPayload: {
          ...state.searchPayload,
          page: state.searchPayload.page + 1,
          no_trigger_search: false,
        },
      };
    },
    setPage: (state, action) => {
      return {
        ...state,
        searchPayload: {
          ...state.searchPayload,
          page: action.payload,
          no_trigger_search: false,
        },
      };
    },

    setMapState: (state, action) => {
      return {
        ...state,
        searchPayload: {
          ...state.searchPayload,
          mapState: action.payload.mapState,
        },
      };
    },

    // setMapListing: (state, action) => {
    //   return {
    //     ...state,
    //     searchPayload: {
    //       ...state.searchPayload,
    //       mapListing: action.payload.mapListing,
    //     },
    //   };
    // },
    setMapListing: (state, action) => {
      return {
        ...state,
        mapListing: action.payload,
      };
    },
    setShowPopup: (state, action) => {
      return {
        ...state,
        showPopup: action.payload,
      };
    },
    setActiveListingId: (state, action) => {
      return {
        ...state,
        activeListingId: action.payload,
      };
    },

    setMapAllFilters: (state, action) => {
      return {
        ...state,
        searchPayload: {
          ...state.searchPayload,
          allFiltersFromMap: calculateAllFilters(
            state.searchPayload,
            action.payload.fromMap
          ),
        },
      };
    },

    setCurrentListing: (state, action) => {
      return {
        ...state,
        currentListing: action.payload.currentListing,
      };
    },

    IncrementGeolocateRequestsCounter: (state, action) => {
      return {
        ...state,
        geolocateRequestsCounter: state.geolocateRequestsCounter + 1,
      };
    },
  },
});

const listingSorter = (sort) => (aa, bb) => {
  const equalNewDate = new Date().toISOString();

  const a = {
    ...aa,
    date: aa.date ? aa.date : equalNewDate,
    disponibilite: aa.disponibilite
      ? aa.disponibilite
      : {
        annee: "2023",
        trimestre: 1,
      },
  };
  const b = {
    ...bb,
    date: bb.date ? bb.date : equalNewDate,
    disponibilite: bb.disponibilite
      ? bb.disponibilite
      : {
        annee: "2023",
        trimestre: 1,
      },
  };

  switch (sort) {
    case "price_asc":
      if (a.transaction_type === "Projets Neufs") {
        return a.prixApartirDe - b.prixApartirDe;
      } else {
        return a.prix - b.prix;
      }
    case "price_desc":
      if (a.transaction_type === "Projets Neufs") {
        return b.prixApartirDe - a.prixApartirDe;
      } else {
        return b.prix - a.prix;
      }
    case "date_desc":
      if (a.transaction_type === "Projets Neufs") {
        if (a.disponibilite.trimestre === "Immédiate") {
          return 1;
        }
        if (b.disponibilite.trimestre === "Immédiate") {
          return 1;
        }
        if (a.disponibilite.annee === b.disponibilite.annee) {
          if (a.disponibilite.trimestre === b.disponibilite.trimestre) {
            return new Date(a.date).getTime() - new Date(b.date).getTime();
          } else {
            return (
              Number(a.disponibilite.trimestre) -
              Number(b.disponibilite.trimestre)
            );
          }
        } else {
          return Number(a.disponibilite.annee) - Number(b.disponibilite.annee);
        }
      } else {
        if (a.date > b.date) {
          return -1;
        } else {
          return 1;
        }
      }
    case "date_asc":
      if (a.transaction_type === "Projets Neufs") {
        if (a.disponibilite.trimestre === "Immédiate") {
          return 1;
        }
        if (b.disponibilite.trimestre === "Immédiate") {
          return 1;
        }

        if (a.disponibilite.annee === b.disponibilite.annee) {
          if (a.disponibilite.trimestre === b.disponibilite.trimestre) {
            return new Date(b.date).getTime() - new Date(a.date).getTime();
          } else {
            return (
              Number(b.disponibilite.trimestre) -
              Number(a.disponibilite.trimestre)
            );
          }
        } else {
          return Number(b.disponibilite.annee) - Number(a.disponibilite.annee);
        }
      } else {
        if (a.date > b.date) {
          return 1;
        } else {
          return -1;
        }
      }
    default:
      return 0;
  }
};

const calculateAllFilters = (searchPayload, fromMap = false) => {
  let allFilters: any = [
    "all",
    ["==", "vendu", searchPayload.vendu ? searchPayload.vendu : false],
    ["==", "valid", true],
    ["!=", "deleted", true],
    ["!=", "rejected", true],

    ["==", "transaction_type", searchPayload.transaction_type],
  ];
  if (!searchPayload.vendu) {
    allFilters.push(["has", "prix"]);
  }
  if (searchPayload.exclusivite) {
    allFilters.push(["==", "exclusivite", true]);
  }
  if (searchPayload.virtual_tour) {
    allFilters.push(["==", "virtual_tour", true]);
  }
  if (searchPayload.prixmin) {
    allFilters.push([">", "prix", searchPayload.prixmin]);
  }
  if (searchPayload.prixmax) {
    allFilters.push(["<", "prix", searchPayload.prixmax]);
  }
  if (searchPayload.surfacemax) {
    allFilters.push(["<=", "surface", searchPayload.surfacemax]);
  }
  if (searchPayload.surfacemin) {
    allFilters.push([">=", "surface", searchPayload.surfacemin]);
  }
  if (!searchPayload.lat && !searchPayload.lng) {
    if (!fromMap) {
      if (searchPayload.quartier && searchPayload.quartier.length > 0) {
        allFilters.push([
          "in",
          "quartier",
          ...(typeof searchPayload.quartier === "string"
            ? [searchPayload.quartier]
            : searchPayload.quartier),
        ]);
      } else {
        if (searchPayload.commune) {
          allFilters.push(["==", "commune", searchPayload.commune]);
        }
      }
    }
  }
  if (searchPayload.commune) {
    allFilters.push(["==", "commune", searchPayload.commune]);
  }
  if (searchPayload.province) {
    allFilters.push(["==", "province", searchPayload.province]);
  }
  if (searchPayload.region) {
    allFilters.push(["==", "region", searchPayload.region]);
  }

  if (searchPayload.type) {
    if (searchPayload.type.length) {
      allFilters.push(["in", "type", ...searchPayload.type]);
    }
  }
  if (
    searchPayload.typologie &&
    searchPayload.typologie.length > 0 &&
    searchPayload.typologie.includes(5)
  ) {
    let newtypo = searchPayload.typologie.concat([
      6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
    ]);
    allFilters.push(["in", "typologie", ...newtypo]);
  } else if (searchPayload.typologie && searchPayload.typologie.length > 0) {
    allFilters.push(["in", "typologie", ...searchPayload.typologie]);
  }
  return allFilters;
};
const selectListingsV2Domain = (state: RootState) =>
  state.listingsV2 || initialState;

const allDataSelector = createSelector(
  [selectListingsV2Domain],
  (substate) => substate
);
const listingsSelector = createSelector(
  [selectListingsV2Domain],
  (substate) => substate.listings
);
const quartier_linksSelector = createSelector(
  [selectListingsV2Domain],
  (substate) => substate.quartier_links
);
const favoriteListingsSelactor = createSelector(
  [selectListingsV2Domain],
  (substate) => substate.favListings
);
const displayMobileSearchBarSelactor = createSelector(
  [selectListingsV2Domain],
  (substate) => substate.displayMobileSearchBar
);

const searchPayloadSelector = createSelector(
  [selectListingsV2Domain],
  (substate) => substate.searchPayload
);
const initialSearchPayloadSelector = createSelector(
  [selectListingsV2Domain],
  (substate) => substate.initialSearchPayloadSSR
);
const loadingSelactor = createSelector(
  [selectListingsV2Domain],
  (substate) => substate.loading
);
const refreshingSelactor = createSelector(
  [selectListingsV2Domain],
  (substate) => substate.refreshing
);

const totalListingsCountSelactor = createSelector(
  [selectListingsV2Domain],
  (substate) => substate.totalListingsCount
);
const mapStateSelactor = createSelector(
  [selectListingsV2Domain],
  (substate) => substate.mapState
);
const mapListingSelactor = createSelector(
  [selectListingsV2Domain],
  (substate) => substate.mapListing
);
const showPopupSelactor = createSelector(
  [selectListingsV2Domain],
  (substate) => substate.showPopup
);
const activeListingIdSelactor = createSelector(
  [selectListingsV2Domain],
  (substate) => substate.activeListingId
);
const currentListingSelactor = createSelector(
  [selectListingsV2Domain],
  (substate) => substate.currentListing
);
const titleComplementSelector = createSelector(
  [selectListingsV2Domain],
  (substate) => substate.title_complement
);

const geolocateRequestsCounterSelactor = createSelector(
  [selectListingsV2Domain],
  (substate) => substate.geolocateRequestsCounter
);

const currentLocationAddressSelactor = createSelector(
  [selectListingsV2Domain],
  (substate) => substate.currentLocationAddress
);

const allFiltersSelactor = createSelector(
  [selectListingsV2Domain],
  (substate) => substate.allFilters
);
const allFiltersFromMapSelactor = createSelector(
  [selectListingsV2Domain],
  (substate) => substate.allFiltersFromMap
);
const mapVsListSelactor = createSelector(
  [selectListingsV2Domain],
  (substate) => substate.mapVsList
);
const adresseSelector = createSelector(
  [selectListingsV2Domain],
  (substate) => substate.adresse
);

export const selectors = {
  allDataSelector,
  listingsSelector,
  favoriteListingsSelactor,
  displayMobileSearchBarSelactor,
  searchPayloadSelector,
  loadingSelactor,
  mapStateSelactor,
  currentListingSelactor,
  refreshingSelactor,
  totalListingsCountSelactor,
  geolocateRequestsCounterSelactor,
  mapListingSelactor,
  showPopupSelactor,
  activeListingIdSelactor,
  currentLocationAddressSelactor,
  allFiltersSelactor,
  allFiltersFromMapSelactor,
  mapVsListSelactor,
  adresseSelector,
  initialSearchPayloadSelector,
  titleComplementSelector,
  quartier_linksSelector,
};

export const actions = {
  ...slice.actions,
};

export default slice.reducer;
