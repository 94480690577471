import React from "react";

export default function ProfilIcon() {
  return (
    <span>
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.417 24C19.046 24 24.417 18.629 24.417 12C24.417 5.371 19.046 0 12.417 0C5.78799 0 0.416992 5.371 0.416992 12C0.416992 18.629 5.78799 24 12.417 24ZM20.29 17.608C19.109 16.088 17.285 15.097 15.204 15.097C14.711 15.097 13.946 15.561 12.417 15.561C10.893 15.561 10.123 15.097 9.62999 15.097C7.55399 15.097 5.72999 16.089 4.54399 17.608C3.37028 15.9737 2.73927 14.0121 2.73999 12C2.73999 6.663 7.07999 2.323 12.417 2.323C17.754 2.323 22.094 6.663 22.094 12C22.094 14.09 21.422 16.026 20.29 17.608ZM12.417 13.935C13.6322 13.9098 14.7892 13.4093 15.6397 12.541C16.4903 11.6726 16.9666 10.5055 16.9666 9.29C16.9666 8.0745 16.4903 6.9074 15.6397 6.03904C14.7892 5.17068 13.6322 4.67022 12.417 4.645C11.2018 4.67022 10.0448 5.17068 9.19426 6.03904C8.34373 6.9074 7.86738 8.0745 7.86738 9.29C7.86738 10.5055 8.34373 11.6726 9.19426 12.541C10.0448 13.4093 11.2018 13.9098 12.417 13.935ZM12.417 11.613C11.8009 11.6129 11.2101 11.368 10.7745 10.9323C10.339 10.4965 10.0944 9.9056 10.0945 9.2895C10.0946 8.6734 10.3395 8.08259 10.7752 7.64704C11.211 7.21148 11.8019 6.96687 12.418 6.967C12.7231 6.96707 13.0251 7.02722 13.3069 7.14402C13.5887 7.26082 13.8448 7.43199 14.0605 7.64774C14.2761 7.8635 14.4472 8.11962 14.5639 8.40149C14.6805 8.68335 14.7406 8.98544 14.7405 9.2905C14.7404 9.59556 14.6803 9.89762 14.5635 10.1794C14.4467 10.4613 14.2755 10.7173 14.0597 10.933C13.844 11.1486 13.5879 11.3197 13.306 11.4364C13.0241 11.553 12.7221 11.6131 12.417 11.613ZM12.417 21.677C10.1055 21.6778 7.87089 20.8465 6.12199 19.335C6.48704 18.768 6.9842 18.298 7.57081 17.9653C8.15742 17.6326 8.81598 17.4472 9.48999 17.425C10.496 17.734 11.454 17.889 12.417 17.889C13.38 17.889 14.338 17.739 15.344 17.424C16.0179 17.4473 16.6762 17.6332 17.2626 17.966C17.8491 18.2987 18.3464 18.7684 18.712 19.335C16.9631 20.8465 14.7285 21.6778 12.417 21.677Z" fill="black"/>
        </svg>
    </span>
  );
}
