import useTranslation from "next-translate/useTranslation";
import React from "react";
import formatUrl from "utils/FormatUrl";
import style from "./style.module.css";

function PlusInformationBtn(props) {
  // const handleWhatsappConnexion = () => {

  // }

  const message =
    "Bonjour, j'aimerai me faire accompagner dans ma demande de crédit immobilier";
  const phoneNumber = "+212669793808";
  const link = `https://api.whatsapp.com/send?phone=${
    props.phoneNumber ? props.phoneNumber : phoneNumber
  }&text=${props.message ? props.message : message}`;
  const { lang } = useTranslation("");
  return (
    <div onClick={props.onClick} className={props.styleClass}>
      {link ? (
        <a href={link} target="_blank">
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.4959 2.928C15.6099 1.041 13.1019 0.001 10.4299 0C4.92394 0 0.442945 4.479 0.440945 9.985C0.439945 11.745 0.899945 13.463 1.77395 14.977L0.418945 20L5.65195 18.763C7.11095 19.559 8.75294 19.978 10.4249 19.979H10.4289C15.9339 19.979 20.4149 15.499 20.4179 9.994C20.4199 7.325 19.3819 4.816 17.4959 2.928ZM15.3169 13.554C15.1089 14.137 14.0899 14.699 13.6319 14.74C13.1739 14.782 12.7449 14.947 10.6369 14.116C8.09995 13.116 6.49795 10.515 6.37395 10.349C6.24895 10.182 5.35495 8.996 5.35495 7.768C5.35495 6.54 5.99995 5.936 6.22895 5.687C6.45795 5.437 6.72795 5.375 6.89495 5.375C7.06095 5.375 7.22795 5.375 7.37295 5.381C7.55095 5.388 7.74795 5.397 7.93495 5.812C8.15695 6.306 8.64194 7.54 8.70395 7.665C8.76595 7.79 8.80795 7.936 8.72495 8.102C8.64194 8.268 8.59995 8.372 8.47595 8.518C8.35095 8.664 8.21394 8.843 8.10194 8.955C7.97694 9.079 7.84695 9.215 7.99195 9.464C8.13795 9.714 8.63795 10.531 9.37995 11.192C10.3339 12.042 11.1369 12.305 11.3869 12.431C11.6369 12.556 11.7819 12.535 11.9279 12.368C12.0739 12.202 12.5519 11.64 12.7179 11.39C12.8839 11.14 13.0509 11.182 13.2799 11.265C13.5089 11.348 14.7359 11.952 14.9849 12.077C15.2349 12.202 15.4009 12.264 15.4629 12.368C15.5249 12.471 15.5249 12.971 15.3169 13.554Z"
              fill="white"
            />
          </svg>
          <p className={style.title}>{props.title}</p>
        </a>
      ) : (
        <>
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.4959 2.928C15.6099 1.041 13.1019 0.001 10.4299 0C4.92394 0 0.442945 4.479 0.440945 9.985C0.439945 11.745 0.899945 13.463 1.77395 14.977L0.418945 20L5.65195 18.763C7.11095 19.559 8.75294 19.978 10.4249 19.979H10.4289C15.9339 19.979 20.4149 15.499 20.4179 9.994C20.4199 7.325 19.3819 4.816 17.4959 2.928ZM15.3169 13.554C15.1089 14.137 14.0899 14.699 13.6319 14.74C13.1739 14.782 12.7449 14.947 10.6369 14.116C8.09995 13.116 6.49795 10.515 6.37395 10.349C6.24895 10.182 5.35495 8.996 5.35495 7.768C5.35495 6.54 5.99995 5.936 6.22895 5.687C6.45795 5.437 6.72795 5.375 6.89495 5.375C7.06095 5.375 7.22795 5.375 7.37295 5.381C7.55095 5.388 7.74795 5.397 7.93495 5.812C8.15695 6.306 8.64194 7.54 8.70395 7.665C8.76595 7.79 8.80795 7.936 8.72495 8.102C8.64194 8.268 8.59995 8.372 8.47595 8.518C8.35095 8.664 8.21394 8.843 8.10194 8.955C7.97694 9.079 7.84695 9.215 7.99195 9.464C8.13795 9.714 8.63795 10.531 9.37995 11.192C10.3339 12.042 11.1369 12.305 11.3869 12.431C11.6369 12.556 11.7819 12.535 11.9279 12.368C12.0739 12.202 12.5519 11.64 12.7179 11.39C12.8839 11.14 13.0509 11.182 13.2799 11.265C13.5089 11.348 14.7359 11.952 14.9849 12.077C15.2349 12.202 15.4009 12.264 15.4629 12.368C15.5249 12.471 15.5249 12.971 15.3169 13.554Z"
              fill="white"
            />
          </svg>
          <p className={style.title}>{props.title}</p>
        </>
      )}
    </div>
  );
}

export default PlusInformationBtn;
