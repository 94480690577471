
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { useEffect, useRef } from "react";
import { wrapper } from "../redux/configureStore";
import "../styles/App.scss";
import "../styles/ios.scss";
import { useRouter } from "next/router";
// import "@fortawesome/fontawesome-svg-core/styles.css"; // import Font Awesome CSS
// import moment from "moment";
// import "moment/locale/ar";
// import "moment/locale/fr";
// import useTranslation from "next-translate/useTranslation";
import NavbarGuard from "../components/navbar/navbarguard";
import AuthHelperContainer from "../containers/v2/AuthHelperContainer";
import Navbarv2 from "components/Navbarv2";
import { onINP, onCLS, onFCP, onLCP } from "web-vitals";
// function getCookie(name) {
//   const value = `; ${document.cookie}`;
//   const parts = value.split(`; ${name}=`);
//   if (parts.length === 2) return parts.pop().split(";").shift();
// }
function initGTMOnEvent(event) {
    initGTM();
    event.currentTarget.removeEventListener(event.type, initGTMOnEvent); // remove the event listener that got triggered
}
function initGTM() {
    if (window.gtmDidInit) {
        return false;
    }
    window.gtmDidInit = true; // flag to ensure script does not get added to DOM more than once.
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    // ensure PageViews is always tracked (on script load)
    script.onload = () => {
        dataLayer.push({
            event: "gtm.js",
            "gtm.start": new Date().getTime(),
            "gtm.uniqueEventId": 0,
        });
    };
    script.innerHTML =
        window.location.pathname.startsWith("/annonces") ||
            window.location.pathname.startsWith("/acheter") ||
            window.location.pathname.startsWith("/louer")
            ? `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-WRN3R8G3');
  `
            : `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-NDW2ZBQ');`;
    document.head.appendChild(script);
}
function MyApp({ Component, pageProps, ...rest }) {
    // const { store, props } = wrapper.useWrappedStore(rest);
    // const { lang } = useTranslation("common");
    // const router = useRouter();
    // const { locale, defaultLocale } = useRouter();
    useEffect(() => {
        if (process.env.NEXT_PUBLIC_CLOUD_ENV !== "prod") {
            onINP(console.log, { reportAllChanges: true });
            onCLS(console.log, { reportAllChanges: true });
            onFCP(console.log, { reportAllChanges: true });
            onLCP(console.log, { reportAllChanges: true });
        }
        // if (process.env.NEXT_PUBLIC_CLOUD_ENV == "prod") {
        // if (typeof window !== "undefined") {
        //   document.addEventListener("DOMContentLoaded", () => {
        //     setTimeout(initGTM, 1000);
        //   });
        //   document.addEventListener("scroll", initGTMOnEvent);
        //   document.addEventListener("mousemove", initGTMOnEvent);
        //   document.addEventListener("touchstart", initGTMOnEvent);
        // }
        // }
    }, []);
    const firstUpdateRef = useRef(true);
    // useEffect(() => {
    // debugger; // Pause execution to inspect call stack
    // if (firstUpdateRef.current) {
    //   firstUpdateRef.current = false;
    // } else {
    // if (process.env.NEXT_PUBLIC_DATADOG_ENABLED) {
    // const analytics = getAnalytics(app);
    // logEvent(analytics, "page_view", {
    //   page_location: router.asPath,
    //   page_path: router.asPath,
    // });
    // }
    // }
    // (function (history) {
    //   var pushState = history.pushState;
    //   history.pushState = function (state) {
    //     if (typeof history.onpushstate == "function") {
    //       history.onpushstate({ state: state });
    //     }
    //     // ... whatever else you want to do
    //     // maybe call onhashchange e.handler
    //     return pushState.apply(history, arguments);
    //   };
    // })(window.history);
    // window.onpopstate = history.onpushstate = function (e) {
    // };
    // }, []);
    console.warn = console.error = () => { };
    const { pathname } = useRouter();
    return (<>
      {/* <Provider store={store}> */}
      <AuthHelperContainer userSSR={pageProps.user}/>
      {/* {pathname.startsWith("/espace-pro") ||
        pathname.startsWith("/estimation") ? (
          <NavbarGuard userSSR={pageProps.user} />
        ) : ( */}
      <Navbarv2 />
      {/* )} */}

      <Component {...pageProps}/>
      {/* </Provider> */}
    </>);
}
const __Next_Translate__Page__18c05bb86c5__ = wrapper.withRedux(MyApp);

    export default __appWithI18n(__Next_Translate__Page__18c05bb86c5__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  