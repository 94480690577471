import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const initialState: any = {
  route: null,
  listings: [],
  listingsLoading: false,
  estimation: [],
  totalEstimation: 0,
  totalPagesEstimation: 0,
  listingsQuery: { page: 0, pageSize: 10 },
  userAnnonces: [],
  totalAnnoncesNumbers: {
    totalAnnonces: 0,
    totalRejectedAnnonces: 0,
    totalValidAnnonces: 0,
    totalStandByAnnonces: 0,
  },
  inboxMessages: [],
  totalInboxMessages: 0,
  inboxRefreshing: false,
  inboxLoading: false,
  inboxNotification: 0,
  isAuthDialogVisible: false,
  savedSearches: [],
  messagesFilter: {
    pageSize: 20,
    page: 0,
    sort: "date",
    order: -1,
    search: "",
    filters: {},
  },
};

const slice = createSlice({
  name: "userSpace",
  initialState: initialState,
  reducers: {
    setIsAuthDialogVisible(state, action) {
      return { ...state, isAuthDialogVisible: action.payload.data };
    },
    setInboxMessages(state, action) {
      return { ...state, inboxMessages: action.payload.data };
    },
    setTotalInboxMessages(state, action) {
      return { ...state, totalInboxMessages: action.payload.data };
    },
    setInboxRefreshing(state, action) {
      return { ...state, inboxRefreshing: action.payload.data };
    },
    setInboxNotification(state, action) {
      return { ...state, inboxNotification: action.payload.data };
    },
    setMessagesFilter: (state, action) => {
      return {
        ...state,
        messagesFilter: action.payload.data,
      };
    },
    setInboxLoading: (state, action) => {
      return {
        ...state,
        inboxLoading: action.payload.data,
      };
    },
    setRoute(state, action) {
      return { ...state, route: action.payload.data };
    },
    setListings(state, action) {
      return {
        ...state,
        listings:
          state.listingsQuery.page == 0
            ? action.payload.data
            : [...state.listings, action.payload.data],
      };
    },
    setListingsLoading(state, action) {
      return { ...state, listingsLoading: action.payload.data };
    },
    setEstimation(state, action) {
      return {
        ...state,
        estimation: action.payload.results,
        totalEstimation: action.payload.totalItems,
        totalPagesEstimation: action.payload.totalPages,
      };
    },
    deleteEstimation(state, action) {
      return {
        ...state,
        estimation: state.estimation.map((item) => {
          if (item._id == action.payload) {
            return {
              ...item,
              deleted: true,
            };
          } else {
            return item;
          }
        }),
      };
    },
    setUserAnnonces(state, action) {
      return {
        ...state,
        userAnnonces: action.payload.data,
      };
    },
    setSavedSearches(state, action) {
      return {
        ...state,
        savedSearches: action.payload.data,
      };
    },
    setTotalAnnoncesNumbers: (state, action) => {
      let StandbyAnnonces =
        action.payload.data.totalAnnonces -
        action.payload.data.totalValidAnnonces -
        action.payload.data.totalRejectedAnnonces;

      return {
        ...state,
        totalAnnoncesNumbers: {
          totalAnnonces: action.payload.data.totalAnnonces,
          totalRejectedAnnonces: action.payload.data.totalRejectedAnnonces,
          totalValidAnnonces: action.payload.data.totalValidAnnonces,
          totalStandByAnnonces: action.payload.data.totalStandByAnnonces
            ? action.payload.data.totalStandByAnnonces
            : StandbyAnnonces > 0
            ? StandbyAnnonces
            : 0,
        },
      };
    },
  },
});
const UserSpace = (state) => state.userSpace || initialState;

const routeSelector = createSelector([UserSpace], (substate) => substate.route);

const inboxMessagesSelector = createSelector(
  [UserSpace],
  (substate) => substate.inboxMessages
);

const totalInboxMessagesSelector = createSelector(
  [UserSpace],
  (substate) => substate.totalInboxMessages
);

const inboxRefreshingSelector = createSelector(
  [UserSpace],
  (substate) => substate.inboxRefreshing
);

const inboxNotificationSelector = createSelector(
  [UserSpace],
  (substate) => substate.inboxNotification
);

const messagesFilterSelector = createSelector(
  [UserSpace],
  (substate) => substate.messagesFilter
);
const inboxLoadingSelector = createSelector(
  [UserSpace],
  (substate) => substate.inboxLoading
);

const listingsSelector = createSelector(
  [UserSpace],
  (substate) => substate.listings
);

const isAuthDialogVisibleSelector = createSelector(
  [UserSpace],
  (substate) => substate.isAuthDialogVisible
);

const estimationSelector = createSelector(
  [UserSpace],
  (substate) => substate.estimation
);
const totalEstimationSelector = createSelector(
  [UserSpace],
  (substate) => substate.totalEstimation
);

const totalPagesEstimationSelector = createSelector(
  [UserSpace],
  (substate) => substate.totalPagesEstimation
);

const listingsLoadingSelector = createSelector(
  [UserSpace],
  (substate) => substate.listingsLoading
);

const listingsQuerySelector = createSelector(
  [UserSpace],
  (substate) => substate.listingsQuery
);

const userAnnoncesSelector = createSelector(
  [UserSpace],
  (substate) => substate.userAnnonces
);

const totalAnnoncesNumbersSelector = createSelector(
  [UserSpace],
  (substate) => substate.totalAnnoncesNumbers
);

const savedSearchesSelector = createSelector(
  [UserSpace],
  (substate) => substate.savedSearches
);

export const selectors = {
  routeSelector,
  listingsSelector,
  listingsLoadingSelector,
  listingsQuerySelector,
  estimationSelector,
  totalEstimationSelector,
  totalPagesEstimationSelector,
  userAnnoncesSelector,
  totalAnnoncesNumbersSelector,
  inboxMessagesSelector,
  totalInboxMessagesSelector,
  inboxRefreshingSelector,
  inboxNotificationSelector,
  messagesFilterSelector,
  inboxLoadingSelector,
  isAuthDialogVisibleSelector,
  savedSearchesSelector,
};

export const actions = {
  ...slice.actions,
};

export default slice.reducer;
