import { capitaliserPremiereLettre } from "utils/Functions";

export const types_to_uppercase = {
  terrain: "Terrain",
  "location-appartements": "Appartement",
  "location-studios": "Appartement",
  "location-bureaux": "Bureau",
  "location-locaux-magasins": "Commerce",
  "location-villas": "Villa",
  "location-maisons": "Maison",
  "location-riads": "Riad",
  "location-riad": "Riad",
  "location-terrains": "Terrain",
  "location-local-industriel": "Local Industriel",
  "location-locaux-industriels": "Local Industriel",
  "vente-studios": "Appartement",
  "annonces-exclusives": "ALL",
  "vente-appartements": "Appartement",
  "vente-bureaux": "Bureau",
  "vente-locaux-magasins": "Commerce",
  "vente-villas": "Villa",
  "vente-maisons": "Maison",
  "vente-terrains": "Terrain",
  "vente-local-industriel": "Local Industriel",
  "vente-locaux-industriels": "Local Industriel",
  "vente-riads": "Riad",
  terrains: "Terrain",
  studio: "Appartement",
  appartement: "Appartement",
  commerce: "Commerce",
  villa: "Villa",
  maison: "Maison",
  riad: "Riad",
  terrainss: "Terrain",
  studios: "Appartement",
  appartements: "Appartement",
  commerces: "Commerce",
  villas: "Villa",
  maisons: "Maison",
  riads: "Riad",
  "locaux-magasins": "Commerce",
  "local-industriel": "Local Industriel",
  bureau: "Bureau",
  "locaux-industriels": "Local Industriel",
  bureaux: "Bureau",
};
export const province_to_lat_lng = {
  //OPEN PROVINCE
  rabat: [33.97526955962654, -6.8517187889343285],
  casablanca: [33.56108801291275, -7.635011078156706],
  marrakech: [31.62742462264625, -7.984413394233446],
  tanger: [35.766544729841485, -5.823038049448661],
  mohammadia: [33.69264282756547, -7.385176296750116],
  kenitra: [34.26013206940551, -6.5943401766218885],
  sale: [34.045934663501995, -6.797137107414445],
  "el-jadida": [33.242788402809936, -8.510349572527533],
  temara: [33.91815243327541, -6.932269615735004],
  bouskoura: [33.467731735262774, -7.645145073798377],
  agadir: [30.42135870359439, -9.580259377458102],

  //CLOSED :
  "inezgane-ait-melloul": [30.381356391431765, -9.52542793529011],
  zagora: [30.357822509596573, -5.891757173271117],
  midelt: [32.44438854744806, -4.79600384249008],
  benslimane: [33.8184796494616, -7.14700018650514],
  rehamna: [32.244768130177505, -8.395655251237457],
  nador: [35.1552053417534, -2.9309255552440447],
  khemisset: [33.88701438131106, -6.306069763321432],
  "al-hoceima": [35.23378526353433, -3.924956940762627],
  settat: [32.9958334295425, -7.632769807602472],
  tinghir: [30.95725924748001, -5.067313001729531],
  "chtouka-ait-baha": [30.214745343585136, -9.371775558338499],
  meknes: [33.890103054995606, -5.498755481265903],
  fes: [34.03785685575344, -4.998562406877885],
  tetouan: [35.55051966004032, -5.242332490089524],
  azilal: [32.04309384811563, -6.231494127329682],
  chefchaouen: [35.24121261992235, -4.790249073864231],
  tiznit: [29.80197000287947, -9.824445338034792],
  essaouira: [31.50003691658695, -9.76247937663794],
  "fahs-anjra": [35.77009832251623, -5.708863425474277],
  "fahs-anjra-": [35.77009832251623, -5.708863425474277],
  ifrane: [33.52642212716417, -5.112080525142517],
  guelmim: [28.992066133725004, -10.051868434199463],
  taroudannt: [30.29869315327141, -8.394105027992879],
  safi: [32.29885259304555, -9.2385054207422],
  berkane: [35.075195637562075, -2.2377722666150817],
  mediouna: [33.5669435185586, -7.513526416674779],
  "al-haouz": [31.44354118700909, -8.062883134537165],
  "el-kelaa-des-sraghna": [32.09965994748863, -7.227580040698285],
  "es-semara": [26.82203245145091, -11.52956145468849],
  "el-hajeb": [33.5842512, -5.2551102],
  ouarzazate: [31.25133643043179, -7.389519186436246],
  guercif: [34.21562381640348, -3.3756203509057343],
  khouribga: [32.88705561303758, -6.900752998151528],
  youssoufia: [32.036741892894526, -8.64668005249473],
  "beni-mellal": [32.33542856196448, -6.354334759559578],
  larache: [35.198064165701965, -6.150339143551918],
  figuig: [32.797937341473315, -3.080151720323073],
  errachidia: [31.9405972, -4.418251],
  taza: [34.2196845, -4.0054623],
  sefrou: [33.838008956329816, -4.551789350311822],
  "oued-ed-dahab": [23.821164008880487, -15.89736360468342],
  "m-diq-fnideq": [35.6627774, -5.294569399999999],
  "sidi-bennour": [32.7391747, -9.038254799999999],
  chichaoua: [31.641006228691452, -8.573498656334323],
  "oujda-angad": [34.6885076311234, -1.907196516398841],
  "sidi-slimane": [34.264183311227995, -5.921438722984647],
  "sidi-kacem": [34.237665592927996, -5.730446749342121],
  berrechid: [33.49162840597287, -7.897635434993731],
};
export const slug_to_commune = {
  agadir: "Agadir",
  agafay: "Agafay",
  agdal: "Agdal",
  "agdal-riyad": "Agdal Riyad",
  "aghbalou-n-kerdous": "Aghbalou N'Kerdous",
  aghbar: "Aghbar",
  aghouatim: "Aghouatim",
  agoudim: "Agoudim",
  ahlaf: "Ahlaf",
  "ain-attig": "Ain Attig",
  "ain-bida": "Ain Bida",
  "ain-chkef": "Ain Chkef",
  "ain-dorbane-lahlaf": "Ain Dorbane-Lahlaf",
  "ain-el-aouda": "Ain El Aouda",
  "ain-harrouda": "Ain Harrouda",
  "ain-sfa": "Ain Sfa",
  "ain-tizgha": "Ain Tizgha",
  "ait-malek": "Ait Malek",
  "ait-melloul": "Ait Melloul",
  "ait-naamane": "Ait Naamane",
  "ait-ouallal": "Ait Ouallal",
  "ait-ourir": "Ait Ourir",
  "ait-sebaa-lajrouf": "Ait Sebaa Lajrouf",
  "al-bahraoyine": "Al Bahraoyine",
  "al-hoceima": "Al Hoceima",
  "al-machouar-stinia": "Al Machouar - Stinia",
  "al-majjatia-oulad-taleb": "Al Majjatia Oulad Taleb",
  "al-fida": "Al-Fida",
  allyene: "Allyene",
  alnif: "Alnif",
  alouidane: "Alouidane",
  ameur: "Ameur",
  amghras: "Amghras",
  amizmiz: "Amizmiz",
  amouguer: "Amouguer",
  amtar: "Amtar",
  anfa: "Anfa",
  annakhil: "Annakhil",
  aourir: "Aourir",
  "aquouass-briech": "Aquouass Briech",
  asni: "Asni",
  assilah: "Assilah",
  "assoukhour-assawda": "Assoukhour Assawda",
  atiamim: "Atiamim",
  atouabet: "Atouabet",
  ayacha: "Ayacha",
  azemmour: "Azemmour",
  azla: "Azla",
  azrou: "Azrou",
  "ain-chock": "Aîn-Chock",
  "ain-sebaa": "Aîn-Sebaâ",
  "bab-lamrissa": "Bab Lamrissa",
  "ben-ahmed": "Ben Ahmed",
  "ben-guerir": "Ben Guerir",
  "ben-m-sick": "Ben M'Sick",
  "ben-smim": "Ben Smim",
  benslimane: "Benslimane",
  berkane: "Berkane",
  berrechid: "Berrechid",
  bettana: "Bettana",
  "bni-guil": "Bni Guil",
  "bni-hassane": "Bni Hassane",
  "bni-makada": "Bni Makada",
  "bni-oual": "Bni Oual",
  "bni-yakhlef": "Bni Yakhlef",
  bouchaouene: "Bouchaouene",
  boughriba: "Boughriba",
  bouguargouh: "Bouguargouh",
  bouhmame: "Bouhmame",
  bouskoura: "Bouskoura",
  bouznika: "Bouznika",
  "beni-mellal": "Béni Mellal",
  "charf-mghogha": "Charf-Mghogha",
  "charf-souani": "Charf-Souani",
  charrate: "Charrate",
  chtouka: "Chtouka",
  "dar bouazza": "Dar Bouazza",
  "dar-bouazza": "Dar Bouazza",
  "dar-chaoui": "Dar Chaoui",
  "dcheira-el-jihadia": "Dcheira El Jihadia",
  deroua: "Deroua",
  dkhissa: "Dkhissa",
  drargua: "Drargua",
  echemmaia: "Echemmaia",
  "el-argoub": "El Argoub",
  "el-ganzra": "El Ganzra",
  "el-jadida": "El Jadida",
  "el-kelaa-des-sraghna": "El Kelaâ des Sraghna",
  "el-maarif": "El Maarif",
  "el-mansouria": "El Mansouria",
  "el-mariniyine": "El Mariniyine",
  "el-menzeh": "El Menzeh",
  "el-menzel": "El Menzel",
  "el-youssoufia": "El Youssoufia",
  errachidia: "Errachidia",
  essaouira: "Essaouira",
  fdalate: "Fdalate",
  fnideq: "Fnideq",
  "foum-oudi": "Foum Oudi",
  gueliz: "Gueliz",
  guelmim: "Guelmim",
  "hjar-ennhal": "HJAR ENNHAL",
  "had-al-gharbia": "Had Al Gharbia",
  "had-dra": "Had Dra",
  "had-soualem": "Had Soualem",
  haddada: "Haddada",
  haouza: "Haouza",
  haouzia: "Haouzia",
  harbil: "Harbil",
  harhoura: "Harhoura",
  hassan: "Hassan",
  "hay-mohammadi": "Hay Mohammadi",
  "hay-hassani": "Hay-Hassani",
  "houara-oulad-raho": "Houara Oulad Raho",
  hssaine: "Hssaine",
  ifrane: "Ifrane",
  "ighrem-n-ougdal": "Ighrem N'Ougdal",
  "imi-n-tayart": "Imi N'Tayart",
  inezgane: "Inezgane",
  isly: "Isly",
  issen: "Issen",
  jaqma: "Jaqma",
  jbilate: "Jbilate",
  jdour: "Jdour",
  "kasbat-ben-mchich": "Kasbat Ben Mchich",
  khatazakane: "Khatazakane",
  "khemis-sidi-yahya": "Khemis Sidi Yahya",
  khouribga: "Khouribga",
  khemisset: "Khémisset",
  "ksar-sghir": "Ksar Sghir",
  kenitra: "Kénitra",
  laaouama: "Laaouama",
  laghdira: "Laghdira",
  laghnimyine: "Laghnimyine",
  lahraouyine: "Lahraouyine",
  lakhnafif: "Lakhnafif",
  lakhoualqa: "Lakhoualqa",
  "lalla-takarkoust": "Lalla Takarkoust",
  lambarkiyine: "Lambarkiyine",
  "lamharza-essahel": "Lamharza Essahel",
  lamsabih: "Lamsabih",
  lamzoudia: "Lamzoudia",
  larache: "Larache",
  layayda: "Layayda",
  "lbir-jdid": "Lbir Jdid",
  loualidia: "Loualidia",
  loudaya: "Loudaya",
  "m-diq": "M'Diq",
  mrhassiyine: "MRhassiyine",
  maaziz: "Maaziz",
  mallalienne: "Mallalienne",
  "marrakech-medina": "Marrakech-Médina",
  martil: "Martil",
  mehdya: "Mehdya",
  mejji: "Mejji",
  meknes: "Meknès",
  mellila: "Mellila",
  "mers-el-kheir": "Mers El Kheir",
  "mers-sultan": "Mers-Sultan",
  meskala: "Meskala",
  mnasra: "Mnasra",
  mohammadia: "Mohammadia",
  "moualine-el-oued": "Moualine El Oued",
  "moulay-bousselham": "Moulay Bousselham",
  "moulay-rachid": "Moulay Rachid",
  "my-abdellah": "My Abdellah",
  "mzamza-janoubia": "Mzamza Janoubia",
  "mechouar-kasba": "Méchouar-Kasba",
  menara: "Ménara",
  nador: "Nador",
  "ouad-essafa": "Ouad Essafa",
  "ouahat-sidi-brahim": "Ouahat Sidi Brahim",
  "oued-laou": "Oued Laou",
  ouislane: "Ouislane",
  oujda: "Oujda",
  oukaimden: "Oukaimden",
  "oulad-aamer-tizmarine": "Oulad Aamer Tizmarine",
  "oulad-abbou": "Oulad Abbou",
  "oulad-azzouz": "Oulad Azzouz",
  "oulad-bouali-loued": "Oulad Bouali Loued",
  "oulad-hassoune": "Oulad Hassoune",
  "oulad-hassoune-hamri": "Oulad Hassoune Hamri",
  "oulad-rahmoune": "Oulad Rahmoune",
  "oulad-said": "Oulad Said",
  "oulad-salah": "Oulad Salah",
  "oulad-tayeb": "Oulad Tayeb",
  "oulad-yahya-louta": "Oulad Yahya Louta",
  "oulad-ziyane": "Oulad Ziyane",
  "ouled-dlim": "Ouled Dlim",
  "ouled-hcine": "Ouled Hcine",
  "ouled-slama": "Ouled Slama",
  ourika: "Ourika",
  "ras-el-ain": "Ras El Ain",
  riah: "Riah",
  rima: "Rima",
  rmilat: "Rmilat",
  saada: "Saada",
  sabbah: "Sabbah",
  safi: "Safi",
  "sahel-oulad-h-riz": "Sahel Oulad H'Riz",
  saidia: "Saidia",
  saiss: "Saiss",
  sbata: "Sbata",
  sefrou: "Sefrou",
  settat: "Settat",
  shoul: "Shoul",
  "sidi-abdallah-ghiat": "Sidi Abdallah Ghiat",
  "sidi-abdelkhaleq": "Sidi Abdelkhaleq",
  "sidi-al-kamel": "Sidi Al Kamel",
  "sidi-ali-ben-hamdouche": "Sidi Ali Ben Hamdouche",
  "sidi-allal-el-bahraoui": "Sidi Allal El Bahraoui",
  "sidi-allal-tazi": "Sidi Allal Tazi",
  "sidi-belyout": "Sidi Belyout",
  "sidi-bennour": "Sidi Bennour",
  "sidi-bernoussi": "Sidi Bernoussi",
  "sidi-bibi": "Sidi Bibi",
  "sidi-bouknadel": "Sidi Bouknadel",
  "sidi-el-aidi": "Sidi El Aidi",
  "sidi-el-jazouli": "Sidi El Jazouli",
  "sidi-el-mekki": "Sidi El Mekki",
  "sidi-ettiji": "Sidi Ettiji",
  "sidi-hajjaj-oued-hassar": "Sidi Hajjaj Oued Hassar",
  "sidi-hmad-ou-hamed": "Sidi Hmad Ou Hamed",
  "sidi-jaber": "Sidi Jaber",
  "sidi-kacem": "Sidi Kacem",
  "sidi-m-hamed-ou-marzouq": "Sidi M'Hamed Ou Marzouq",
  "sidi-mohamed-lahmar": "Sidi Mohamed Lahmar",
  "sidi-moumen": "Sidi Moumen",
  "sidi-moussa-ben-ali": "Sidi Moussa Ben Ali",
  "sidi-othmane": "Sidi Othmane",
  "sidi-rahal-chatai": "Sidi Rahal Chatai",
  "sidi-slimane": "Sidi Slimane",
  "sidi-smail": "Sidi Smail",
  "sidi-taibi": "Sidi Taibi",
  "sidi-yahya-zaer": "Sidi Yahya Zaer",
  "sidi-youssef-ben-ali": "Sidi Youssef Ben Ali",
  skhirate: "Skhirate",
  "skhour-rehamna": "Skhour Rehamna",
  "soualem-trifiya": "Soualem Trifiya",
  souissi: "Souissi",
  "souk-tlet-el-gharb": "Souk Tlet El Gharb",
  "sti-fadma": "Sti Fadma",
  tabriquet: "Tabriquet",
  tafoughalt: "Tafoughalt",
  taghazout: "Taghazout",
  tahannaout: "Tahannaout",
  "talat-n-yaaqoub": "Talat N'Yaaqoub",
  tamazouzte: "Tamazouzte",
  tameslohte: "Tameslohte",
  tamri: "Tamri",
  "tanger-medina": "Tanger-Médina",
  tarmigt: "Tarmigt",
  taroudannt: "Taroudannt",
  tassoultante: "Tassoultante",
  taza: "Taza",
  temsia: "Temsia",
  ternata: "Ternata",
  tiflet: "Tiflet",
  tilougguite: "Tilougguite",
  "tit-mellil": "Tit Mellil",
  tiznit: "Tiznit",
  tlauh: "Tlauh",
  "tnine-aglou": "Tnine Aglou",
  touarga: "Touarga",
  toughmart: "Toughmart",
  temara: "Témara",
  tetouan: "Tétouan",
  "yacoub-el-mansour": "Yacoub El Mansour",
  "zaouiat-sidi-ben-hamdoun": "Zaouiat Sidi Ben Hamdoun",
  ziaida: "Ziaida",
  zouagha: "Zouagha",
  rabat: "rabat",
};
export const slug_to_province = {
  // OPEN PROVINCES
  casablanca: "Casablanca",
  rabat: "Rabat",
  tanger: "Tanger-Assilah",
  marrakech: "Marrakech",
  mohammadia: "Mohammadia",
  agadir: "Agadir-Ida -Ou-Tanane",
  bouskoura: "Nouaceur",
  temara: "Skhirate- Témara",
  "el-jadida": "El Jadida",
  kenitra: "Kénitra",

  //CLOSE PROVINCE
  nouaceur: "Nouaceur",
  "inezgane-ait-melloul": "Inezgane- Ait Melloul",
  zagora: "Zagora",
  midelt: "Midelt",
  benslimane: "Benslimane",
  rehamna: "Rehamna",
  nador: "Nador",
  khemisset: "Khémisset",
  "al-hoceima": "Al Hoceima",
  settat: "Settat",
  tinghir: "Tinghir",
  "chtouka-ait-baha": "Chtouka- Ait Baha",
  meknes: "Meknès",
  fes: "Fès",
  tetouan: "Tétouan",
  azilal: "Azilal",
  chefchaouen: "Chefchaouen",
  tiznit: "Tiznit",
  essaouira: "Essaouira",
  "fahs-anjra-": "Fahs-Anjra        ",
  ifrane: "Ifrane",
  guelmim: "Guelmim",
  taroudannt: "Taroudannt",
  safi: "Safi",
  berkane: "Berkane",
  mediouna: "Médiouna",
  "al-haouz": "Al  Haouz",
  "el-kelaa-des-sraghna": "El Kelâa des  Sraghna",
  "es-semara": "Es-Semara",
  "el-hajeb": "El  Hajeb",
  ouarzazate: "Ouarzazate",
  guercif: "Guercif",
  khouribga: "Khouribga",
  youssoufia: "Youssoufia",
  "beni-mellal": "Béni Mellal",
  larache: "Larache",
  sale: "Salé",
  figuig: "Figuig",
  errachidia: "Errachidia",
  taza: "Taza",
  sefrou: "Sefrou",
  "oued-ed-dahab": "Oued Ed-Dahab",
  "m-diq-fnideq": "M'Diq-Fnideq",
  "sidi-bennour": "Sidi Bennour",
  chichaoua: "Chichaoua",
  "oujda-angad": "Oujda-Angad",
  "sidi-slimane": "Sidi Slimane",
  "sidi-kacem": "Sidi Kacem",
  berrechid: "Berrechid",
};
export const open_province = [
  "Casablanca",
  "Rabat",
  "Tanger-Assilah",
  "Marrakech",
  "Mohammadia",
  "Agadir-Ida -Ou-Tanane",
  "Nouaceur",
  "Skhirate- Témara",
  "El Jadida",
  "Kénitra",
];

export const open_province_slug = [
  "casablanca",
  "rabat",
  "tanger",
  "marrakech",
  "mohammedia",
  "agadir",
  "bouskoura",
  "temara",
  "el-jadida",
  "kenitra",
];
export const province_to_slug = {
  //OPEN
  Casablanca: "casablanca",
  Rabat: "rabat",
  "Tanger-Assilah": "tanger",
  Marrakech: "marrakech",
  Mohammadia: "mohammadia",
  "Agadir-Ida -Ou-Tanane": "agadir",
  Nouaceur: "bouskoura",
  "Skhirate- Témara": "temara",
  "El Jadida": "el-jadida",
  Kénitra: "kenitra",

  //CLOSE
  "Inezgane- Ait Melloul": "inezgane-ait-melloul",
  Zagora: "zagora",
  Midelt: "midelt",
  Benslimane: "benslimane",
  Rehamna: "rehamna",
  Nador: "nador",
  Khémisset: "khemisset",
  "Al Hoceima": "al-hoceima",
  Settat: "settat",
  Tinghir: "tinghir",
  "Chtouka- Ait Baha": "chtouka-ait-baha",
  Meknès: "meknes",
  Fès: "fes",
  Tétouan: "tetouan",
  Azilal: "azilal",
  Chefchaouen: "chefchaouen",
  Tiznit: "tiznit",
  Essaouira: "essaouira",
  "Fahs-Anjra        ": "fahs-anjra-",
  Ifrane: "ifrane",
  Guelmim: "guelmim",
  Taroudannt: "taroudannt",
  Safi: "safi",
  Berkane: "berkane",
  Médiouna: "mediouna",
  "Al  Haouz": "al-haouz",
  "El Kelâa des  Sraghna": "el-kelaa-des-sraghna",
  "Es-Semara": "es-semara",
  "El  Hajeb": "el-hajeb",
  Ouarzazate: "ouarzazate",
  Guercif: "guercif",
  Khouribga: "khouribga",
  Youssoufia: "youssoufia",
  "Béni Mellal": "beni-mellal",
  Larache: "larache",
  Salé: "sale",
  Figuig: "figuig",
  Errachidia: "errachidia",
  Taza: "taza",
  Sefrou: "sefrou",
  "Oued Ed-Dahab": "oued-ed-dahab",
  "M'Diq-Fnideq": "m-diq-fnideq",
  "Sidi Bennour": "sidi-bennour",
  Chichaoua: "chichaoua",
  "Oujda-Angad": "oujda-angad",
  "Sidi Slimane": "sidi-slimane",
  "Sidi Kacem": "sidi-kacem",
  Berrechid: "berrechid",
};
export const slug_to_quartier = (quartier) => {
  switch (quartier) {
    case "maarif":
      return "Maârif";
    case "ain-sebaa":
      return "Aïn Sebaâ";
    case "ain-chock":
      return "Ain Chock";
    case "belvedere":
      return "Belvédère";
    case "autre":
      return "Autre";
    case "beausejour":
      return "Beauséjour";
    case "ain-diab":
      return "Ain Diab";
    case "green-town-bouskoura":
      return "Ville Verte";
    case "hassan-centre-ville":
      return "Hassan - Centre Ville";
    case "l-ocean":
      return "L'Ocean";
    case "ennakhil-palmeraie":
      return "Palmeraie";
    case "cil":
      return "CIL";
    case "anfa-superieur":
      return "Anfa Supérieur";
    case "les-hopitaux":
      return "Les Hôpitaux";
    case "liberte":
      return "Liberté";
    case "ville-haute":
      return "Ville Haute";
    case "gueliz":
      return "Guéliz";
    case "route-d-ourika":
      return "Route d'ourika";
    case "route-de-fes":
      return "Route de Fès";
    case "rahba-kedima":
      return "Rahba Kedima";
    case "moulay-youssef":
      return "Moulay Youssef";
    case "ain-diab":
      return "Ain Diab";
    case "dar-bouazza":
      return "Dar Bouazza";
    case "bouskoura-ville":
      return "Bouskoura";
    default:
      return capitaliserPremiereLettre(quartier.replace(/-/g, " "));
  }
};

export const prix_filters = [
  "1000-dh",
  "pas-cher",
  "appartement-pas-cher",
  "25-millions",
  "2500-dh",
  "2000-dh",
  "3000-dh",
  "5000-dh",
  "haut-standing",
  "haut-standing-luxe",
  "economique",
  "moyen-standing",
  "2eme-main",
];
export const slug_prix_max_to_filter = {
  "25-millions": 350000,
  "1000-dh": 1000,
  "2000-dh": 2000,
  "3000-dh": 3000,
  "5000-dh": 5000,
  "appartement-pas-cher": 500000,
  economique: 300000,
  "pas-cher": 5000,
  "2500-dh": 2500,
  "moyen-standing": 1000000,
};
export const slug_prix_min_to_filter = {
  "haut-standing": 10000,
  "haut-standing-luxe": 1000000,
  "moyen-standing": 300000,
  "25-millions": 150000,
};

export const slug_to_title = {
  "1000-dh": " pour moins de 1000 MAD/Mois",
  "2500-dh": " pour moins de 2500 MAD/Mois",
  "2000-dh": " pour moins de 2000 MAD/Mois",
  "3000-dh": " pour moins de 3000 MAD/Mois",
  "5000-dh": " pour moins de 5000 MAD/Mois",
  economique: " pour moins de 300 000 MAD",
  "moyen-standing": " de moyen standing",
  "haut-standing": " de haut standing",
  "haut-standing-luxe": " de luxe",
  "2eme-main": " de deuxième main",
  "25-millions": " à 25 millions de centimes",
  "appartement-pas-cher": " pas chers",
  "pas-cher": " pas chers",
  "green-town-bouskoura": " - Bouskoura",
};

export const filtre_typologie = {
  "location-studios": 0,
  "vente-studios": 0,
};
export const slug_to_sort = {
  "25-millions": "price_desc",
};

export type BienTypes =
  | "Appartement"
  | "Villa"
  | "Maison"
  | "Bureau"
  | "Terrain"
  | "Commerce"
  | "Villas"
  | "Appartements"
  | "Lots de terrains"
  | "Local Industriel"
  | "Riad";
export type BienTypesfromUrl = string;

export const PAGE_SIZE = 25;
