import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { createWrapper, HYDRATE } from "next-redux-wrapper";
import annonceDisplayReducer from "./slices/annonceDisplayReducer.jsx";
// import { firebaseReducer } from "react-redux-firebase";
import authReducer from "./slices/authReducer";
import carteprixReducer from "./slices/carteprixReducer.jsx";
import annuairesV2Reducer from "./slices/v2/Annuaire";
import calculezMensualiteV2Reducer from "./slices/v2/CaclulezMensualite";
import CalculezBudgetV2Reducer from "./slices/v2/CalculezBudget";
import selectDemandUnCredit from "./slices/v2/DemandeUnCredit";
import EstimationV2Reducer from "./slices/v2/EstimationReducer";
import listingsV2Reducer from "./slices/v2/ListingReducer";
import newEspaceProReducer from "./slices/v2/ProSpace";
import userSpaceReducer from "./slices/v2/UserSpace";
import UrlTrack from "./slices/newLps/FillDarian";
import DefaultSearchListTrack from "./slices/SearchBar/DefaultList";

const rrfConfig = { userProfile: "users" };
const config = {
  userProfile: "users",
  updateProfileOnLogin: true,
};

const combinedReducer = combineReducers({
  userSpace: userSpaceReducer,
  proSpaceV2: newEspaceProReducer,
  auth: authReducer,
  annonceDisplay: annonceDisplayReducer,
  carteprix: carteprixReducer,
  listingsV2: listingsV2Reducer,
  annuairesV2: annuairesV2Reducer,
  EstimationV2: EstimationV2Reducer,
  calculezBudgetV2: CalculezBudgetV2Reducer,
  calculezMensualiteV2: calculezMensualiteV2Reducer,
  selectDemandUnCredit: selectDemandUnCredit,
  UrlTrack: UrlTrack,
  DefaultSearchListTrack: DefaultSearchListTrack,
});
const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    };

    return nextState;
  } else {
    return combinedReducer(state, action);
  }
};
const makeStore = () =>
  configureStore({
    reducer: reducer,
    devTools: false,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });

export const wrapper = createWrapper(makeStore);
