import { useEffect, useRef, useState } from "react";
// import { getAnalytics, setUserId } from "firebase/analytics";
import { useDispatch, useSelector } from "react-redux";

// import app from "Config/FirebaseConfig";
// import { useUserAgent } from "next-useragent";
import {
  setAuthenticatedPro,
  setAuthenticated,
  updateUser,
  signInR,
  setUID,
  setEmail,
} from "redux/slices/authReducer";
import api_call_v2 from "utils/API_CALL/api_call_v2";

export default function AuthHelperContainer({ userSSR }: { userSSR: any }) {
  const userRedux = useSelector((store: any) => store?.auth?.user);

  const [user, setUser] = useState(userSSR);

  const dispatch = useDispatch();

  const addAnalyticID = async (
    clientId: string,
    screenResolution: string,
    ua: any
  ) => {
    api_call_v2(
      undefined,
      "post",
      "addUserAnalyticId",
      { clientId, screenResolution, ua },
      "cloud_functions",
      false
    );
  };

  useEffect(() => {
    if (userSSR) {
      if (userSSR?.isPro) {
        dispatch(setAuthenticatedPro({}));
      }
      dispatch(setUID({ data: userSSR._id }));
      dispatch(setAuthenticated({}));
      dispatch(setEmail({ data: userSSR }));
      dispatch(updateUser({ data: userSSR }));
      dispatch(signInR({ data: userSSR }));
    }
  }, [userSSR]);
  const userRef = useRef(user);

  useEffect(() => {
    if (JSON.stringify(userRef.current) == JSON.stringify(user)) {
      return;
    } else {
      userRef.current = user;
      if (user && user._id) {
      }
    }
  }, [user]);

  useEffect(() => {
    if (userRedux && userRedux._id) {
      setUser(userRedux);
    }
  }, [userRedux]);

  return <></>;
}
