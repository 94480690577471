import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_SEARCH_LIMIT } from "pagesHelpers/AnnuairePagesHelper";
import { createSelector } from "reselect";

const initialState: any = {
  loading: false,
  searchPayload: {
    sort_by: "annonces",
    type: [],
    geometry_label: "",
    geometry_value: "",
    limit: DEFAULT_SEARCH_LIMIT,
    page: 0,
    lat: 0,
    lng: 0,
    adresse: 0,
    specialite: [],
    sallesDeBain: [],
  },
  totalPages: 0,
  agences_annuaires: {
    agences: [],
    totalItems: 0,
  },
  agence_immobiliere: {
    agence: {},
    annonces: {
      data: [],
      page: 0,
      totalItems: 0,
      totalPages: 0,
    },
    ventes: {
      data: [],
      page: 0,
      totalItems: 0,
      totalPages: 0,
    },
    avis: {
      data: [],
      page: 0,
      totalItems: 0,
      totalPages: 0,
    },
    rating: null,
  },
};

const slice = createSlice({
  name: "annuairesV2",
  initialState,
  reducers: {
    setSearchPayload(state, action) {
      return {
        ...state,
        searchPayload: {
          ...state.searchPayload,
          ...action.payload.data,
        },
      };
    },
    setAgences(state, action) {
      return {
        ...state,
        agences_annuaires: {
          agences: action.payload.agences,
          totalItems: action.payload.totalItems,
        },
        totalPages:
          parseInt(
            (action.payload.totalItems / state.searchPayload.limit).toString()
          ) ==
          action.payload.totalItems / state.searchPayload.limit
            ? action.payload.totalItems / state.searchPayload.limit
            : parseInt(
                (
                  action.payload.totalItems / state.searchPayload.limit
                ).toString()
              ) + 1,
      };
    },
    setAgencesAndPayload(state, action) {
      return {
        ...state,
        agences_annuaires: {
          agences: action.payload.agences,
          totalItems: action.payload.totalItems,
        },
        totalPages:
          parseInt(
            (action.payload.totalItems / action.payload.limit).toString()
          ) ==
          action.payload.totalItems / action.payload.limit
            ? action.payload.totalItems / action.payload.limit
            : parseInt(
                (action.payload.totalItems / action.payload.limit).toString()
              ) + 1,
        searchPayload: {
          sort_by: action.payload.sort_by,
          geometry_label: action.payload.geometry_label,
          geometry_value: action.payload.geometry_value,
          type: action.payload.type,
          limit: action.payload.limit,
          page: action.payload.page,
        },
      };
    },
    setLoading(state, action) {
      return {
        ...state,
        loading: action.payload.data,
      };
    },
    setAgenceImmobiliere(state, action) {
      return {
        ...state,
        agence_immobiliere: action.payload,
      };
    },
    setAnnonces(state, action) {
      return {
        ...state,
        agence_immobiliere: {
          ...state.agence_immobiliere,
          annonces: action.payload,
        },
      };
    },
    setVentes(state, action) {
      return {
        ...state,
        agence_immobiliere: {
          ...state.agence_immobiliere,
          ventes: action.payload,
        },
      };
    },
    setAvis(state, action) {
      return {
        ...state,
        agence_immobiliere: {
          ...state.agence_immobiliere,
          avis: action.payload,
        },
      };
    },
  },
});
const annuairesV2Domain = (state: any) => state.annuairesV2 || initialState;

const searchPayloadSelector = createSelector(
  [annuairesV2Domain],
  (substate) => substate.searchPayload
);

const agencesSelector = createSelector(
  [annuairesV2Domain],
  (substate) => substate.agences_annuaires
);
const loadingSelector = createSelector(
  [annuairesV2Domain],
  (substate) => substate.loading
);
const totalPagesSelector = createSelector(
  [annuairesV2Domain],
  (substate) => substate.totalPages
);

const agenceImmobiliereSelector = createSelector(
  [annuairesV2Domain],
  (substate) => substate.agence_immobiliere
);

export const selectors = {
  searchPayloadSelector,
  agencesSelector,
  loadingSelector,
  totalPagesSelector,
  agenceImmobiliereSelector,
};

export const actions = {
  ...slice.actions,
};

export default slice.reducer;
