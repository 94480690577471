export function isEmail(email) {
  let re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

// export function upperCaseFirstLetter(string) {
//   let ret = "";
//   if (string) {
//     string.split(" ").map((el) => {
//
//       ret = ret + el.charAt(0).toUpperCase() + el.slice(1) + " ";
//     });
//     return ret.slice(0, -1);
//   } else {
//     return "";
//   }
// }
export function capitaliserPremiereLettre(chaine) {

  return chaine.toLowerCase().split(' ').map(mot => mot.replace(mot[0], mot[0].toUpperCase())).join(' ');
}
export function upperCaseFirstLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return "";
  }
}

export function lowerCaseAllWordsExceptFirstLetters(string) {
  return string.replace(/\S*/g, function (word) {
    return (
      word.charAt(0) +
      word
        .slice(1)
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .trim()
        .replace(/ /g, "-")
    );
  });
}



export function isPhoneNumber(value) {
  if (!value) {
    return false
  }
  else {
    return value === "+"
      ? false
      : value.match(/\d/g).length > 10 && value.match(/\d/g).length < 14;
  }
}

export function ordinal_suffix_of_trimestre(i) {
  if (i !== "Immédiate") {
    var j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "er trimestre";
    }
    if (j === 2 && k !== 12) {
      return i + "ème trimestre";
    }
    if (j === 3 && k !== 13) {
      return i + "ème trimestre";
    }
    return i + "ème trimestre";
  } else {
    return i;
  }
}

export function createConsistance(data) {
  switch (data.type) {
    case "Appartement":
    case "appartement":
      switch (data.typologie) {
        case 0:
          return "Appartement studio";
        case 1:
          return "Appartement 1 chambre";
        default:
          return `Appartement ${data.typologie} chambres`;
      }

    case "Bureau":
    case "bureau":
    case "Commerce":
    case "commerce":
      switch (data.typologie) {
        case 1:
          return `${upperCaseFirstLetter(data.type)} 1 pièce`;
        default:
          return `${upperCaseFirstLetter(data.type)} ${data.typologie} pièces`;
      }

    case "Villa":
    case "villa":
    case "Maison":
    case "maison":
      switch (data.typologie) {
        case 1:
          return `${upperCaseFirstLetter(data.type)} 1 chambre`;
        default:
          return `${upperCaseFirstLetter(data.type)} ${data.typologie
            } chambres`;
      }

    case "Terrain":
    case "terrain":
      return "Terrain nu";

    default:
      return "";
  }
}

export default null;
