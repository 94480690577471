// CREATE ENV VARIABLE INSTEAD OF THIS
const secret = process.env.NEXT_PUBLIC_CRYPTO_SECRET
// import CryptoJS from "crypto-js"
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

export const encrypt = (content, password = secret) =>
    AES.encrypt(JSON.stringify({ content }), password).toString();
export const decrypt = (crypted, password = secret) => JSON.parse(AES.decrypt(crypted, password).toString(Utf8)).content

export default secret