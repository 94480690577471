import React from 'react'

function Facebook() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.1999 15.5932C31.1453 7.25706 24.6239 0.418041 16.3486 0.0183404C8.07328 -0.38136 0.930934 5.7977 0.0829707 14.0902C-0.764993 22.3828 4.97725 29.8958 13.1601 31.2V19.9882H9.20448V15.5035H13.1601V12.14C13.1601 8.1822 15.4889 5.9959 19.0545 5.9959C20.2233 6.01043 21.3893 6.11538 22.5421 6.30983V10.1891H20.5811C19.9874 10.1033 19.3844 10.2619 18.9084 10.629C18.4324 10.9961 18.1236 11.5408 18.0517 12.14C18.0295 12.3074 18.0295 12.4771 18.0517 12.6445V15.5035H22.375L21.6842 19.9882H18.0405V31.2C25.6433 29.9598 31.2225 23.343 31.1999 15.5932Z" fill="#757E83" />
        </svg>

    )
}

export default Facebook
