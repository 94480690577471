import React from "react";

function index() {
  return (
    <svg
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.09562 8.43474L3.73572 9.31629H2.4444L4.57549 4.53516H5.86748L7.95005 9.31629H6.60885L6.25569 8.43541H4.09562V8.43474ZM5.18273 5.74426L4.46293 7.51342H5.88838L5.18273 5.74426Z"
        fill="#051011"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.47143 0.93457H14.9533C15.971 0.93457 16.8 1.73659 16.8 2.72194V11.2786C16.8 12.2647 15.971 13.066 14.9533 13.066H2.47143C1.45374 13.066 0.624756 12.264 0.624756 11.2786V2.72194C0.624756 1.73659 1.45306 0.93457 2.47143 0.93457ZM2.47143 12.1272H8.71238V1.87408H2.47143C1.98819 1.87408 1.59527 2.2542 1.59527 2.72194V11.2793C1.59527 11.7464 1.98819 12.1272 2.47143 12.1272ZM14.2389 4.49582H13.1606C12.9988 4.49582 12.8458 4.51469 12.7023 4.55176C11.8753 4.86044 11.4622 5.41175 11.4622 6.20703C11.4622 6.56221 11.5431 6.87089 11.7048 7.13239V7.16069C11.667 7.15992 11.6297 7.16947 11.597 7.18833C11.1838 7.41276 10.9769 7.74031 10.9769 8.1703C10.9769 8.3152 10.9951 8.45539 11.0308 8.59086C11.2061 9.09633 11.5383 9.3484 12.0283 9.3484H13.6458L13.6997 9.32009C13.4349 8.77822 13.1471 8.50661 12.8371 8.50661H11.9744C11.8443 8.50661 11.754 8.43247 11.7048 8.28218C11.723 8.05842 11.8126 7.94587 11.9744 7.94587H14.185C14.2207 7.94587 14.2389 7.927 14.2389 7.88993V7.16069C14.2389 7.12767 14.2207 7.1088 14.185 7.10475H12.9179C12.5493 7.10475 12.3067 6.82371 12.1901 6.26229C12.1901 6.16929 12.1988 6.12278 12.217 6.12278C12.217 5.73929 12.4684 5.47779 12.9719 5.33693H13.4571C13.7449 5.33693 14.0233 5.07543 14.2928 4.55176C14.2928 4.51941 14.2746 4.50054 14.2389 4.49582Z"
        fill="#051011"
      />
    </svg>
  );
}

export default index;
