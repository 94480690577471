import useTranslation from "next-translate/useTranslation";

import { province_to_slug } from "pagesHelpers/ListingPagesHepler/SlugTransformation";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import formatUrl from "utils/FormatUrl";
import { selectors as ListingsReducerSelectors } from "../../../../redux/slices/v2/ListingReducer";
export const linkSearchItems = [
  "surfacemin",
  "surfacemax",
  "prixmin",
  "prixmax",
  "typologie",
  "type",
  "province",
  "commune",
  "quartier",
  "zoom",
  "lat",
  "lng",
  "address",
  "transaction_type",
  "page",
  "exclusivite",
  "sort",
  "price_cut",
  "virtual_tour",
  "vendu",
];
export const linkSearchItemsListing = [
  "surfacemin",
  "surfacemax",
  "prixmin",
  "prixmax",
  "typologie",
  "type",
  "province",
  "commune",
  "quartier",
  "zoom",
  "lat",
  "lng",
  "address",
  "page",
  "transaction_type",
  "exclusivite",
  "sort",
  "price_cut",
  "virtual_tour",
  "vendu",
];
export const buildUrl = (
  currentListing,
  initialSearchPayloadSelector,
  searchPayload: any = {},
  mapVsList,
  currentUrl = null,
  page = null
) => {
  let copySP = {};
  if (searchPayload.bounds) {
    copySP["bounds"] = [
      searchPayload.bounds._sw.lat,
      searchPayload.bounds._sw.lng,
      searchPayload.bounds._ne.lat,
      searchPayload.bounds._ne.lng,
    ];
  }
  if (searchPayload.exclusivite) {
    copySP["exclusivite"] = searchPayload.exclusivite;
  }
  linkSearchItems.map((item) => {
    if (
      typeof searchPayload[item] == "string" ||
      (searchPayload[item] && searchPayload[item].length) ||
      typeof searchPayload[item] == "number" ||
      typeof searchPayload[item] == "boolean"
    ) {
      copySP[item] = searchPayload[item];
    }
  });
  copySP["commune"] = norm(searchPayload["commune"]);
  copySP["province"] = norm(searchPayload["province"]);
  let u: any = new URLSearchParams(copySP);
  if (!u.page) {
    delete u["page"];
  } else {
    if (isNaN(parseInt(u.page.toString()))) {
      delete u["page"];
    }
  }
  u = u.toString();

  try {
    if (currentListing) {
      return buildListingUrl(currentListing);
    } else {
      let url = mapVsList == "MAP" ? "map.htm" : "list.htm";
      let initialCopy = { ...initialSearchPayloadSelector };
      // delete initialCopy["page"];
      delete initialCopy["no_trigger_search"];
      let spCopy = { ...searchPayload };
      delete spCopy["no_trigger_search"];
      // delete spCopy["page"];

      if (JSON.stringify(initialCopy) == JSON.stringify(spCopy)) {
        if (currentUrl) {
          //

          url = currentUrl.split("?page")[0];
        } else {
          if (typeof window !== undefined) {
            url = window.location.pathname.includes("annonces")
              ? mapVsList == "MAP"
                ? "map.htm"
                : "list.htm"
              : window.location.pathname;
          } else {
            url = "list.htm";
          }
        }
        if (
          (!u.page || isNaN(parseInt(u.page.toString()))) &&
          (!page || isNaN(parseInt(page.toString())))
        ) {
          return url.split("?page=0")[0];
        } else {
          return `${url}${u ? (page ? `?page=${page}` : "") : ""}`.split(
            "?page=0"
          )[0];
        }
      } else {
        return `${url}${u ? `?${u}` : ""}`.split("?page=0")[0];
      }
    }
  } catch (e) { }
};

const type_to_url = {
  Appartement: "appartements",
  Villa: "villas",
  Maison: "maisons",
  Riad: "riads",
  Bureau: "bureaux",
  Terrain: "terrains",
  Commerce: "locaux-magasins",
  "Local Industriel": "locaux-industriels",
};
export const norm = (text) => {
  if (text) {
    return text
      .toLowerCase()
      .normalize("NFD")
      .replace(/&/g, " ")
      .replace(/-/g, " ")
      .replace(/_/g, " ")
      .replace(/,/g, " ")
      .replace("'", "-")
      .replace(".", " ")
      .replace(/;/g, " ")
      .replace(/:/g, " ")
      .replace(/'/g, " ")
      .replace(/"/g, " ")
      .replace("(", "-")
      .replace(")", "")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/ /g, "-")
      .replace(/--/g, "-")
      .replace(/--/g, "-")
      .replace(/--/g, "-")
      .replace(/-'/g, "")
      .replace("-ida-ou-tanane", "")
      .replace("-ida--ou-tanane", "")
      .replace("skhirate-temara", "temara")
      .replace("skhirate--temara", "temara")
      .replace("tanger-assilah", "tanger")
      .replace("nouaceur", "bouskoura");
  } else {
    return "";
  }
};
export const buildListingUrl = (
  listing,
  lang = "fr",
  initialSeachPayload = null,
  searchPayload = null,
  viewType = null,
  forLinkSEO = false
) => {
  let u = null;

  if (searchPayload && initialSeachPayload != searchPayload) {
    const {
      region,
      controlBy,
      meublee,
      no_trigger_search,
      ...filteredPayload
    } = searchPayload;

    let copySP: any = {};
    if (viewType == "MAP") {
      copySP.view = "map";
    }
    if (filteredPayload.bounds) {
      copySP["bounds"] = [
        filteredPayload.bounds._sw.lat,
        filteredPayload.bounds._sw.lng,
        filteredPayload.bounds._ne.lat,
        filteredPayload.bounds._ne.lng,
      ];
    }
    if (filteredPayload.exclusivite) {
      copySP["exclusivite"] = filteredPayload.exclusivite;
    }
    if (filteredPayload.virtual_tour) {
      copySP["virtual_tour"] = filteredPayload.virtual_tour;
    }
    if (typeof window !== "undefined") {
      if (window.cartedesannonces) {
        if (window.cartedesannonces.getBounds) {
          let boundsref = window.cartedesannonces.getBounds();
          copySP["lat"] = window.cartedesannonces.getCenter().lat;
          copySP["lng"] = window.cartedesannonces.getCenter().lng;
          copySP["zoom"] = window.cartedesannonces.getZoom();
          copySP["bounds"] = [
            boundsref._sw.lat,
            boundsref._sw.lng,
            boundsref._ne.lat,
            boundsref._ne.lng,
          ];
        }
      }
    }

    linkSearchItemsListing.map((item) => {
      if (filteredPayload[item]) {
        copySP[item] = filteredPayload[item];
      }
    });
    copySP["commune"] = norm(filteredPayload["commune"]);

    if (filteredPayload["province"]) {
      copySP["province"] = province_to_slug[filteredPayload["province"]];
    }
    u = new URLSearchParams(copySP).toString();
  }

  return formatUrl(
    `/annonces/immo-${norm(listing.province)
      .replace("-ida-ou-tanane", "")
      .replace("-ida--ou-tanane", "")
      .replace("skhirate-temara", "temara")
      .replace("skhirate--temara", "temara")
      .replace("tanger-assilah", "tanger")
      .replace("nouaceur", "bouskoura")}/${listing.transaction_type == "Location" ? "location-" : "vente-"
    }${type_to_url[listing.type]}/${listing.quartier ? norm(listing.quartier) : norm(listing.commune)
    }/${norm(listing.slug?.split("-").slice(-1)[0])}${u && !forLinkSEO ? `?${u}` : ""
    }`,
    lang
    // ${
    //   listing.typologie && !isNaN(parseInt(listing.typologie.toString()))
    //     ? `${listing.typologie}-chambres-`
    //     : listing.surface && !isNaN(parseInt(listing.surface.toString()))
    //     ? `${listing.surface}-m2-`
    //     : ""
    // }${listing.reference ? `${norm(listing.reference)}-` : ""}
  );
};

export default function ListingsRouterUrlHelper() {
  const { lang } = useTranslation("");
  const searchPayload = useSelector(
    ListingsReducerSelectors.searchPayloadSelector
  );
  const initialSearchPayloadSSR = useSelector(
    ListingsReducerSelectors.initialSearchPayloadSelector
  );
  const currentListing = useSelector(
    ListingsReducerSelectors.currentListingSelactor
  );

  const region = searchPayload?.region;
  const province = searchPayload?.province;
  const commune = searchPayload?.commune;
  const transaction_type = searchPayload?.transaction_type;
  const bienTypes = searchPayload?.type;
  const slug = currentListing?.slug;

  const mapVsList = useSelector(ListingsReducerSelectors.mapVsListSelactor);

  const firstUpdate = useRef(true);
  const firstUpdateBis = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    //  else if (firstUpdateBis.current) {
    //   firstUpdateBis.current = false;

    //   return;
    // }
    // else if (
    //   region ||
    //   province ||
    //   commune ||
    //   transaction_type ||
    //   bienTypes ||
    //   slug
    // ) {
    const url = buildUrl(
      currentListing,
      initialSearchPayloadSSR,
      searchPayload,
      mapVsList,
      null,
      searchPayload.page
    );
    let params = new URLSearchParams(window.location.search);

    window.history.pushState(
      {},
      "",
      // `${url}${params.toString() ? `?${params.toString()}` : ""}`
      formatUrl(`${url}`, lang)
    );

    // if (process.env.NEXT_PUBLIC_DATADOG_ENABLED) {
  }, [
    region,
    province,
    commune,
    transaction_type,
    slug,
    bienTypes,
    currentListing,
    searchPayload,
  ]);
  const firstUpdate2 = useRef(true);
  const firstUpdate2Bis = useRef(true);

  return <></>;
}
