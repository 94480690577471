import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
  firstEntrance: false,
  todisplay: "INFO",
  lat: 33.56156827300742,
  lng: -7.63289972059,
  prix: 0,
  prix_villa: 0,
  province: "",
  zoom: 10,
  areaLabel: "",
  agencesVisibility: "visible",
  ventesVisibility: "visible",
  address: "",
  addressChangedFlag: false,
  isloading: true,
  layer: "",
  legend: [
    4011, 5111, 6211, 7311, 8411, 9511, 10611, 11711, 12811, 13911, 15011,
    16111,
  ],
  provinces: [],
  communes: [],

  //DEV MAROC
  region: "",

  quartier: "",
  commune: "",
  prices: {
    _id: "",
    prix_appartement: 0,
    prix_villa: 0,
    province: "",
    region: "",
    region_ar: "",
    province_ar: "",
    province: "",
    commune: "",
    commune_ar: "",
    quartier: "",
    indice_confiance_appartement: 0,
    indice_confiance_villa: 0,
    lat: 0,
    lng: 0,
  },
  annonces: [],
  agences: [],
  transaction_popup: false,
  agence_popup: false,
  transaction_popup_data: {},
  agence_popup_data: {},
  transaction_full_display_data: {},
  transaction_full_display: false,
  plan: null,
  loadingLegend: false,
  marker: { latitude: null, longitude: null },
  display_marker: false,
  zoomEndFromMarkerCDP: false,
  from_server: true,
  legend: [],
});
const carteprixSlice = createSlice({
  name: "carte",
  initialState: initialState(),
  reducers: {
    setLegend: (state, action) => {
      return { ...state, legend: action.payload.data };
    },
    setPricesTables: (state, action) => {
      return {
        ...state,
        provinces: action.payload.data.provinces,
        communes: action.payload.data.communes,
      };
    },
    setAgences: (state, action) => {
      return { ...state, agences: action.payload.data };
    },
    setAnnonces: (state, action) => {
      return { ...state, annonces: action.payload.data };
    },
    setTransactions: (state, action) => {
      return { ...state, transactions: action.payload.data };
    },

    setPrix: (state, action) => {
      return { ...state, prices: action.payload.data, from_server: false };
    },
    setTransactionPopup: (state, action) => {
      return {
        ...state,
        transaction_popup_data: action.payload.data.data,
        transaction_popup: action.payload.data.popup,
      };
    },
    setTransactionFullDisplay: (state, action) => {
      return {
        ...state,
        transaction_full_display_data: action.payload.data.data,
        transaction_full_display: action.payload.data.popup,
      };
    },
    setAgencePopup: (state, action) => {
      return {
        ...state,
        agence_popup_data: action.payload.data.data,
        agence_popup: action.payload.data.popup,
      };
    },

    setTodisplay: (state, action) => {
      return { ...state, todisplay: action.payload.data };
    },

    setVentesVisibility: (state, action) => {
      return { ...state, ventesVisibility: action.payload.data };
    },
    setAgencesVisiblity: (state, action) => {
      return { ...state, agencesVisibility: action.payload.data };
    },

    cartePrixChangeDisplay: (state, action) => {
      return {
        ...state,
        todisplay: state.todisplay === "CARTE" ? "INFO" : "CARTE",
      };
    },

    resetState: (state) => initialState(),
  },
});

export const {
  setTodisplay,
  resetState,
  setPrix,
  setPricesTables,
  cartePrixChangeDisplay,
  setAgencesVisiblity,
  setVentesVisibility,
  setAgences,
  setAnnonces,
  setTransactionPopup,
  setAgencePopup,
  setTransactions,
  setTransactionFullDisplay,
  setLegend,
} = carteprixSlice.actions;
export default carteprixSlice.reducer;
