import AgenzLogoPro from "assets/logos_svg/agenz_logo_pro.svg";
import useTranslation from "next-translate/useTranslation";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { Suspense, useRef, useState } from "react";
import formatUrl from "utils/FormatUrl";
// import MobileDrawer from "./MobileDrawerv3";
import classes from "./NavbarMobile.module.css";
import Image from "next/image";
const MobileDrawer = dynamic(() => import("./MobileDrawerv3"), {
  ssr: false,
});
export default function NavbarMobile({
  navBarType,
  authenticated,
  openAuthDialog,
  user,
  onLogout,
}: {
  navBarType: any;
  authenticated;
  openAuthDialog: () => void;
  onLogout: () => void;
  user: any;
}) {
  const { t, lang } = useTranslation("LandingPage");
  const router = useRouter();
  const [openDrawer, setOpendrawer] = useState(false);
  const [menuClicked, setMenuClicked] = useState(false);

  const node = useRef(null);

  const handleClickOutside = (event: any) => {
    if (node.current && node.current.contains(event.target)) {
      return;
    }
    setOpendrawer(false);
    setMenuClicked(false);
  };

  // useEffect(() => {
  //     document.addEventListener("click", handleClickOutside);
  //     return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //     };
  // }, []);
  return (
    <>
      <div
        className={classes.navbar__mobile__container}
        id={"navbar__mobile__container"}
      >
        <div className={classes.navbar__mobile__inner}>
          <div
            className={classes.navbar__logo__container}
            data-tracking="click"
            data-value="navbar-logo"
          >
            {navBarType === "NORMAL" && (
              <a
                data-tracking="click"
                data-value="navbar-logo"
                className={classes.anchor_black}
                href={formatUrl(`/${lang}`)}
              >
                <Image
                  unoptimized
                  loading="lazy"
                  // src={AgenzLogo.src}
                  src={
                    "https://images.agenz.ma/uploads/agenz-logo_250x250.webp"
                  }
                  className={`${classes.logoImageNoWidth}`}
                  // style={{ padding: "14px 0px" }}
                  alt="agenz logo"
                  width={123}
                  height={35}
                ></Image>
              </a>
            )}
            {navBarType === "OFFRE_PRO" && (
              <a
                data-tracking="click"
                data-value="navbar-logo"
                className={classes.anchor_black}
                href={formatUrl(`/${lang}`)}
              >
                <Image
                  unoptimized
                  width={123}
                  height={35}
                  loading="lazy"
                  src={AgenzLogoPro.src}
                  className={`${classes.logoImage}`}
                  alt="agenz logo"
                ></Image>
              </a>
            )}
            {navBarType === "CREDIT" && (
              <a
                data-tracking="click"
                data-value="navbar-logo"
                className={classes.anchor_black}
                href={formatUrl(`/${lang}/credit-immobilier-maroc`, lang)}
              >
                <Image
                  unoptimized
                  loading="lazy"
                  src={
                    "https://storage.googleapis.com/headers-agenz/logo-agenz/Agenz_credit_immo.webp"
                  }
                  // className={`${classes.logoImage}`}
                  // style={{ height: "auto" }}
                  alt="agenz logo"
                  width={128}
                  height={42}
                ></Image>
              </a>
            )}
          </div>
          <div className={classes.navbar__mobile__right__container}>
            <div className={classes.navbar__mobile__right__inner}>
              <div
                data-tracking="click"
                data-value={`menu-bars-mobile-to-${
                  openDrawer ? "close" : "open"
                }`}
                className={classes.navbar__mobile__button__container}
                onClick={() => {
                  setOpendrawer(!openDrawer);
                  setMenuClicked(!menuClicked);
                }}
              >
                <div
                  className={classes.navbar__mobile__button__inner_menu}
                  data-tracking="click"
                  data-value="navbar-mobile-menu"
                >
                  <span className={classes.menu_title}>{t("Menu")}</span>
                </div>
                <div
                  className={classes.navbar__mobile__button__inner}
                  data-tracking="click"
                  data-value="navbar-mobile-menu-logo"
                >
                  {openDrawer ? (
                    <i
                      aria-hidden
                      className={`fas fa-times ${classes.fa__times}`}
                    ></i>
                  ) : (
                    <i
                      aria-hidden
                      className={`fas fa-bars ${classes.fa__bars}`}
                    ></i>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {true && (
        <>
          <div
            className={menuClicked ? classes.connav : classes.connavnone}
            onClick={handleClickOutside}
            id="overlay-mobile"
          ></div>
          <Suspense fallback={<></>}>
            <MobileDrawer
              user={user}
              closeMenu={() => {
                setMenuClicked(false);
                setOpendrawer(false);
              }}
              openLoginModal={() => {
                openAuthDialog();
              }}
              onLogout={onLogout}
              menuClicked={menuClicked}
            />
          </Suspense>
        </>
      )}
    </>
  );
}
