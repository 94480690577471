import Regions from "assets/citiesUrlsMappings/regions-to-url.json";
import Provinces from "assets/ProvincesMaroc/ProvincesMaroc.json";
import Footer from "SanityComponents/Footer/Footer";
import AgencesSection from "components/v2/Annuaire/AgencesSection";
import Banner from "components/v2/Annuaire/Banner";
import ListeAgence from "components/v2/Annuaire/ListeAgence";
import Recherche from "components/v2/Annuaire/Recherches";
import styles from "./AnnuaireContainer.module.css";
const AnnuaireContainer = (props) => {
  return (
    <div
      className={styles.annuaire_container}
      style={{ direction: props.lang == "ar" ? "rtl" : "ltr" }}
    >
      <Recherche />
      <AgencesSection displayEmptyPage={true} displayPagination={true} />
      <div className={styles.banner}>
        <Banner
          title={props.t("Vous êtes une agence immobilière ?")}
          buttonText={props.t("Apparaissez sur cette page")}
          buttonHref={"https://agenz.ma/contact"}
        />
      </div>
      {/* <ListeAgence
        title={props.t("Les agences immobilières par région")}
        items={Object.keys(Regions).map((item) => {
          return {
            name: props.t(item),
            url: `agence-immobiliere-maroc/${Regions[item]}`,
          };
        })}
      />
      <ListeAgence
        title={props.t("Les agences immobilières par ville")}
        items={Provinces.map((item) => {
          return {
            name: props.t(item.province),
            url: `agence-immobiliere-maroc/${item.region
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .trim()
              .replace(/ /g, "-")}/${item.province
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .trim()
              .replace(/ /g, "-")}`,
          };
        })}
      /> */}
      <div className={styles.footer}>
        <div className={styles.footerInner}>
          <Footer />
        </div>
      </div>
    </div>
  );
};
export default AnnuaireContainer;
