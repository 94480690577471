import useTranslation from "next-translate/useTranslation";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import nookies from "nookies";
import { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAuthenticated,
  setEmail,
  setUID,
  setUser as setUserDispatch,
  signInR,
  updateUser,
} from "redux/slices/authReducer";
import { checkUserClientSide } from "utils/BackendHelper";
import {
  actions as UserSpaceActions,
  selectors as UserSpaceSelectors,
} from "../../redux/slices/v2/UserSpace";
// const NavbarMobile = dynamic(() => import("./NavbarMobile"), {
//   suspense: true,
// });
import Navbar from "./Navbar";
import classes from "./navbarguard.module.css";
import NavbarMobile from "./NavbarMobile";
// import LoginRegisterDialog from "components/LoginRegisterDialog";
// import "moment/locale/fr";

const LoginRegisterDialog = dynamic(
  () => import("components/LoginRegisterDialog"),
  {
    ssr: false,
    suspense: true,
  }
);

const NavbarGuard = ({ userSSR }: { userSSR: any }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { t, lang } = useTranslation("common");
  const isAuthDialogVisibleTrigger = useSelector(
    UserSpaceSelectors.isAuthDialogVisibleSelector
  );
  useEffect(() => {
    if (isAuthDialogVisibleTrigger) {
      setAuthDialogVisible(true);
      dispatch(UserSpaceActions.setIsAuthDialogVisible({ data: false }));
    }
  }, [isAuthDialogVisibleTrigger]);
  const authenticatedRedux = useSelector(
    (store: any) => store?.auth?.authenticated
  );
  const userRedux = useSelector((store: any) => store?.auth?.user);
  const authenticatedProRedux = useSelector(
    (store: any) => store?.auth?.authenticatedPro
  );

  const [user, setUser] = useState(userRedux);
  useEffect(() => {
    setUser(userRedux);
  }, [userRedux]);
  const [activeRoute, setActiveRoute] = useState(router.pathname);

  const [isAuthDialogVisible, setAuthDialogVisible] = useState(false);
  const [checkProPath, setCheckProPath] = useState<boolean>(
    (router.pathname.startsWith("/_error") &&
      (router.asPath.startsWith("/annonces/") ||
        router.asPath.startsWith("/acheter") ||
        router.asPath.startsWith("/louer") ||
        router.asPath.startsWith("/immo"))) ||
      router.asPath.startsWith("/espace-pro") ||
      router.asPath.startsWith("/prix-immobilier-maroc") ||
      router.pathname.startsWith("/louer/immo/[slug]/annonces") ||
      router.pathname.startsWith("/louer/[type]") ||
      router.pathname.startsWith("/louer/immo/[slug]/[type]") ||
      router.pathname.startsWith("/louer/immo/[slug]/[type]/[quartier]") ||
      router.pathname.startsWith("/acheter/immo/[slug]/annonces") ||
      router.pathname.startsWith("/acheter/[type]") ||
      router.pathname.startsWith("/acheter/immo/[slug]/[type]") ||
      router.pathname.startsWith("/acheter/immo/[slug]/[type]/[quartier]") ||
      router.pathname.startsWith(
        "/annonces/immo/[slugannonce]/location/[typeannonce]/[quartierannonce]/[annonceslug]"
      ) ||
      router.pathname.startsWith(
        "/annonces/immo/[slugannonce]/vente/[typeannonce]/[quartierannonce]/[annonceslug]"
      ) ||
      router.asPath.startsWith("/list.htm") ||
      router.asPath.startsWith("/immobilier")
  );

  const [checkListPath, setCheckListPath] = useState<boolean>(
    (router.pathname.startsWith("/_error") &&
      (router.asPath.startsWith("/annonces/") ||
        router.asPath.startsWith("/acheter") ||
        router.asPath.startsWith("/louer") ||
        router.asPath.startsWith("/immo"))) ||
      router.pathname.startsWith(
        "/annonces/immo/[slugannonce]/location/[typeannonce]/[quartierannonce]/[annonceslug]"
      ) ||
      router.pathname.startsWith(
        "/annonces/immo/[slugannonce]/vente/[typeannonce]/[quartierannonce]/[annonceslug]"
      ) ||
      router.pathname.startsWith("/louer/immo/[slug]/annonces") ||
      router.pathname.startsWith("/louer/[type]") ||
      router.pathname.startsWith("/louer/immo/[slug]/[type]") ||
      router.pathname.startsWith("/louer/immo/[slug]/[type]/[quartier]") ||
      router.pathname.startsWith("/acheter/immo/[slug]/annonces") ||
      router.pathname.startsWith("/acheter/[type]") ||
      router.pathname.startsWith("/acheter/immo/[slug]/[type]") ||
      router.pathname.startsWith("/acheter/immo/[slug]/[type]/[quartier]") ||
      router.asPath.startsWith("/list.htm") ||
      router.asPath.startsWith("/map.htm")
  );

  useEffect(() => {
    if (router.pathname.startsWith("/espace-pro")) setCheckProPath(true);
  }, [checkProPath]);

  const signInSuccess = (userData) => {
    setUser(userData);
    setAuthDialogVisible(false);
    if (userData.isPro) {
      window.location.href = `/${lang}/espace-pro/vitrine/gerermavitrine`;
    }
  };

  const getNavType = (pathname: string, authenticated: boolean) => {
    // /estimation/appartement/resultat?token=74bd70ea-f86b-4138-abf6-b5cb77ee2d65
    if (pathname.includes("user")) {
      return "NORMAL";
    } else if (pathname.includes("estimation")) {
      //on est dans l 'estimation
      if (!pathname.includes("estimation-")) {
        // on est pas dans la homePage d'estimation => on est dans estimation stepper
        if (pathname.includes("estimation") && pathname.includes("resultat")) {
          // on est dans les resultats
          if (!authenticated) {
            // resultat mais sans authentification

            return "ESTIMATION";
          } else {
            // authentifier => on affiche la navbar normale

            return "NORMAL";
          }
        } else {
          // on est dans estimation/map , estimation/bien ..

          return "ESTIMATION";
        }
      }
    }
    if (
      pathname.startsWith("/offres-pro") ||
      pathname.startsWith("/temoignages")
    ) {
      // on est dans une des pages de l'offre pro
      return "OFFRE_PRO";
    }
    if (
      pathname.startsWith("/espace-pro") ||
      pathname.startsWith("/espace-pro")
    ) {
      // on est dans espace pro => espace pro a son propre Navbar
      return "PRO_SPACE";
    }
    if (pathname.startsWith("/guide-du-credit")) {
      return "CREDIT";
    }
    if (pathname.startsWith("/credit")) {
      return "CREDIT";
    }
    return "NORMAL";
  };

  useEffect(() => {
    setActiveRoute(router.pathname);
  }, [router.pathname]);

  // useEffect(() => {
  //   setUser(userRedux);
  // }, [userRedux]);
  const firstCheckPushState = useRef(true);

  // useEffect(() => {
  //   if (firstCheckPushState.current) {
  //     firstCheckPushState.current = false;
  //     return;
  //   } else {
  //     (function (history) {
  //       var pushState = history.pushState;
  //       history.pushState = function (state) {
  //         if (typeof history.onpushstate == "function") {
  //           history.onpushstate({ state: state });
  //         }
  //         // ... whatever else you want to do
  //         // maybe call onhashchange e.handler

  //         try {
  //           return pushState.apply(history, arguments);
  //         } catch (e) {}
  //       };
  //     })(window.history);
  //     window.onpopstate = history.onpushstate = function (e) {
  //       if (e.state) {
  //         if (e.state.url) {
  //           setActiveRoute(e.state.url);
  //         }
  //       }
  //     };
  //   }
  // }, []);
  const userUpdate = useRef(null);
  const firstCheck = useRef(false);
  useEffect(() => {
    // if (JSON.stringify(userRedux) !== JSON.stringify(userUpdate.current)) {
    //   userUpdate.current = userRedux;
    checkUserClientSide(undefined, dispatch);
    // }
  }, []);
  const onLogout = () => {
    // dispatch(setAuthenticated({}));
    dispatch(updateUser({ data: null }));
    dispatch(signInR({ data: null }));
    dispatch(setUID({ data: null }));
    dispatch(setEmail({ data: null }));
    dispatch(setUserDispatch({ data: null }));
    localStorage.removeItem("FBIdToken");
    localStorage.removeItem("RT");
    nookies.set(undefined, "AccessToken", "", {
      path: "/",
    });
    nookies.destroy(undefined, "AccessToken");
    nookies.set(undefined, "FBIdToken", "", {
      path: "/",
    });
    nookies.destroy(undefined, "FBIdToken");
    nookies.set(undefined, "RT", "", {
      path: "/",
    });
    nookies.destroy(undefined, "RT");

    dispatch(setUserDispatch(null));
    dispatch(setAuthenticated(false));
  };

  const authenticated = authenticatedRedux || authenticatedProRedux;

  const navBarType = getNavType(activeRoute, authenticated);

  return (
    <>
      {!checkListPath && !checkProPath && navBarType !== "ESTIMATION" ? (
        <Suspense fallback={``}>
          <LoginRegisterDialog
            visible={isAuthDialogVisible}
            onClose={() => setAuthDialogVisible(false)}
            onFacebookSignInSuccess={(userData) => signInSuccess(userData)}
            onGoogleSignInSuccess={(userData) => signInSuccess(userData)}
            onRegisterSucccess={(userData) => signInSuccess(userData)}
            onSignInSuccess={(userData) => signInSuccess(userData)}
            onSwitchSignType={() => {
              return;
            }}
            Estimation={false}
          />
        </Suspense>
      ) : null}
      {!checkListPath ? (
        <div className={classes.mobile__hidden}>
          <Navbar
            user={user}
            navBarType={navBarType}
            activeRoute={activeRoute}
            authenticated={authenticated}
            openAuthDialog={() => {
              setAuthDialogVisible(true);
            }}
            onLogout={onLogout}
          />
        </div>
      ) : null}
      <div className={classes.desktop__hidden}>
        <Suspense fallback={<></>}>
          {!checkProPath && navBarType !== "ESTIMATION" ? (
            <Suspense fallback={<></>}>
              <NavbarMobile
                openAuthDialog={() => setAuthDialogVisible(true)}
                user={user}
                navBarType={navBarType}
                authenticated={authenticated}
                onLogout={onLogout}
              />
            </Suspense>
          ) : null}
        </Suspense>
      </div>
    </>
  );
};
export default NavbarGuard;
