import useTranslation from "next-translate/useTranslation";
import Head from "next/head";
import Script from "next/script";
import { canonicalUrl } from "utils/FormatUrl";
import siteMetadata from "../../data/siteMetadata";

const HeadSeo = (props) => {
  const { t, lang } = useTranslation("headers");
  return (
    <Head>
      {/* //basic metadata */}
      <title>{t(props.title)}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
      />
      <meta name="description" content={props.description} ></meta>

      <meta name="twitter:card" content="summary" ></meta>
      <meta name="twitter:site" content={siteMetadata.twitterHandle} ></meta>
      <meta name="twitter:title" content={props.title} ></meta>
      <meta name="twitter:description" content={props.description} ></meta>
      <meta
        name="twitter:image"
        content={props.ogImageUrl
          ? props.ogImageUrl
          : "https://storage.googleapis.com/headers-agenz/Logo%20300_%20300%20px.jpg"
        }
      ></meta>

      {props.canonicalUrl ? <link rel="canonical" href={canonicalUrl(props.canonicalUrl, lang)} /> : null}
      <link rel="alternate" hrefLang="fr" href={canonicalUrl(props.canonicalUrl, "fr")} />
      <link rel="alternate" hrefLang="ar" href={canonicalUrl(props.canonicalUrl, "ar")} />
      <meta property="og:locale" content={lang == "fr" ? `fr_FR` : `ar_AR`} ></meta>
      <meta property="og:site_name" content={siteMetadata.companyName} ></meta>
      <meta property="og:type" content={props.ogType} ></meta>
      <meta property="og:title" content={t(props.ogTitle)} ></meta>
      <meta property="og:description" content={t(props.ogDescription)} ></meta>
      <meta
        property="og:image"
        // itemProp="image"
        content={props.ogImageUrl
          ? props.ogImageUrl
          : "https://storage.googleapis.com/headers-agenz/Logo%20300_%20300%20px.jpg"
        }
      ></meta>
      <meta property="og:url" content={canonicalUrl(props.canonicalUrl, lang)} ></meta>
      <meta property="og:updated_time" content="1440432930" ></meta>
      <meta property="og:image:width" content="256" ></meta>
      <meta property="og:image:height" content="256" ></meta>

      <meta name="msapplication-TileImage" content={props.ogImageUrl}></meta>
      {props.appId ? (
        <>
          {/* <meta name="apple-itunes-app" content={`app-id=${props.appId}, app-argument=${props.resolvedUrl}`} /> */}
          <Script strategy="beforeInteractive" src="node_modules/smart-app-banner/dist/smart-app-banner.js" />
          <meta name="apple-itunes-app" content={`app-id=${props.appId}`} ></meta>
          <meta name="google-play-app" content="app-id=com.fl.agenz" ></meta>
          <Script
            id="app-banner"
            strategy="beforeInteractive"
            dangerouslySetInnerHTML={{
              __html: `new SmartBanner({
            daysHidden: 0,
            daysReminder: 0,
            appStoreLanguage: 'fr'
            title: ${props.title},
            author: 'Agenz',
            button: 'Ouvrir',
            store: {
                ios: 'On the App Store',
                android: 'In Google Play'
            },
            price: {
                ios: 'FREE',
                android: 'FREE',
                windows: 'FREE'
            }
            // , theme: '' // put platform type ('ios', 'android', etc.) here to force single theme on all device
            // , icon: '' // full path to icon image if not using website icon image

        });`,
            }}
          />
          <script type="text/javascript">

          </script>
        </>
      ) : (null)}
      {props.structuredData ? (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={props.structuredData}
        />
      ) : (
        null
      )}
      {props.children ? props.children : null}
    </Head>
  );
};

export default HeadSeo;
