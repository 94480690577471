const siteMetadata = {
  companyName: "Agenz",
  phoneNumber: "+212 5 20 69 00 69",
  siteUrl: "https://agenz.ma/",
  siteLogo:
    "https://storage.googleapis.com/headers-agenz/Logo%20300_%20300%20px.jpg",
  siteLogoSquare:
    "https://storage.googleapis.com/headers-agenz/Logo%20300_%20300%20px.jpg",
  email: "contact@agenz.ma",
  twitter: "https://twitter.com/agenz_maroc?s=20",
  twitterHandle: "@agenztechnologies",
  facebook: "https://www.facebook.com/Agenztechnologies",
  instagram: "https://www.instagram.com/agenztechnologies",
  linkedin: "https://www.linkedin.com/company/agenztechnologies/",
};

export default siteMetadata;
