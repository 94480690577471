import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "types/RootState";
import ProSpaceState, { Contact } from "./types";

const initialState: ProSpaceState = {
  proSpaceMockRouter: {
    currentRoute: "PRO_SPACE_HOME",
  },
  filterNumberVente: 0,
  navigate: false,
  filterNumberAnnonce: 0,
  drawerOpen: false,
  agence: {},
  contacts: [],
  contactsLoading: false,
  contactsRefreshing: false,
  contactsError: false,
  contactsNumber: 0,
  contactsQuery: {
    pageSize: 10,
    page: 0,
    sort: "last_update",
    order: -1,
    filters: {
      projet_type: [],
      statut: [],
      timing: [],
      villes: [],
      quartiers: [],
      bien: [],
      budgetMax: 0,
      budgetMin: 0,
    },
  },

  rubriqueVitrine: "top",
  contactsToDisplay: "List",
  currentContact: {
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    projet_type: "Acheteur",
    projet_ville: "",
    projet_quartier: [],
    besoin_financement: false,
    has_agent: false,
    timing: "1-3 Mois",
    statut: "Nouveau",
    creation_origin: "inbox",
    type_bien: "Appartement",
    creation_origin_id: "",
    accord_credit: false,
    from: "",
    creation_id: "",
    create_date: "",
    last_update: "",
    created_by: "",
    owner: "",
    note: "",
    budget: 0,
    accordCredit: false,
    hasAgent: false,
  },
  updateContactLoading: false,

  ERROR_FIELDS: {
    surfaceError: false,
    typologieError: false,
    SDBError: false,
    zoningError: false,
    projectNameError: false,
  },

  displayDropDown: false,
  displayFilters: false,

  annonces: [],
  annoncesQuery: {
    page: 0,
    sort: "date",
    order: -1,
    search: "",
    vendu: false,
    type: [],
    typedetransaction: [],
    etat: [],
    reference: "",
    prix_max: 0,
    prix_min: 0,
  },
  ventes: [],
  ventesQuery: {
    page: 0,
    sort: "date",
    order: -1,
    search: "",
    vendu: false,
    type: [],
    typedetransaction: [],
    etat: [],
    reference: "",
    prix_max: 0,
    prix_min: 0,
  },
  totalAnnoncesNumbers: {
    totalAnnonces: 0,
    totalRejectedAnnonces: 0,
    totalValidAnnonces: 0,
    totalStandByAnnonces: 0,
  },
  totalVentesNumbers: {
    totalAnnonces: 0,
    totalRejectedAnnonces: 0,
    totalValidAnnonces: 0,
    totalStandByAnnonces: 0,
  },

  annonceToUpdate: {
    id: "",
    _id: "",
    transaction_type: "Vente",
    images_with_key: [],
    images: [],
    images_loading: [],
    image_end_loading: [],
    files: [],
    slug: "",
    prix: 0,
    logo: {
      url: "",
      name: "",
    },
    dateLivraison: "",
    disponibilite: {
      annee: 2023,
      trimestre: "",
    },
    projectName: "",
    // virtual_tour: false,
    // virtual_tour_link: "",
  },
  addingAnnonce: false,

  currentAnnonce: {
    activeStep: 1,
    type: "Appartement",
    checkOrientation: false,
    surface: 0,
    typologie: null,
    sdb: 0,
    etage: 1,
    construction: "",
    transaction_type: "",
    prix: "",
    virtual_tour: false,
    virtual_tour_link: "",
    phone: "",
    etat: "",
    Description: "",
    visibleDescription: "",
    balcons: false,
    surfacebalcons: 0,
    terrasses: false,
    surfaceterrasses: 0,
    orientation: "",
    parking: false,
    placesparking: 0,
    cave: false,
    surfacecave: 0,
    ascenseur: false,
    chambre_service: false,
    vueexceptionnelle: false,
    residence_fermee: false,
    meublee: false,
    images_with_key: [],
    images: [],
    images_loading: [],
    image_end_loading: [],
    files: [],
    dateLivraison: "",
    projectName: "",
    disponibilite: {
      annee: "",
      trimestre: "",
    },
    logo: null,
    camera: false,
    terrainSport: false,
    espaceEnfants: false,
    terrasseAccesible: false,
    chauffeeausolaire: false,
    murmitoyen: false,
    typeVilla: "",
  },

  userMessagesVisibility: [],
  vitrineViews: 0,
  statistiquesLoaders: {
    vitrineLoaders: false,
    contactPhoneMailLoader: false,
  },

  avisLoader: true,
  avisFirstLoader: true,
  avis: [],
  avisFilter: {
    sort_by: "note",
    order: "desc",
    page: 1,
  },
  glovalAvis: {
    noteGlobal: 0,
    stars_1_per: "0%",
    stars_2_per: "0%",
    stars_3_per: "0%",
    stars_4_per: "0%",
    stars_5_per: "0%",
  },
  totalAvis: 0,

  //INBOX
  inboxMessages: [],
  inboxMessagesFiltred: [],
  messagesFilter: {
    pageSize: 20,
    page: 0,
    sort: "date",
    order: -1,
    search: "",
    filters: {},
  },
  totalInboxMessages: 0,
  inboxRefreshing: false,
  inboxLoading: false,

  //////

  //// new prspaces types
  refreshingAnnonces: false,
  inboxNotification: 0,
  contactsNotification: 0,
  similairAnnonce: {},
};

const slice = createSlice({
  name: "proSpaceV2",
  initialState,
  reducers: {
    setFilterNumberAnnonce: (state, action) => {
      return { ...state, filterNumberAnnonce: action.payload };
    },
    setNavigate: (state, action) => {
      return { ...state, navigate: action.payload };
    },
    setFilterNumberVente: (state, action) => {
      return { ...state, filterNumberVente: action.payload };
    },
    setAgence: (state, action) => {
      return { ...state, agence: { ...state.agence, ...action.payload.data } };
    },
    openDrawer: (state) => {
      return { ...state, drawerOpen: true };
    },
    closeDrawer: (state) => {
      return { ...state, drawerOpen: false };
    },
    setContactsNumber: (state, action) => {
      return {
        ...state,
        contactsNumber: action.payload.data,
      };
    },
    setContacts: (state, action) => {
      return {
        ...state,
        contactsError: action.payload.data.length === 0,
        contacts: action.payload.data,
        contactsLoading: false,
        contactsRefreshing: false,
      };
    },
    setContactsError: (state, action) => {
      return {
        ...state,
        contactsError: action.payload.contactsError,
      };
    },

    setContactInContactsList: (state, action) => {
      return {
        ...state,
        contacts: state.contacts.map((c) =>
          c._id === action.payload.data._id ? action.payload.data : c
        ),
      };
    },

    setAnnonces: (state, action) => {
      return {
        ...state,
        annonces: action.payload.data,
      };
    },
    setVentes: (state, action) => {
      return {
        ...state,
        ventes: action.payload.data,
      };
    },
    setAnnoncesQuery: (state, action) => {
      return {
        ...state,
        annoncesQuery: {
          page: action.payload.data.page ? action.payload.data.page : 0,
          ...state.annoncesQuery,
          ...action.payload.data,
        },
      };
    },
    setVentesQuery: (state, action) => {
      return {
        ...state,
        ventesQuery: {
          vendu: true,
          page: action.payload.data.page ? action.payload.data.page : 0,
          ...state.ventesQuery,
          ...action.payload.data,
        },
      };
    },
    setAnnonceToUpdate: (state, action) => {
      if (action.payload.image_end_loading) {
        if (action.payload.images_with_key) {
          return {
            ...state,
            annonceToUpdate: {
              ...state.annonceToUpdate,
              image_end_loading: [
                ...state.annonceToUpdate.image_end_loading,
                action.payload.image_end_loading,
              ],
              images_with_key: [
                ...state.annonceToUpdate.images_with_key,
                action.payload.images_with_key,
              ],
            },
          };
        } else {
          return {
            ...state,
            annonceToUpdate: {
              ...state.annonceToUpdate,
              image_end_loading: [
                ...state.annonceToUpdate.image_end_loading,
                action.payload.image_end_loading,
              ],
            },
          };
        }
      } else {
        try {
          delete action.payload.data["images_with_key"];
        } catch (e) {}
        try {
          delete action.payload.data["image_end_loading"];
        } catch (e) {}
        return {
          ...state,
          annonceToUpdate: { ...state.annonceToUpdate, ...action.payload.data },
        };
      }
    },

    setAddingAnnonce: (state, action) => {
      return { ...state, addingAnnonce: action.payload.data };
    },
    toggleDisplayFilters: (state) => {
      return { ...state, displayFilters: !state.displayFilters };
    },
    toggleDisplayDropDown: (state) => {
      return { ...state, displayDropDown: !state.displayDropDown };
    },
    setCurrentAnnonce: (state, action) => {
      if (action.payload.image_end_loading) {
        if (action.payload.images_with_key) {
          return {
            ...state,
            currentAnnonce: {
              ...state.currentAnnonce,
              image_end_loading: [
                ...state.currentAnnonce.image_end_loading,
                action.payload.image_end_loading,
              ],
              images_with_key: [
                ...state.currentAnnonce.images_with_key,
                action.payload.images_with_key,
              ],
            },
          };
        } else {
          return {
            ...state,
            currentAnnonce: {
              ...state.currentAnnonce,
              image_end_loading: [
                ...state.currentAnnonce.image_end_loading,
                action.payload.image_end_loading,
              ],
            },
          };
        }
      } else {
        return { ...state, currentAnnonce: action.payload.data };
      }
    },
    setUserMessagesVisibility: (state, action) => {
      return { ...state, userMessagesVisibility: action.payload.data };
    },
    setUserVitrineView: (state, action) => {
      return {
        ...state,
        vitrineViews: action.payload.data ? action.payload.data.views : 0,
      };
    },
    setVitrineLoadersLoaders: (state, action) => {
      return {
        ...state,
        statistiquesLoaders: {
          ...state.statistiquesLoaders,
          vitrineLoaders: action.payload.data,
        },
      };
    },

    setAvisLoading: (state, action) => {
      return { ...state, avisLoader: action.payload.data };
    },
    setAvisFirstLoading: (state, action) => {
      return { ...state, avisFirstLoader: action.payload.data };
    },

    setAvisData: (state, action) => {
      return { ...state, avis: state.avis.concat(action.payload.data) };
    },

    setAvisFilter: (state, action) => {
      return { ...state, avisFilter: action.payload.data };
    },

    setGlobalAvis: (state, action) => {
      return { ...state, glovalAvis: action.payload.data };
    },
    setTotalAvis: (state, action) => {
      return { ...state, totalAvis: action.payload.data };
    },

    setcontactPhoneMailLoader: (state, action) => {
      return {
        ...state,
        statistiquesLoaders: {
          ...state.statistiquesLoaders,
          contactPhoneMailLoader: action.payload.data,
        },
      };
    },

    setRPCQAnnonce: (state, action) => {
      return {
        ...state,
        currentAnnonce: {
          ...state.currentAnnonce,
          region: action.payload.data.region,
          province: action.payload.data.province,
          commune: action.payload.data.commune,
          quartier: action.payload.data.quartier,
        },
      };
    },
    setTotalAnnoncesNumbers: (state, action) => {
      let StandbyAnnonces =
        action.payload.data.totalAnnonces -
        action.payload.data.totalValidAnnonces -
        action.payload.data.totalRejectedAnnonces;

      return {
        ...state,
        totalAnnoncesNumbers: {
          totalAnnonces: action.payload.data.totalAnnonces,
          totalRejectedAnnonces: action.payload.data.totalRejectedAnnonces,
          totalValidAnnonces: action.payload.data.totalValidAnnonces,
          totalStandByAnnonces: action.payload.data.totalStandByAnnonces
            ? action.payload.data.totalStandByAnnonces
            : StandbyAnnonces > 0
            ? StandbyAnnonces
            : 0,
        },
      };
    },
    setTotalVentesNumbers: (state, action) => {
      let StandbyAnnonces =
        action.payload.data.totalAnnonces -
        action.payload.data.totalValidAnnonces -
        action.payload.data.totalRejectedAnnonces;
      return {
        ...state,
        totalVentesNumbers: {
          totalAnnonces: action.payload.data.totalAnnonces,
          totalRejectedAnnonces: action.payload.data.totalRejectedAnnonces,
          totalValidAnnonces: action.payload.data.totalValidAnnonces,
          totalStandByAnnonces: action.payload.data.totalStandByAnnonces
            ? action.payload.data.totalStandByAnnonces
            : StandbyAnnonces > 0
            ? StandbyAnnonces
            : 0,
        },
      };
    },
    setContactsQuery: (state, action) => {
      return {
        ...state,
        contactsQuery: {
          ...action.payload.data,
          page: action.payload.data?.page ? action.payload.data?.page : 0,
        },
      };
    },
    setContactsLoading: (state, action) => {
      return {
        ...state,
        contactsLoading: action.payload.data,
      };
    },
    setContactsRefreshing: (state, action) => {
      return {
        ...state,
        contactsRefreshing: action.payload.data,
      };
    },
    setContactserror: (state, action) => {
      return {
        ...state,
        contactsError: action.payload.data,
      };
    },
    setContactsToDisplay: (
      state,
      action: { payload: { contactsToDisplay: "List" | "Add" | "Detail" } }
    ) => {
      return { ...state, contactsToDisplay: action.payload.contactsToDisplay };
    },
    setCurrentContact: (
      state,
      action: { payload: { currentContact: Contact } }
    ) => {
      return {
        ...state,
        currentContact: action.payload.currentContact,
      };
    },

    setUpdateContactLoading: (
      state,
      action: {
        payload: { updateContactLoading: boolean; contactId?: string };
      }
    ) => {
      return {
        ...state,
        updateContactLoading: action.payload.updateContactLoading,
        updateContactLoadingFor: action.payload.contactId,
      };
    },

    initialiseCurrentContact: (state, action) => {
      return {
        ...state,
        currentContact: {
          firstname: "",
          lastname: "",
          phone: "",
          email: "",
          projet_type: "Acheteur",
          projet_ville: "",
          projet_quartier: [],
          besoin_financement: false,
          has_agent: false,
          timing: "1-3 Mois",
          statut: "Nouveau",
          creation_origin: "inbox",
          type_bien: "Terrain",
          creation_origin_id: "",
          accord_credit: false,
          from: "",
          creation_id: "",
          create_date: "",
          last_update: "",
          created_by: "",
          owner: "",
          note: "",
          accordCredit: false,
          hasAgent: false,
        },
      };
    },
    setInboxMessages: (state, action) => {
      return {
        ...state,
        // inboxMessages: action.payload.data,
        inboxMessages: action.payload.data.sort((a) => (a.epingle ? -1 : 1)),
      };
    },
    inboxMessagesFiltred: (state, action) => {
      return {
        ...state,
        inboxMessagesFiltred: action.payload.data.sort((a) =>
          a.epingle ? -1 : 1
        ),
      };
    },
    setMessagesFilter: (state, action) => {
      return {
        ...state,
        messagesFilter: action.payload.data,
      };
    },
    setTotalInboxMessages: (state, action) => {
      return {
        ...state,
        totalInboxMessages: action.payload.data,
      };
    },
    setInboxRefreshing: (state, action) => {
      return {
        ...state,
        inboxRefreshing: action.payload.data,
      };
    },
    setInboxLoading: (state, action) => {
      return {
        ...state,
        inboxLoading: action.payload.data,
      };
    },

    setCurrentRout: (
      state: ProSpaceState,
      action: {
        payload: {
          currentRoute: ProSpaceState["proSpaceMockRouter"]["currentRoute"];
        };
      }
    ) => {
      return {
        ...state,
        proSpaceMockRouter: { currentRoute: action.payload.currentRoute },
      };
    },

    ///////// newTypes

    setRefreshingAnnonces: (state, action) => {
      return {
        ...state,
        refreshingAnnonces: action.payload,
      };
    },

    setInboxNotification: (state, action) => {
      return {
        ...state,
        inboxNotification: action.payload.data,
      };
    },

    setContactsNotification: (state, action) => {
      return {
        ...state,
        contactsNotification: action.payload.data,
      };
    },

    setSimilairAnnonce: (state, action) => {
      return {
        ...state,
        similairAnnonce: action.payload.data,
      };
    },

    setRubriqueVitrine: (state, action) => {
      return {
        ...state,
        rubriqueVitrine: action.payload.data,
      };
    },
  },
});

const selectProSpaceV2Domain = (state: RootState) =>
  state.proSpaceV2 || initialState;

const proSpaceMockRouterSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.proSpaceMockRouter
);

const filterNumberVente = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.filterNumberVente
);

const navigateSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.navigate
);

const filterNumberAnnonce = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.filterNumberAnnonce
);

const contactsQuerySelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.contactsQuery
);
const currentContactSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.currentContact
);
const contactsToDisplaySelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.contactsToDisplay
);
const contactsLoadingSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.contactsLoading
);
const contactsRefreshingSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.contactsRefreshing
);
const contactsErrorSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.contactsError
);
const contactsNumberSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.contactsNumber
);

const agenceSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.agence
);
const contactsSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.contacts
);

const annoncesQuerySelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.annoncesQuery
);
const annoncesSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.annonces
);

const ventesQuerySelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.ventesQuery
);
const ventesSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.ventes
);

const totalAnnoncesSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.totalAnnoncesNumbers
);
const totalVentesSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.totalVentesNumbers
);

const drawerOpenSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.drawerOpen
);

const annonceToUpdateSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.annonceToUpdate
);
const addingAnnonceSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.addingAnnonce
);

const displayFiltersSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.displayFilters
);

const displayDropDownSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.displayDropDown
);

const currentAnnonceSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.currentAnnonce
);

const userMessagesVisibilitySelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.userMessagesVisibility
);

const vitrineViewsSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.vitrineViews
);
const statistiquesLoadersSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.statistiquesLoaders
);
const avisLoaderSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.avisLoader
);
const avisFirstLoaderSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.avisFirstLoader
);
const avisSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.avis
);
const avisFilterSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.avisFilter
);
const globalAvisSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.glovalAvis
);
const totalAvisSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.totalAvis
);
const inboxMessagesSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.inboxMessages
);
const inboxMessagesFiltredSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.inboxMessagesFiltred
);
const messagesFilterSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.messagesFilter
);
const totalInboxMessagesSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.totalInboxMessages
);
const inboxRefreshingSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.inboxRefreshing
);
const inboxLoadingSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.inboxLoading
);
const updateContactLoadingSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.updateContactLoading
);
const updateContactLoadingForSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.updateContactLoadingFor
);

/////// new types

const refreshingAnnoncesSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.refreshingAnnonces
);

const inboxNotificationSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.inboxNotification
);

const contactsNotificationSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.contactsNotification
);

const similairAnnonceSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.similairAnnonce
);

const rubriqueVitrineSelector = createSelector(
  [selectProSpaceV2Domain],
  (substate) => substate.rubriqueVitrine
);

export const selectors = {
  filterNumberVente,
  filterNumberAnnonce,
  contactsQuerySelector,
  agenceSelector,
  contactsSelector,
  annoncesQuerySelector,
  annoncesSelector,
  ventesQuerySelector,
  ventesSelector,
  totalAnnoncesSelector,
  drawerOpenSelector,
  addingAnnonceSelector,
  annonceToUpdateSelector,
  displayFiltersSelector,
  displayDropDownSelector,
  currentAnnonceSelector,
  userMessagesVisibilitySelector,
  vitrineViewsSelector,
  statistiquesLoadersSelector,
  contactsLoadingSelector,
  contactsRefreshingSelector,
  contactsErrorSelector,
  contactsNumberSelector,
  avisLoaderSelector,
  avisFirstLoaderSelector,
  avisSelector,
  avisFilterSelector,
  globalAvisSelector,
  totalAvisSelector,
  currentContactSelector,
  contactsToDisplaySelector,
  inboxMessagesSelector,
  inboxMessagesFiltredSelector,
  messagesFilterSelector,
  totalInboxMessagesSelector,
  inboxRefreshingSelector,
  inboxLoadingSelector,
  proSpaceMockRouterSelector,
  updateContactLoadingSelector,
  updateContactLoadingForSelector,
  totalVentesSelector,
  navigateSelector,

  //////new types

  refreshingAnnoncesSelector,
  inboxNotificationSelector,
  contactsNotificationSelector,
  similairAnnonceSelector,
  rubriqueVitrineSelector,
};

export const actions = {
  ...slice.actions,
};

export default slice.reducer;
