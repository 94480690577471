class MiniQuery {
  private elements: Element[] | Document[] = [];

  constructor(arg: string | Element | Document) {
    if (typeof arg === "string") {
      this.elements = Array.from(document.querySelectorAll(arg));
    } else if (arg === document) {
      this.elements = [document];
    } else if (arg instanceof Element) {
      this.elements = [arg];
    }
  }

  each(callback: (index: number, element: Element) => void): MiniQuery {
    this.elements.forEach((el, i) => callback(i, el));
    return this;
  }

  scrollTop(value?: number): any {
    if (value !== undefined) {
      this.elements.forEach((el) => {
        if (el instanceof HTMLElement || el === document.documentElement) {
          el.scrollTop = value;
        }
      });
      return this;
    } else {
      if (
        this.elements.length > 0 &&
        (this.elements[0] instanceof HTMLElement ||
          this.elements[0] === document.documentElement)
      ) {
        return this.elements[0].scrollTop;
      }
      return 0;
    }
  }

  removeClass(className: string): MiniQuery {
    this.elements.forEach((el) => {
      if (el instanceof HTMLElement) {
        el.classList.remove(className);
      }
    });
    return this;
  }
  addClass(className: string): MiniQuery {
    this.elements.forEach((el) => {
      if (el instanceof HTMLElement) {
        el.classList.add(className);
      }
    });
    return this;
  }
  attr(attributeName: string): string | null;
  attr(attributeName: string, value: string): MiniQuery;
  attr(attributeName: string, value?: string): MiniQuery | string | null {
    if (value !== undefined) {
      // Si une valeur est fournie, définir l'attribut
      this.elements.forEach((el) => {
        if (el instanceof HTMLElement) {
          el.setAttribute(attributeName, value);
        }
      });
      return this;
    } else {
      // Si aucune valeur n'est fournie, obtenir la valeur de l'attribut
      if (this.elements.length > 0 && this.elements[0] instanceof HTMLElement) {
        return this.elements[0].getAttribute(attributeName);
      } else {
        return null;
      }
    }
  }

  prop(key: string, value: any): MiniQuery {
    this.elements.forEach((el) => ((el as any)[key] = value));
    return this;
  }

  focus(callback?: () => void): MiniQuery {
    this.elements.forEach((el) => {
      if (el instanceof HTMLElement) {
        if (callback) {
          el.addEventListener("focus", callback);
        } else {
          (el as HTMLElement).focus();
        }
      }
    });
    return this;
  }

  blur(callback?: () => void): MiniQuery {
    this.elements.forEach((el) => {
      if (el instanceof HTMLElement) {
        if (callback) {
          el.addEventListener("blur", callback);
        } else {
          (el as HTMLElement).blur();
        }
      }
    });
    return this;
  }

  offset(): { top: number; left: number } | null {
    if (this.elements.length && this.elements[0] instanceof HTMLElement) {
      const rect = this.elements[0].getBoundingClientRect();
      return {
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX,
      };
    }
    return null;
  }

  on(event: string, callback: EventListenerOrEventListenerObject): MiniQuery {
    this.elements.forEach((el) => el.addEventListener(event, callback));
    return this;
  }

  trigger(event: string): MiniQuery {
    this.elements.forEach((el) => {
      const evt = new Event(event, { bubbles: true, cancelable: true });
      el.dispatchEvent(evt);
    });
    return this;
  }
  addEventListener(
    event: string,
    handler: EventListenerOrEventListenerObject
  ): MiniQuery {
    this.elements.forEach((el) => {
      if (el instanceof HTMLElement || el instanceof Document) {
        el.addEventListener(event, handler);
      }
    });
    return this;
  }
  css(key: string, value: string | number): MiniQuery;
  css(styles: { [key: string]: string | number }): MiniQuery;
  css(
    arg1: string | { [key: string]: string | number },
    arg2?: string | number
  ): MiniQuery {
    if (typeof arg1 === "string") {
      // Cas où une seule propriété est définie
      this.elements.forEach((el) => {
        if (el instanceof HTMLElement) {
          (el.style as any)[arg1] = arg2 as any;
        }
      });
    } else {
      // Cas où plusieurs propriétés sont définies via un objet
      this.elements.forEach((el) => {
        if (el instanceof HTMLElement) {
          Object.keys(arg1).forEach((key: string) => {
            (el.style as any)[key] = arg1[key];
          });
        }
      });
    }
    return this;
  }
  setInnerHTML(html: string): MiniQuery {
    this.elements.forEach((el) => {
      if (el instanceof HTMLElement) {
        el.innerHTML = html;
      }
    });
    return this;
  }

  first(): MiniQuery {
    if (this.elements.length > 0) {
      return new MiniQuery(this.elements[0]);
    }
    return this;
  }

  bind(event: string, handler: EventListenerOrEventListenerObject): MiniQuery {
    this.elements.forEach((el) => {
      el.addEventListener(event, handler);
    });
    return this;
  }
  innerWidth(): number | null {
    if (this.elements.length && this.elements[0] instanceof HTMLElement) {
      return this.elements[0].clientWidth;
    }
    return null;
  }

  scroll(callback: () => void): MiniQuery {
    this.elements.forEach((el) => {
      if (el instanceof HTMLElement || el === document) {
        (el as HTMLElement).addEventListener("scroll", callback);
      }
    });
    return this;
  }

  position(): { top: number; left: number } | null {
    if (this.elements.length && this.elements[0] instanceof HTMLElement) {
      const el = this.elements[0];
      return {
        top: el.offsetTop,
        left: el.offsetLeft,
      };
    }
    return null;
  }

  animate(
    properties: { [key: string]: number },
    duration: number = 100
  ): MiniQuery {
    this.elements.forEach((el) => {
      if (el instanceof HTMLElement) {
        const startStyles: { [key: string]: number } = {};
        Object.keys(properties).forEach((key) => {
          startStyles[key] = parseFloat(
            window.getComputedStyle(el)[key] || "0"
          );
        });

        let start: number;
        const step = (timestamp: number) => {
          if (start === undefined) start = timestamp;
          const elapsed = timestamp - start;
          Object.keys(properties).forEach((key) => {
            const distance = properties[key] - (startStyles[key] || 0);
            const current = startStyles[key]! + distance * (elapsed / duration);
            (el.style as any)[key] = current + "px";
          });
          if (elapsed < duration) {
            requestAnimationFrame(step);
          }
        };
        requestAnimationFrame(step);
      }
    });
    return this;
  }
  is(selector: string): boolean {
    for (let el of this.elements) {
      if (el instanceof Element && el.matches(selector)) {
        return true;
      }
    }
    return false;
  }
}

function $(arg: string | Element | Document): MiniQuery {
  return new MiniQuery(arg);
}

export default $;
