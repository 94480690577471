import { useEffect, useRef, useState } from "react";
import style from "./Annuaire.module.scss"
import classes from "components/v2/Listings/SearchBarComponent/components/DropDown/DropDown.module.scss";

const DropDown = ({
  className,
  onToggleActive,
  toggleBtnStyle,
  valueComponent,
  liststyling,
  contentComponent,
  dropdownToggle,
  setDropdownToggle,
}: {
  className: string;
  onToggleActive: string;
  toggleBtnStyle: string;
  valueComponent: any;
  liststyling?: string;
  contentComponent: any;
  dropdownToggle: boolean;
  setDropdownToggle: (bool: boolean) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    setIsOpen(dropdownToggle);
  }, [dropdownToggle]);

  return (
    <div
      className={` ${className} ${
        isOpen && dropdownToggle ? onToggleActive : ""
      }`}
      style={{padding:"0px"}}
    >
      <div
        className={`${classes.toggle} ${style.toggleBar} ${toggleBtnStyle} `}
        style={
          isOpen && dropdownToggle
            ? { color: "white" }
            : {display:"flex", zIndex:"100"}
        }
        onClick={() => {
          setIsOpen((prev) => !prev);
          setDropdownToggle(dropdownToggle ? false : true);
        }}
      >
        {valueComponent}
      </div>
      {isOpen && dropdownToggle && (
        <div ref={ref} className={`${liststyling ?? classes.list_v2}`} style={{borderRadius:"5px"}}>
          {contentComponent}
        </div>
      )}
    </div>
  );
};

export default DropDown;
