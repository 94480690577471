import AgenzLogo from "assets/logos_svg/agenz.svg";
import AgenzLogoPro from "assets/logos_svg/agenz_logo_pro.svg";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import PlusInformationBtn from "ui/Button/PlusInformationBtn";
import formatUrl from "utils/FormatUrl";
import NavbarCss from "./navbarStyle.module.css";
import ProfilIcon from "./ProfilIcon";
import Image from "next/image";

declare global {
  interface History {
    onpushstate: (e: any) => void;
  }
}

export default function Navbar({
  navBarType,
  activeRoute,
  authenticated,
  openAuthDialog,
  onLogout,
  user,
  position,
  isNavBarList,
}: {
  navBarType: any;
  activeRoute: any;
  authenticated;
  openAuthDialog: () => void;
  onLogout: () => void;
  user: any;
  position?: string;
  isNavBarList?: boolean;
}) {
  const { t, lang } = useTranslation("navbar");
  const router = useRouter();
  const [link, setLink] = useState("");

  useEffect(() => {
    setLink(window.location.href);
  }, []);
  // const [mySvg, setMySvg] = useState("black")
  // const [svgColor, setSvgColor] = useState("black");
  const [showDropdown, setshowDropdown] = useState(false);
  const node = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (node.current && node.current.contains(event.target)) {
      return;
    }
    setshowDropdown(false);
  };

  if (navBarType === "PRO_SPACE" || navBarType === "ESTIMATION") {
    return <></>;
  }

  const [showAnnonces, setshowAnnonces] = useState(false);

  return (
    <>
      <div
        className={`${NavbarCss.navbarContainer} ${
          isNavBarList ? NavbarCss.navbarConList : ""
        }`}
        style={{ position: position ? "relative" : "fixed" }}
      >
        <div className={`${NavbarCss.navbar}`}>
          <ul className={`${NavbarCss.navbarPortion}`}>
            {navBarType === "NORMAL" && (
              <li>
                <a
                  data-tracking="click"
                  data-value="navbar-logo-link"
                  className={NavbarCss.anchor_black}
                  href={formatUrl(`/${lang}`)}
                >
                  <img
                    width={123}
                    height={35}
                    loading="lazy"
                    // src={AgenzLogo.src}
                    src={
                      "https://images.agenz.ma/uploads/agenz-logo_250x250.webp"
                    }
                    className={`${NavbarCss.logoImage}`}
                    // style={{ padding: "14px 0px" }}
                    alt="agenz logo"
                  />
                </a>
              </li>
            )}
            {navBarType === "OFFRE_PRO" && (
              <li>
                <a
                  data-tracking="click"
                  data-value="navbar-logo-link"
                  className={NavbarCss.anchor_black}
                  href={formatUrl(`/${lang}`)}
                >
                  <img
                    width={123}
                    height={35}
                    loading="lazy"
                    src={AgenzLogoPro.src}
                    className={`${NavbarCss.logoImage}`}
                    alt="agenz logo"
                  />
                </a>
              </li>
            )}
            {navBarType === "CREDIT" && (
              <li>
                <a
                  data-tracking="click"
                  data-value="navbar-logo-link"
                  className={NavbarCss.anchor_black}
                  href={formatUrl(`/${lang}/credit-immobilier-maroc`, lang)}
                >
                  <Image
                    width={128}
                    height={42}
                    loading="lazy"
                    src={
                      "https://storage.googleapis.com/headers-agenz/logo-agenz/Agenz_credit_immo.webp"
                    }
                    // className={`${NavbarCss.logoImage}`}
                    // style={{ height: 56 }}
                    alt="agenz logo"
                  />
                </a>
              </li>
            )}
          </ul>
          {navBarType === "OFFRE_PRO" && (
            <ul
              className={`${NavbarCss.navbarPortion} ${NavbarCss.navbarPortionFullwidth}`}
            >
              <li>
                <a
                  data-tracking="click"
                  data-value=""
                  href={formatUrl(`/${lang}/temoignages`, lang)}
                  className={`${NavbarCss.anchor_black} ${
                    activeRoute.includes("/temoignages") ? NavbarCss.active : ""
                  }`}
                >
                  {t("Nos partenaires")}
                </a>
              </li>
              <li>
                <a
                  data-tracking="click"
                  data-value=""
                  className={NavbarCss.anchor_black}
                  href={formatUrl(`/${lang}/offres-pro`, lang)}
                >
                  <span
                    onClick={() => {
                      window.open("https://mag.agenz.ma/");
                    }}
                  >
                    {t("Blog")}
                  </span>
                </a>
              </li>
              <li>
                <span className={`${NavbarCss.anchor_black}`}>
                  <i aria-hidden className="fas fa-phone"></i>
                  {t("0664-211141")}
                </span>
              </li>
              <li>
                <a
                  data-tracking="click"
                  data-value=""
                  rel="nofollow"
                  href={formatUrl(`/${lang}/contact`, lang)}
                  className={`${NavbarCss.anchor_black} ${
                    activeRoute.includes("/contact") ? NavbarCss.active : ""
                  }`}
                >
                  <i aria-hidden className="fas fa-envelope"></i>
                  {t("Contact")}
                </a>
              </li>
              {/* <li>
                <span
                  style={{ color: "black", cursor: "pointer" }}
                  onClick={() => openAuthDialog()}
                >
                  <ConnexionIcon /> {t("Connexion")}
                </span>
              </li> */}
            </ul>
          )}
          {navBarType === "NORMAL" && (
            <ul
              className={`${NavbarCss.navbarPortion} ${NavbarCss.navbarPortionFullwidth}`}
            >
              <li>
                <div className={`${NavbarCss.dropdown}`}>
                  <div
                    data-tracking="click"
                    data-value="dropdown"
                    className={`${NavbarCss.dropbtn}`}
                  >
                    <span>{t("Acheter")}</span>
                    <span className={`${NavbarCss.dropFaDown}`}>
                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.4987 7.5L10.6654 13.3333L4.83203 7.5"
                          stroke="black"
                          stroke-width="1.8"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className={`${NavbarCss.dropdown_content}`}>
                    <a
                      data-tracking="click"
                      data-value=""
                      className={`${NavbarCss.anchor_black} ${NavbarCss.dropdown_item}`}
                      href={formatUrl(`/${lang}/acheter`, lang)}
                    >
                      {t("Appartements et maisons à vendre")}
                    </a>
                    <a
                      data-tracking="click"
                      data-value=""
                      rel="noindex,nofollow"
                      className={`${NavbarCss.anchor_black} ${NavbarCss.dropdown_item}`}
                      href={formatUrl(
                        `/${lang}/list.htm?transaction_type=Projets Neufs`,
                        lang
                      )}
                    >
                      {t("Immobilier Neuf")}
                    </a>
                  </div>
                </div>
              </li>
              <li>
                <a
                  data-tracking="click"
                  data-value=""
                  rel="noindex, nofollow"
                  href={formatUrl(
                    `/${lang}/list.htm?transaction_type=Location`,
                    lang
                  )}
                  className={`${NavbarCss.anchor_black} ${
                    activeRoute.includes("/louer") ||
                    activeRoute.includes("transaction_type=Location")
                      ? NavbarCss.active
                      : ""
                  }`}
                >
                  {t("Louer")}
                </a>
              </li>
              <li>
                <div className={`${NavbarCss.dropdown}`}>
                  <div
                    data-tracking="click"
                    data-value="dropdown"
                    className={`${NavbarCss.dropbtn}`}
                  >
                    <span
                      className={`${
                        activeRoute.includes("/estimation")
                          ? NavbarCss.active
                          : ""
                      }`}
                    >
                      {t("Vendre")}
                    </span>
                    <span className={`${NavbarCss.dropFaDown}`}>
                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.4987 7.5L10.6654 13.3333L4.83203 7.5"
                          stroke="black"
                          stroke-width="1.8"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className={`${NavbarCss.dropdown_content}`}>
                    <a
                      data-tracking="click"
                      data-value=""
                      className={`${NavbarCss.anchor_black} ${NavbarCss.dropdown_item}`}
                      href={formatUrl(`/${lang}/vendre-rapidement`, lang)}
                    >
                      {t("Vendre rapidement")}
                    </a>
                    <a
                      data-tracking="click"
                      data-value=""
                      className={`${NavbarCss.anchor_black} ${NavbarCss.dropdown_item}`}
                      rel="noindex, nofollow"
                      href={formatUrl(
                        `/${lang}/estimation/map?version=vente`,
                        lang
                      )}
                    >
                      {t("Estimation immobilière")}
                    </a>{" "}
                    <a
                      data-tracking="click"
                      data-value=""
                      className={`${NavbarCss.anchor_black} ${NavbarCss.dropdown_item}`}
                      href={formatUrl(
                        `/${lang}/publier-une-annonce-proprietaire`,
                        lang
                      )}
                    >
                      {t("Publier une annonce")}
                    </a>{" "}
                    <a
                      data-tracking="click"
                      data-value=""
                      className={`${NavbarCss.anchor_black} ${NavbarCss.dropdown_item}`}
                      href={formatUrl(
                        `/${lang}/comment-reussir-votre-vente-immobilier-au-maroc`,
                        lang
                      )}
                    >
                      {t("Comment vendre votre maison ?")}
                    </a>
                  </div>
                </div>
              </li>
              <li>
                <a
                  data-tracking="click"
                  data-value=""
                  href={formatUrl(`/${lang}/prix-immobilier-maroc`, lang)}
                  className={`${NavbarCss.anchor_black} ${
                    activeRoute.includes("/prix-immobilier-maroc")
                      ? NavbarCss.active
                      : ""
                  }`}
                >
                  {t("Prix immobiliers")}
                </a>
              </li>
              <li>
                <div className={`${NavbarCss.dropdown}`}>
                  <div
                    data-tracking="click"
                    data-value="dropdown"
                    className={`${NavbarCss.dropbtn}`}
                  >
                    <span
                      className={`${
                        activeRoute.includes(`/${lang}/credit-immobilier`)
                          ? NavbarCss.active
                          : ""
                      }`}
                    >
                      {t("Crédit immobilier")}
                    </span>
                    <span className={`${NavbarCss.dropFaDown}`}>
                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.4987 7.5L10.6654 13.3333L4.83203 7.5"
                          stroke="black"
                          stroke-width="1.8"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className={`${NavbarCss.dropdown_content}`}>
                    <a
                      data-tracking="click"
                      data-value=""
                      className={`${NavbarCss.anchor_black} ${NavbarCss.dropdown_item}`}
                      href={formatUrl(`/${lang}/credit-immobilier-maroc`, lang)}
                    >
                      {t("Crédit immobilier")}
                    </a>
                    <a
                      data-tracking="click"
                      data-value=""
                      className={`${NavbarCss.anchor_black} ${NavbarCss.dropdown_item}`}
                      href={formatUrl(
                        `/${lang}/credit-immobilier/demander-un-credit`,
                        lang
                      )}
                    >
                      {t("Demander un crédit")}
                    </a>
                    <a
                      data-tracking="click"
                      data-value=""
                      className={`${NavbarCss.anchor_black} ${NavbarCss.dropdown_item}`}
                      href={formatUrl(
                        `/${lang}/credit-immobilier/simulateur-credit-immobilier`,
                        lang
                      )}
                    >
                      {t("Calculateur de budget")}
                    </a>
                    <a
                      data-tracking="click"
                      data-value=""
                      className={`${NavbarCss.anchor_black} ${NavbarCss.dropdown_item}`}
                      href={formatUrl(
                        `/${lang}/credit-immobilier/simulateur-de-mensualite-credit`,
                        lang
                      )}
                    >
                      {t("Calculateur de Mensualité")}
                    </a>
                  </div>
                </div>
              </li>
              <li>
                <a
                  data-tracking="click"
                  data-value=""
                  href={formatUrl(`/${lang}/agence-immobiliere-maroc`, lang)}
                  className={`${NavbarCss.anchor_black} ${
                    activeRoute.includes("/agence-immobiliere-maroc")
                      ? NavbarCss.active
                      : ""
                  }`}
                >
                  {t("Trouver une agence")}
                </a>
              </li>
            </ul>
          )}
          {navBarType === "CREDIT" && (
            <ul
              className={`${NavbarCss.navbarPortion} ${NavbarCss.navbarPortionFullwidth}`}
            >
              <li>
                <a
                  data-tracking="click"
                  data-value=""
                  href={formatUrl(`/${lang}`, lang)}
                >
                  {t("Accueil")}
                </a>
              </li>

              <li>
                <div className={`${NavbarCss.dropdown}`}>
                  <div
                    data-tracking="click"
                    data-value="dropdown"
                    className={`${NavbarCss.dropbtn}`}
                  >
                    <span
                      className={`${
                        activeRoute.includes(
                          "/credit-immobilier/simulateur-credit-immobilier"
                        ) ||
                        activeRoute.includes(
                          "/credit-immobilier/simulateur-de-mensualite-credit"
                        )
                          ? NavbarCss.active
                          : ""
                      }`}
                    >
                      {t("Calculateurs")}
                    </span>
                    <span className={`${NavbarCss.dropFaDown}`}>
                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.4987 7.5L10.6654 13.3333L4.83203 7.5"
                          stroke="black"
                          stroke-width="1.8"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className={`${NavbarCss.dropdown_content}`}>
                    <a
                      data-tracking="click"
                      data-value=""
                      className={`${NavbarCss.anchor_black} ${NavbarCss.dropdown_item}`}
                      href={formatUrl(
                        `/${lang}/credit-immobilier/simulateur-credit-immobilier`,
                        lang
                      )}
                    >
                      {t("Calculateur de budget")}
                    </a>
                    <a
                      data-tracking="click"
                      data-value=""
                      className={`${NavbarCss.anchor_black} ${NavbarCss.dropdown_item}`}
                      href={formatUrl(
                        `/${lang}/credit-immobilier/simulateur-de-mensualite-credit`,
                        lang
                      )}
                    >
                      {t("Calculateur de Mensualité")}
                    </a>
                  </div>
                </div>
              </li>
              <li>
                <a
                  data-tracking="click"
                  data-value=""
                  href={formatUrl(
                    `/${lang}/credit-immobilier/demander-un-credit`,
                    lang
                  )}
                  className={`${NavbarCss.anchor_black} ${
                    activeRoute.includes(
                      "/credit-immobilier/demander-un-credit"
                    )
                      ? NavbarCss.active
                      : ""
                  }`}
                >
                  {t("Demander un crédit")}
                </a>
              </li>
              <li>
                <a
                  data-tracking="click"
                  data-value=""
                  href={formatUrl(`/${lang}/guide-du-credit`, lang)}
                  target="__blank"
                >
                  {t("Guide du crédit")}
                </a>
              </li>
            </ul>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <div className={`${NavbarCss.lang_not_connected}`}>
              <a
                data-tracking="click"
                data-value=""
                rel={
                  router.asPath.includes("list") ||
                  router.asPath.includes("map") ||
                  router.asPath.includes("?") ||
                  router.asPath.includes("conditions") ||
                  router.asPath.includes("contact")
                    ? "nofollow noopener noreferrer noindex"
                    : "follow"
                }
                href={formatUrl(`${router.asPath}`, lang == "fr" ? "ar" : "fr")}
              >
                <span
                  className={`${NavbarCss.link} ${
                    lang !== "fr"
                      ? NavbarCss.lang_container_normal
                      : NavbarCss.lang_container_reverse
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="16"
                    viewBox="0 0 21 16"
                    fill="none"
                  >
                    <path
                      d="M4.82335 9.81953L4.36634 10.939H2.72656L5.43271 4.86768H7.07334L9.71787 10.939H8.01476L7.5663 9.82038H4.82335V9.81953ZM6.20381 6.40304L5.28978 8.6496H7.09987L6.20381 6.40304Z"
                      fill="black"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2.761 0.297485H18.611C19.9033 0.297485 20.956 1.31593 20.956 2.56716V13.4328C20.956 14.6849 19.9033 15.7025 18.611 15.7025H2.761C1.46869 15.7025 0.416016 14.684 0.416016 13.4328V2.56716C0.416016 1.31593 1.46783 0.297485 2.761 0.297485ZM2.761 14.5103H10.686V1.49052H2.761C2.14737 1.49052 1.64842 1.97321 1.64842 2.56716V13.4337C1.64842 14.0268 2.14737 14.5103 2.761 14.5103ZM17.7038 4.81971H16.3345C16.1291 4.81971 15.9348 4.84367 15.7525 4.89074C14.7024 5.28271 14.1778 5.98279 14.1778 6.99267C14.1778 7.44369 14.2805 7.83567 14.4859 8.16773V8.20367C14.4379 8.20269 14.3906 8.21482 14.349 8.23876C13.8244 8.52376 13.5616 8.93969 13.5616 9.48571C13.5616 9.66972 13.5847 9.84773 13.6301 10.0198C13.8526 10.6616 14.2745 10.9817 14.8967 10.9817H16.9507L17.0192 10.9458C16.6828 10.2577 16.3174 9.91277 15.9237 9.91277H14.8282C14.6631 9.91277 14.5484 9.81863 14.4859 9.62778C14.509 9.34364 14.6228 9.20072 14.8282 9.20072H17.6354C17.6807 9.20072 17.7038 9.17676 17.7038 9.12969V8.20367C17.7038 8.16174 17.6807 8.13777 17.6354 8.13264H16.0264C15.5583 8.13264 15.2502 7.77576 15.1021 7.06285C15.1021 6.94474 15.1132 6.88569 15.1363 6.88569C15.1363 6.39872 15.4556 6.06666 16.0949 5.88779H16.7111C17.0765 5.88779 17.43 5.55573 17.7723 4.89074C17.7723 4.84966 17.7492 4.8257 17.7038 4.81971Z"
                      fill="black"
                    />
                  </svg>

                  {lang !== "fr" ? "Français" : "العربية"}
                </span>
              </a>
            </div>
            {!activeRoute.includes("/credit-immobilier") ? (
              <div className={NavbarCss.offerProCont}>
                <a
                  data-tracking="click"
                  data-value=""
                  className={NavbarCss.offres_pro}
                  href={formatUrl(`/${lang}/offres-pro`, lang)}
                >
                  <span className={NavbarCss.offreProText}>
                    {t("Offres pro")}
                  </span>
                  <span>
                    <svg
                      width="17"
                      height="18"
                      viewBox="0 0 17 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.43222 3.48665V5.32443H1.83778V15.4322H11.9456V10.8378H13.7833V16.3511C13.7833 16.8586 13.3719 17.27 12.8644 17.27H0.918889C0.411401 17.27 0 16.8586 0 16.3511V4.40554C0 3.89805 0.411401 3.48665 0.918889 3.48665H6.43222ZM16.54 0.72998V8.08109H14.7022V3.86615L7.54132 11.028L6.24201 9.72866L13.402 2.56776H9.18889V0.72998H16.54Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </a>
              </div>
            ) : null}
            {activeRoute.includes("/credit-immobilier") ? (
              <div
                data-tracking="click"
                data-value="plusdinfo"
                style={{ marginRight: "15px" }}
              >
                <PlusInformationBtn
                  title={t("Plus d'information")}
                  onClick={() => {}}
                  styleClass={NavbarCss.btnStyle}
                />
              </div>
            ) : null}
            <div ref={node} className={`${NavbarCss.dropdown}`}>
              <button
                data-tracking="click"
                data-value="dropdown-profil"
                className={`${NavbarCss.dropbtn}`}
                onClick={() => {
                  if (user) {
                    if (user._id) {
                      setshowDropdown(!showDropdown);
                    } else {
                      openAuthDialog();
                    }
                  } else {
                    openAuthDialog();
                  }
                }}
              >
                <ProfilIcon />
                <span
                  data-tracking="click"
                  data-value="user"
                  className={NavbarCss.userName}
                  style={{ width: user && user._id ? "90px" : "" }}
                >
                  {user && user._id
                    ? `${
                        user.firstName
                          ? user.firstName
                          : user.firstname
                          ? user.firstname
                          : t("Mon Compte")
                      } ${
                        user.lastName
                          ? user.lastName
                          : user.lastname
                          ? user.lastname
                          : ""
                      }`
                    : t("Connexion")}
                </span>
                {user && user._id ? (
                  <span className={`${NavbarCss.dropFaDown}`}>
                    <svg
                      width="14"
                      height="9"
                      viewBox="0 0 14 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.8346 1.5L7.0013 7.33333L1.16797 1.5"
                        stroke="black"
                        stroke-width="1.8"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                ) : null}
              </button>

              {showDropdown ? (
                <div
                  className={`${NavbarCss.dropdown_content_nav}`}
                  style={{ marginTop: "12px" }}
                >
                  <div
                    style={{
                      width: "18px",
                      height: "18px",
                      position: "relative",
                      top: "-17px",
                      right: "-222px",
                    }}
                  >
                    <div className={NavbarCss.caret}></div>
                  </div>

                  {user?.isPro ? (
                    <div
                      style={{
                        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        padding: "0px 23px 16px 23px",
                      }}
                    >
                      <a
                        data-tracking="click"
                        data-value=""
                        style={{
                          backgroundColor: "transparent",

                          fontWeight: "500",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#460af0",
                        }}
                        href={formatUrl(`/${lang}/espace-pro`, lang)}
                      >
                        {t("Accéder à mon espace pro")}
                      </a>
                      <span>
                        <svg
                          width="10"
                          height="18"
                          viewBox="0 0 10 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.999999 1L9 9L1 17"
                            stroke="#460AF0"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </div>
                  ) : null}

                  <div className={NavbarCss.dropdownItems}>
                    <a
                      data-tracking="click"
                      data-value=""
                      className={`${NavbarCss.link}`}
                      rel="noindex, nofollow"
                      href={formatUrl(`/${lang}/user/saved`, lang)}
                    >
                      {t("Annonces aimées")}
                    </a>
                  </div>
                  <div className={NavbarCss.dropdownItems}>
                    <a
                      data-tracking="click"
                      data-value=""
                      className={`${NavbarCss.link}`}
                      rel="noindex, nofollow"
                      href={formatUrl(`/${lang}/user/recherches`, lang)}
                    >
                      {t("Mes recherches sauvegardées")}
                    </a>
                  </div>
                  <div className={NavbarCss.dropdownItems}>
                    <a
                      data-tracking="click"
                      data-value=""
                      className={`${NavbarCss.link}`}
                      rel="noindex, nofollow"
                      href={formatUrl(`/${lang}/user/estimates`, lang)}
                    >
                      {t("Mes estimations")}
                    </a>
                  </div>
                  <div className={NavbarCss.dropdownItems}>
                    <span className={`${NavbarCss.linkDrop}`}>
                      <span
                        data-tracking="click"
                        data-value=""
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                        onClick={() => setshowAnnonces(!showAnnonces)}
                      >
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            color: `${showAnnonces ? "#460af0" : "black"}`,
                          }}
                        >
                          <span>{t("Publier une annonce")}</span>
                        </span>
                        <span>
                          {showAnnonces ? (
                            <svg
                              width="17"
                              height="10"
                              viewBox="0 0 17 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16 9L8.5 1L1 9"
                                stroke="#460af0"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          ) : (
                            <svg
                              width="17"
                              height="10"
                              viewBox="0 0 17 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 0.999999L8.5 9L16 1"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </span>
                      </span>
                      {showAnnonces ? (
                        <ul
                          style={{
                            padding: "0px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "24px",
                            paddingLeft: "10px",
                            marginBottom: 0,
                          }}
                        >
                          <li className={NavbarCss.subItem}>
                            <a
                              data-tracking="click"
                              data-value=""
                              className={NavbarCss.subitemtitle}
                              rel="noindex, nofollow"
                              href={formatUrl(
                                `/${lang}/user/annonces/publier`,
                                lang
                              )}
                            >
                              {t("Publier une annonce")}
                            </a>
                          </li>
                          <li className={NavbarCss.subItem1}>
                            <a
                              data-tracking="click"
                              data-value=""
                              className={NavbarCss.subitemtitle}
                              rel="noindex, nofollow"
                              href={formatUrl(`/${lang}/user/annonces`, lang)}
                            >
                              {t("Annonces publiées")}
                            </a>
                          </li>
                        </ul>
                      ) : null}
                    </span>
                  </div>
                  <div className={NavbarCss.dropdownItems}>
                    <a
                      data-tracking="click"
                      data-value=""
                      className={`${NavbarCss.link}`}
                      rel="noindex, nofollow"
                      href={formatUrl(`/${lang}/user/inbox`, lang)}
                    >
                      {t("Inbox")}
                    </a>
                  </div>
                  <div className={NavbarCss.dropdownItems}>
                    <a
                      data-tracking="click"
                      data-value=""
                      className={`${NavbarCss.link}`}
                      rel="noindex, nofollow"
                      href={formatUrl(`/${lang}/user/settings`, lang)}
                    >
                      {t("Paramètres")}
                    </a>
                  </div>
                  <div
                    data-tracking="click"
                    data-value=""
                    className={NavbarCss.dropdownItems_link}
                  >
                    <Link
                      href={
                        // lang == "fr"
                        // ? window.location.href
                        //     .replace("agenz.ma", "ar.agenz.ma")
                        //     .replace("/fr", "")
                        //     .replace("/ar", "")
                        // : window.location.href
                        //     .replace("ar.agenz.ma", "agenz.ma")
                        //     .replace("/fr", "")
                        //     .replace("/ar", "")
                        // lang == "fr"
                        //   ? `https://ar.agenz.ma${router.asPath}?${router.query}`
                        //   : `https://agenz.ma${router.asPath}?${router.query}`
                        router.asPath
                      }
                      locale={lang == "fr" ? "ar" : "fr"}
                    >
                      <div className={`${NavbarCss.link}`}>
                        <div
                          className={`${NavbarCss.link_lang} ${NavbarCss.dropdownItems_lang}`}
                        >
                          {/* {lang == "fr" && (
                            <span style={{ marginRight: 4 }}> */}
                          <svg
                            width="21"
                            height="16"
                            viewBox="0 0 21 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.40636 9.81953L3.94935 10.939H2.30957L5.01572 4.86768H6.65635L9.30087 10.939H7.59776L7.14931 9.82038H4.40636V9.81953ZM5.78682 6.40304L4.87279 8.6496H6.68288L5.78682 6.40304Z"
                              fill="black"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M2.34498 0.297485H18.195C19.4873 0.297485 20.54 1.31593 20.54 2.56716V13.4328C20.54 14.6849 19.4873 15.7025 18.195 15.7025H2.34498C1.05268 15.7025 0 14.684 0 13.4328V2.56716C0 1.31593 1.05182 0.297485 2.34498 0.297485ZM2.34498 14.5103H10.27V1.49052H2.34498C1.73135 1.49052 1.2324 1.97321 1.2324 2.56716V13.4337C1.2324 14.0268 1.73135 14.5103 2.34498 14.5103ZM17.2878 4.81971H15.9185C15.7131 4.81971 15.5188 4.84367 15.3365 4.89074C14.2864 5.28271 13.7618 5.98279 13.7618 6.99267C13.7618 7.44369 13.8645 7.83567 14.0699 8.16773V8.20367C14.0219 8.20268 13.9746 8.21482 13.933 8.23876C13.4083 8.52376 13.1456 8.93969 13.1456 9.48571C13.1456 9.66972 13.1687 9.84773 13.2141 10.0198C13.4366 10.6616 13.8585 10.9817 14.4807 10.9817H16.5347L16.6032 10.9458C16.2668 10.2577 15.9014 9.91277 15.5077 9.91277H14.4122C14.2471 9.91277 14.1324 9.81863 14.0699 9.62778C14.093 9.34364 14.2068 9.20072 14.4122 9.20072H17.2194C17.2647 9.20072 17.2878 9.17676 17.2878 9.12969V8.20367C17.2878 8.16174 17.2647 8.13777 17.2194 8.13264H15.6104C15.1423 8.13264 14.8342 7.77576 14.6861 7.06285C14.6861 6.94474 14.6972 6.88569 14.7203 6.88569C14.7203 6.39872 15.0396 6.06666 15.6789 5.88779H16.2951C16.6605 5.88779 17.014 5.55573 17.3563 4.89074C17.3563 4.84966 17.3332 4.8257 17.2878 4.81971Z"
                              fill="black"
                            />
                          </svg>
                          {/* </span> */}
                          {/* )} */}
                          <span style={{ color: "black" }}>
                            {lang !== "fr" ? "Français" : "العربية"}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className={` ${NavbarCss.dropdownItems_lang}`}>
                    <a
                      data-tracking="click"
                      data-value=""
                      className={`${NavbarCss.link}`}
                      // style={{ padding: "0px 9px 0px 0px" }}
                      href={formatUrl(`/${lang}/offres-pro`, lang)}
                      style={{ width: "100%", height: "100%" }}
                    >
                      <span>
                        <span style={{ paddingRight: "8px" }}>
                          <svg
                            width="17"
                            height="18"
                            viewBox="0 0 17 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.43222 3.48665V5.32443H1.83778V15.4322H11.9456V10.8378H13.7833V16.3511C13.7833 16.8586 13.3719 17.27 12.8644 17.27H0.918889C0.411401 17.27 0 16.8586 0 16.3511V4.40554C0 3.89805 0.411401 3.48665 0.918889 3.48665H6.43222ZM16.54 0.72998V8.08109H14.7022V3.86615L7.54132 11.028L6.24201 9.72866L13.402 2.56776H9.18889V0.72998H16.54Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        <span>{t("Offres pro")}</span>
                      </span>
                    </a>
                  </div>
                  <button
                    className={NavbarCss.deconnexion_button}
                    // onMouseEnter={() => { setSvgColor("#460af0") }}
                    // onMouseLeave={() => { setSvgColor("black") }}
                    onClick={() => {
                      onLogout();
                      setshowDropdown(false);
                    }}
                  >
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.7452 0.561035C11.208 0.561035 11.5717 0.924716 11.5717 1.38758V3.86723H9.91861V2.21413H1.65313V13.7858H9.91861V12.1327H11.5717V14.6124C11.5717 15.067 11.208 15.4389 10.7452 15.4389H0.826587C0.371984 15.4389 3.8147e-05 15.067 3.8147e-05 14.6124V1.38758C3.8147e-05 0.924716 0.371984 0.561035 0.826587 0.561035H10.7452ZM13.4153 4.48714L13.3992 4.51943L16.2921 7.40427C16.6227 7.72662 16.6227 8.24748 16.2921 8.56983L13.3992 11.4628L12.2336 10.2891L13.6962 8.82652H5.78587V7.17342H13.7479L12.2417 5.66077L13.4153 4.48714Z"
                        fill="black"
                      />
                    </svg>
                    {t("Déconnexion")}
                  </button>
                </div>
              ) : null}
            </div>
            {user && user?.isPro ? (
              <div className={NavbarCss.btnPublierContainer}>
                <a
                  rel="noindex, nofollow"
                  href={formatUrl(`/espace-pro/annonces/publier`, lang)}
                  className={NavbarCss.btnPublierUneAnnonce}
                >
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 5.5V11M11 11V16.5M11 11H16.5M11 11L5.5 11"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {t("PUBLIER UNE ANNONCE")}
                </a>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
