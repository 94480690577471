


let PROXY_CONFIG = `${process.env.NEXT_PUBLIC_CLOUD_ENV == "STAGING" ?
  "https://stage.agenz.ma/api/v2" : process.env.NEXT_PUBLIC_CLOUD_ENV ?
    "https://app.agenz.ma/api/v2" :
    // "https://cloud.agenz.ma/api/v2" 
    // "https://stage.agenz.ma/api/v2"
    "https://stage.agenz.ma/api/v2"
  // "http://localhost:5000/agenz-website-prod/us-central1/apiv2"
  // "http://localhost:5001/apiv2"
  }`;

export const PROXY_CONFIG_AUTOCOMPLETE = `${process.env.NEXT_PUBLIC_CLOUD_ENV == "STAGING" ?
  "https://stage.agenz.ma/api/v2" : process.env.NEXT_PUBLIC_CLOUD_ENV ? "https://app.agenz.ma/api/v2"
    : // "https://cloud.agenz.ma/api/v2" :
    // "https://app.agenz.ma/api/v2"
    "https://stage.agenz.ma/api/v2"
  // "http://localhost:5000/agenz-website-prod/us-central1"
  }`;

export const PROXY_CONFIG_V3 = `${process.env.NEXT_PUBLIC_CLOUD_ENV == "STAGING" ?
  "https://stage.agenz.ma/api/v3" : process.env.NEXT_PUBLIC_CLOUD_ENV ? "https://app.agenz.ma/api/v3"
    // "https://cloud.agenz.ma/api/v3" :
    // "https://cloud.agenz.ma/api/v3"
    : "https://stage.agenz.ma/api/v3"
  // : "http://localhost:5000/agenz-website-prod/us-central1/application"
  }`;

export const PROXY_CONFIG_UPLOAD = `${process.env.NEXT_PUBLIC_CLOUD_ENV == "STAGING"

  ? "https://us-central1-agenz-website-prod.cloudfunctions.net/uploadWatermark" : process.env.NEXT_PUBLIC_CLOUD_ENV ?
    "https://cloud.agenz.ma/api/v2"
    // : "https://cloud.agenz.ma/api/v2"
    // : "https://atlas-test.agenz.ma/api/v2"
    : "https://us-central1-agenz-website-prod.cloudfunctions.net/uploadWatermark"
  // : "https://us-central1-agenz-website-prod.cloudfunctions.net/uploadWatermark"
  }`;

// let t "http://localhost:5000/agenz-website-prod/us-central1/apiv2Tunisie"
// let PROXY_CONFIG = "http://localhost:5000/agenz-website-prod/us-central1/apiv2";
// let PROXY_CONFIG = "http://localhost:5000/agenz-website-prod/us-central1/apiv2Tunisie";
// let PROXY_CONFIG = "http://localhost:5000/agenz-website-prod/us-central1/apiv2";

export const PROXY_CONFIG_PROJETNEUF = "https://api.agenz.ma/api";
// export const PROXY_CONFIG_ESTIMATION = "https://test.valuationapi.agenz.ma/api";

export const PROXY_CONFIG_ESTIMATION = "https://api.agenz.ma/api";

export default PROXY_CONFIG;
