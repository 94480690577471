import { Suspense, useEffect, useState, useRef } from "react";
import Link from "./components/Link";
import Logo from "./assets/Logo";
import style from "./style.module.css";
import ProIcon from "./assets/ProIcon";
import LangSwitchIcon from "./assets/LangSwitchIcon";
import LoginIcon from "./assets/LoginIcon";
import { useRouter } from "next/router";
import formatUrl from "utils/FormatUrl";
import OffrePro from "./components/SubMenuComponent/OffrePro";
import AgenzLogoPro from "assets/logos_svg/agenz_logo_pro.svg";
import dynamic from "next/dynamic";
import Image from "next/image";
import $ from "utils/selectors";

import nookies from "nookies";
import { useDispatch, useSelector } from "react-redux";
import {
  setAuthenticated,
  setEmail,
  setUID,
  setUser as setUserDispatch,
  signInR,
  updateUser,
} from "redux/slices/authReducer";
import { checkUserClientSide } from "utils/BackendHelper";
import {
  actions as UserSpaceActions,
  selectors as UserSpaceSelectors,
} from "../../redux/slices/v2/UserSpace";
import {
  actions as ProSpaceActions,
  selectors as ProSpaceSelectors,
} from "redux/slices/v2/ProSpace";
import useTranslation from "next-translate/useTranslation";
import UserSpaceActionsModal from "./components/UserSpaceActionsModal";
import MenuBar from "./assets/MenuBar";
import MenuX from "./assets/MenuX";
import PlusInformationBtn from "ui/Button/PlusInformationBtn";

const LoginRegisterDialog = dynamic(
  () => import("components/LoginRegisterDialog"),
  {
    ssr: false,
    suspense: true,
  }
);

const MobileDrawer = dynamic(() => import("components/navbar/MobileDrawerv3"), {
  suspense: true,
});
const CreditImmo = dynamic(
  () => import("./components/SubMenuComponent/CreditImmo"),
  {
    suspense: true,
  }
);
const Acheter = dynamic(() => import("./components/SubMenuComponent/Acheter"), {
  suspense: true,
});
const Vendre = dynamic(() => import("./components/SubMenuComponent/Vendre"), {
  suspense: true,
});

function Navbarv2() {
  const [active, setActive] = useState("");
  const { pathname, query } = useRouter();

  const router = useRouter();
  const dispatch = useDispatch();
  const { t, lang } = useTranslation("navbar");
  const isAuthDialogVisibleTrigger = useSelector(
    UserSpaceSelectors.isAuthDialogVisibleSelector
  );
  useEffect(() => {
    if (isAuthDialogVisibleTrigger) {
      setAuthDialogVisible(true);
      dispatch(UserSpaceActions.setIsAuthDialogVisible({ data: false }));
    }
  }, [isAuthDialogVisibleTrigger]);
  const authenticatedRedux = useSelector(
    (store: any) => store?.auth?.authenticated
  );
  const userRedux = useSelector((store: any) => store?.auth?.user);
  const authenticatedProRedux = useSelector(
    (store: any) => store?.auth?.authenticatedPro
  );

  const [user, setUser] = useState(userRedux);
  useEffect(() => {
    setUser(userRedux);
  }, [userRedux]);
  const [activeRoute, setActiveRoute] = useState(router.pathname);
  const buttonClose = useSelector(ProSpaceSelectors.drawerOpenSelector);

  const [isAuthDialogVisible, setAuthDialogVisible] = useState(false);
  const [checkProPath, setCheckProPath] = useState<boolean>(false);

  const [checkListPath, setCheckListPath] = useState<boolean>(false);

  useEffect(() => {
    if (router.pathname.startsWith("/espace-pro")) setCheckProPath(true);
  }, [checkProPath]);

  const signInSuccess = (userData) => {
    setUser(userData);
    setAuthDialogVisible(false);
    if (userData.isPro) {
      window.location.href = `/${lang}/espace-pro/vitrine/gerermavitrine`;
    }
  };

  const getNavType = (pathname: string, authenticated: boolean) => {
    // /estimation/appartement/resultat?token=74bd70ea-f86b-4138-abf6-b5cb77ee2d65
    if (pathname.includes("user")) {
      return "NORMAL";
    } else if (pathname.includes("estimation")) {
      //on est dans l 'estimation
      if (!pathname.includes("estimation-")) {
        // on est pas dans la homePage d'estimation => on est dans estimation stepper
        if (pathname.includes("estimation") && pathname.includes("resultat")) {
          // on est dans les resultats
          if (!authenticated) {
            // resultat mais sans authentification

            return "ESTIMATION";
          } else {
            // authentifier => on affiche la navbar normale

            return "NORMAL";
          }
        } else {
          // on est dans estimation/map , estimation/bien ..

          return "ESTIMATION";
        }
      }
    }
    if (
      pathname.startsWith("/offres-pro") ||
      pathname.startsWith("/temoignages")
    ) {
      // on est dans une des pages de l'offre pro
      return "OFFRE_PRO";
    }
    if (
      pathname.startsWith("/espace-pro") ||
      pathname.startsWith("/espace-pro")
    ) {
      // on est dans espace pro => espace pro a son propre Navbar
      return "PRO_SPACE";
    }
    if (pathname.startsWith("/guide-du-credit")) {
      return "CREDIT";
    }
    if (pathname.startsWith("/credit")) {
      return "CREDIT";
    }
    return "NORMAL";
  };

  useEffect(() => {
    setActiveRoute(router.pathname);
  }, [router.pathname]);

  useEffect(() => {
    checkUserClientSide(undefined, dispatch);
  }, []);
  const onLogout = () => {
    dispatch(updateUser({ data: null }));
    dispatch(signInR({ data: null }));
    dispatch(setUID({ data: null }));
    dispatch(setEmail({ data: null }));
    dispatch(setUserDispatch({ data: null }));
    localStorage.removeItem("FBIdToken");
    localStorage.removeItem("RT");
    nookies.set(undefined, "AccessToken", "", {
      path: "/",
    });
    nookies.destroy(undefined, "AccessToken");
    nookies.set(undefined, "FBIdToken", "", {
      path: "/",
    });
    nookies.destroy(undefined, "FBIdToken");
    nookies.set(undefined, "RT", "", {
      path: "/",
    });
    nookies.destroy(undefined, "RT");

    dispatch(setUserDispatch(null));
    dispatch(setAuthenticated(false));
  };

  const authenticated = authenticatedRedux || authenticatedProRedux;

  const navBarType = getNavType(activeRoute, authenticated);

  const [showDropdown, setShowDropdown] = useState(false);
  const loggedDropRef = useRef(null);
  const sectionRef = useRef(null);
  const linksRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const loggedDropEv = loggedDropRef.current;
      if (loggedDropEv && !loggedDropEv.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [openDrawer, setOpendrawer] = useState(false);
  const [menuClicked, setMenuClicked] = useState(false);

  useEffect(() => {
    if (menuClicked) {
      $("body").css("overflow", "hidden");
    } else {
      if (
        router.pathname.startsWith("/list.htm") ||
        router.pathname.startsWith("/map.htm")
      )
        $("body").css("overflow", "hidden");
      else $("body").css("overflow", "scroll");
    }
  }, [menuClicked]);

  const [dropDownCalcules, setDropDownCalcules] = useState(false);

  const [language, setLanguage] = useState(lang);
  const [openLang, setOpenLang] = useState(false);
  const langRef = useRef(null);
  const handleClickOutside = (event) => {
    if (langRef.current && !langRef.current.contains(event.target)) {
      setOpenLang(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [scrollY, setScrollY] = useState(0);

  const updateScrollY = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", updateScrollY);

    return () => {
      window.removeEventListener("scroll", updateScrollY);
    };
  }, []);

  return (
    <div
      className={`${style.navbarParentContainer} ${pathname.startsWith("/list.htm") ||
        pathname.startsWith("/map.htm") ||
        pathname.startsWith("/annonces/immo")
        ? style.lisitngCase
        : null
        } ${scrollY > 220 ? style.navbarBoxShadow : ""}`}
    >
      {!checkProPath && navBarType !== "ESTIMATION" ? (
        <Suspense fallback={``}>
          <LoginRegisterDialog
            visible={isAuthDialogVisible}
            onClose={() => setAuthDialogVisible(false)}
            onFacebookSignInSuccess={(userData) => signInSuccess(userData)}
            onGoogleSignInSuccess={(userData) => signInSuccess(userData)}
            onRegisterSucccess={(userData) => signInSuccess(userData)}
            onSignInSuccess={(userData) => signInSuccess(userData)}
            onSwitchSignType={() => {
              return;
            }}
            Estimation={false}
          />
        </Suspense>
      ) : null}
      <div
        className={style.navbarContainer}
        style={{
          boxShadow: menuClicked ? "0 0.2px 0 rgba(0, 0, 0, .25)" : "none",
        }}
      >
        <div className={style.navLinksContainer}>
          <div className={style.logoContainer}>
            {pathname.startsWith("/espace-pro") ||
              pathname.startsWith("/offres-pro") ? (
              <img
                width={123}
                height={35}
                loading="lazy"
                src={AgenzLogoPro.src}
                alt="agenz logo"
              />
            ) : pathname.startsWith("/credit-immobilier") ? (
              <a
                data-tracking="click"
                data-value="navbar-logo-link"
                href={formatUrl(`/${lang}/credit-immobilier-maroc`, lang)}
              >
                <Image
                  width={128}
                  height={42}
                  loading="lazy"
                  src={
                    "https://storage.googleapis.com/headers-agenz/logo-agenz/Agenz_credit_immo.webp"
                  }
                  alt="agenz logo"
                />
              </a>
            ) : (
              <a href={formatUrl("/", lang)}>
                <Logo />
              </a>
            )}
          </div>
          {!pathname.startsWith("/espace-pro") &&
            !pathname.startsWith("/estimation/map") ? (
            // pathname.startsWith("/credit-immobilier")
            <div className={style.linksContainer} ref={linksRef}>
              <div
                style={{ height: "100%" }}
                onMouseEnter={() => {
                  if (active !== "Acheter") setActive("Acheter");
                  else setActive("");
                }}
                onMouseLeave={() => {
                  setActive("");
                }}
              >
                {active === "Acheter" ? (
                  <Suspense fallback={<></>}>
                    <Acheter lang={lang} t={t} />
                  </Suspense>
                ) : null}
                <Link
                  active={
                    pathname.startsWith("/acheter") || active === "Acheter"
                  }
                  linkTo={formatUrl(
                    `/${lang}/list.htm?transaction_type=vente`,
                    lang
                  )}
                  value={t("Acheter")}
                  isSubNav={false}
                  activeCurrent={active}
                  rel={"noindex nofollow"}
                />
              </div>

              <div
                style={{ height: "100%" }}
                onMouseEnter={() => {
                  if (active !== "Vendre") setActive("Vendre");
                  else setActive("");
                }}
                onMouseLeave={() => {
                  setActive("");
                }}
              >
                {active === "Vendre" ? (
                  <Suspense fallback={<></>}>
                    <Vendre lang={lang} t={t} />
                  </Suspense>
                ) : null}
                <Link
                  active={active === "Vendre"}
                  value={t("Vendre")}
                  isSubNav={false}
                  linkTo={formatUrl(`/${lang}/vendre-rapidement`, lang)}
                />
              </div>
              <div
                style={{ height: "100%" }}
                onMouseEnter={() => {
                  if (active !== "Crédit immobilier")
                    setActive("Crédit immobilier");
                  else setActive("");
                }}
                onMouseLeave={() => {
                  setActive("");
                }}
              >
                {active === "Crédit immobilier" ? (
                  <Suspense fallback={<></>}>
                    <CreditImmo lang={lang} t={t} />
                  </Suspense>
                ) : null}
                <Link
                  active={
                    active === "Crédit immobilier"
                  }
                  value={t("Crédit immobilier")}
                  isSubNav={true}
                  onClick={() => {
                    if (active !== "Crédit immobilier")
                      setActive("Crédit immobilier");
                    else setActive("");
                  }}
                />
              </div>
              <div
                style={{ height: "100%" }}
                onMouseEnter={() => {
                  if (active !== "Prix immobilier")
                    setActive("Prix immobilier");
                  else setActive("");
                }}
                onMouseLeave={() => {
                  setActive("");
                }}
              >
                <Link
                  active={
                    active === "Prix immobilier"
                  }
                  value={t("Prix immobilier")}
                  isSubNav={false}
                  linkTo={formatUrl("/prix-immobilier-maroc", lang)}
                  onClick={() => {
                    setActive("Prix immobilier");
                  }}
                />
              </div>
              <div
                style={{ height: "100%" }}
                onMouseEnter={() => {
                  if (active !== "Louer") setActive("Louer");
                  else setActive("");
                }}
                onMouseLeave={() => {
                  setActive("");
                }}
              >
                <Link
                  active={
                    query.transaction_type == "location" ||
                    active === "Louer"
                  }
                  value={t("Louer")}
                  isSubNav={false}
                  linkTo={formatUrl(
                    // `/${lang}/list.htm?transaction_type=location`,
                    `/louer`,
                    lang
                  )}
                  onClick={() => {
                    setActive("Louer");
                  }}
                />
              </div>
              {/* <div
              style={{ height: "100%" }}
              onMouseEnter={() => {
                if (active !== "Offres pro") setActive("Offres pro");
                else setActive("");
              }}
              onMouseLeave={() => {
                setActive("");
              }}
            >
              {active === "Offres pro" ? <OffrePro lang={lang} t={t} /> : null}
              <Link
                active={
                  pathname.startsWith("/espace-pro") || active === "Offres pro"
                }
                value={t("Offres pro")}
                isSubNav={true}
                icon={<ProIcon />}
                onClick={() => {
                  if (active !== "Offres pro") setActive("Offres pro");
                  else setActive("");
                }}
              />
            </div> */}
            </div>
          ) : null}
          {/* {pathname.startsWith("/credit-immobilier") ? (
            <div className={style.linksContainer} ref={linksRef}>
              <Link
                value={t("Accueil")}
                isSubNav={false}
                linkTo={formatUrl("/", lang)}
              />
              <div
                className={style.linkArrow}
                onMouseEnter={() => {
                  setDropDownCalcules(true);
                }}
                onMouseLeave={() => {
                  setDropDownCalcules(false);
                }}
              >
                <Link value={t("Calculateurs")} isSubNav={true} linkTo={""} />
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.4987 7.5L10.6654 13.3333L4.83203 7.5"
                    stroke="black"
                    stroke-width="1.8"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {dropDownCalcules ? (
                  <div className={style.dropdownCalcules}>
                    <Link
                      value={t("Calculer mon budget")}
                      isSubNav={false}
                      linkTo={formatUrl(
                        "/credit-immobilier/simulateur-credit-immobilier",
                        lang
                      )}
                      dropdown={true}
                    />
                    <Link
                      value={t("Simuler mes mensualités")}
                      isSubNav={false}
                      linkTo={formatUrl(
                        "/credit-immobilier/simulateur-de-mensualite-credit",
                        lang
                      )}
                      dropdown={true}
                    />
                  </div>
                ) : null}
              </div>
              <Link
                value={t("Demande un crédit")}
                isSubNav={false}
                linkTo={formatUrl(
                  "/credit-immobilier/demander-un-credit",
                  lang
                )}
              />
              <Link
                value={t("Guide du crédit")}
                isSubNav={false}
                linkTo={formatUrl("/guide-du-credit", lang)}
              />
            </div>
          ) : null} */}
        </div>
        <div className={style.actionsNavContainer}>
          <div
            data-tracking="click"
            data-value=""
            className={style.langSwicthBtn}
            onClick={() => {
              setOpenLang(!openLang);
            }}
            ref={langRef}
          >
            <LangSwitchIcon />
            {lang === "fr" ? "Français" : lang === "en" ? "English" : "العربية"}
            {openLang ? (
              <div className={style.langOptions}>
                <a
                  className={language == "fr" ? style.activeLang : ""}
                  href={`/fr${router.asPath}`}
                  onClick={() => {
                    setLanguage("fr");
                  }}
                >
                  Français
                </a>
                {/* <a
                  className={language == "en" ? style.activeLang : ""}
                  href={`/en${router.asPath}`}
                  onClick={() => {
                    setLanguage("en");
                  }}
                >
                  English
                </a> */}
                <a
                  className={language == "ar" ? style.activeLang : ""}
                  href={`/ar${router.asPath}`}
                  onClick={() => {
                    setLanguage("ar");
                  }}
                >
                  العربية
                </a>
              </div>
            ) : null}
            <span style={{ rotate: openLang ? "0deg" : "180deg" }}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.59883 12.3994L10.1988 6.79941L15.7988 12.3994"
                  stroke="#00111A"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <div>
            {user?.isPro && !pathname.startsWith("/credit-immobilier") ? (
              <a
                className={style.buttonFill}
                rel="noindex, nofollow"
                href={formatUrl("/espace-pro/annonces/publier", lang)}
              >
                {t("Publier une annonce")}
              </a>
            ) : pathname.startsWith("/credit-immobilier") ? (
              <div data-tracking="click" data-value="plusdinfo">
                <PlusInformationBtn
                  title={t("Plus d'information")}
                  styleClass={style.btnPlusinfo}
                />
              </div>
            ) : (
              <a
                className={style.buttonFill}
                href={formatUrl("/estimation-immobiliere", lang)}
              >
                {t("Estimez votre bien")}
              </a>
            )}
          </div>
          <div
            // data-tracking="click"
            // data-value="dropdown-profil"
            className={style.connexion}
            ref={loggedDropRef}
          // onClick={() => {
          //   if (user && user._id) {
          //     setShowDropdown(!showDropdown);
          //   } else {
          //     setAuthDialogVisible(true);
          //   }
          // }}
          >
            <div
              data-tracking="click"
              data-value="dropdown-profil"
              className={style.connexion}
              // ref={loggedDropRef}
              onClick={() => {
                if (user && user._id) {
                  setShowDropdown(!showDropdown);
                } else {
                  setAuthDialogVisible(true);
                }
              }}
            >
              <LoginIcon />
              {user && user._id
                ? `${user.firstName
                  ? user.firstName
                  : user.firstname
                    ? user.firstname
                    : t("Mon Compte")
                } ${user.lastName
                  ? user.lastName
                  : user.lastname
                    ? user.lastname
                    : ""
                }`
                : t("Connexion")}
              {user && user._id ? (
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.4987 7.5L10.6654 13.3333L4.83203 7.5"
                    stroke="black"
                    stroke-width="1.8"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : null}
            </div>
            {user && user._id && showDropdown ? (
              <UserSpaceActionsModal
                onLogout={onLogout}
                setShowDropDown={setShowDropdown}
                t={t}
                user={user}
                lang={lang}
              />
            ) : null}
            {/* </a> */}
          </div>
        </div>
        <div
          className={style.menuBarMobile}
          onClick={() => {
            setMenuClicked(!menuClicked);
            setOpendrawer(!openDrawer);
            if (!buttonClose) dispatch(ProSpaceActions.openDrawer());
            else dispatch(ProSpaceActions.closeDrawer());
          }}
        >
          <span>{t("Menu")}</span>
          {!buttonClose ? <MenuBar /> : <MenuX />}
        </div>
        {openDrawer && !pathname.startsWith("/espace-pro") ? (
          <Suspense fallback={<></>}>
            <MobileDrawer
              user={user}
              closeMenu={() => {
                setMenuClicked(false);
                setOpendrawer(false);
              }}
              openLoginModal={() => {
                setAuthDialogVisible(true);
              }}
              onLogout={onLogout}
              menuClicked={menuClicked}
            />
          </Suspense>
        ) : null}
      </div>
    </div>
  );
}

export default Navbarv2;
