import ArrowLink from "components/Navbarv2/assets/ArrowLink/ArrowLink";
import React from "react";
import style from "./style.module.css";
import Acheter from "../SubMenuComponent/Acheter";
import Vendre from "../SubMenuComponent/Vendre";

interface dataType {
  active?: boolean;
  linkTo?: string | null;
  value: string;
  isSubNav?: boolean;
  icon?: any;
  fromSub?: boolean;
  onClick?: () => void;
  color?: string;
  arrow?: any;
  onMouseEnter?: any;
  onMouseLeave?: any;
  activeCurrent?: string;
  lang?: any;
  t?: any;
  dropdown?: any;
  rel?: string;
}

function index({
  active,
  linkTo,
  value,
  isSubNav,
  onClick,
  icon,
  fromSub,
  color,
  arrow,
  onMouseEnter,
  onMouseLeave,
  activeCurrent,
  lang,
  t,
  dropdown,
  rel,
}: // ref
dataType) {
  return (
    <div
      className={`${style.linkcontainer} ${active ? style.linkActive : null}`}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      style={{
        display: arrow ? "flex" : "",
        justifyContent: arrow ? "flex-start" : "",
        alignItems: arrow ? "center" : "",
        gap: arrow ? "8px" : "",
      }}
    >
      {!isSubNav ? (
        <a
          className={`${style.anchorLink} ${
            fromSub ? style.anchorLinkSubMenu : null
          } ${dropdown ? style.anchorNoborder : ""}`}
          href={linkTo}
          rel={
            rel
              ? rel
              : linkTo.includes("list") ||
                linkTo.includes("map") ||
                linkTo.includes("?") ||
                linkTo.includes("conditions") ||
                linkTo.includes("contact")
              ? "nofollow noopener noreferrer noindex"
              : "follow"
          }
          style={{
            color: color,
            padding: arrow ? "0px" : "",
            borderBottom: fromSub ? "none" : "",
          }}
        >
          {icon ? icon : null}
          {value}
        </a>
      ) : (
        <span
          className={`${style.anchorLink} ${
            fromSub ? style.anchorLinkSubMenu : null
          } ${dropdown ? style.anchorNoborder : ""}`}
          style={{
            color: color,
            padding: arrow ? "0px" : "",
            cursor: "default",
            borderBottom: fromSub ? "none" : "",
          }}
        >
          {icon ? icon : null}
          {value}
        </span>
      )}
      {arrow ? <ArrowLink /> : null}
      {active ? <div className={style.bottomBar}></div> : ""}
    </div>
  );
}

export default index;
